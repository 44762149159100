@import "styles/mixins/index";

.recipes-sidebar-sticky {
  position: sticky;
  top: 40px;
  @include breakpoint("max-md") {
    display: none;
  }
  @include breakpoint("max-sm") {
    display: none;
  }
  .sidebar-banner {
    padding: map-get($pixel, md) * 4 0 0 0;
    img {
      width: 100%;
      height: 337px;
      object-fit: cover;
    }
  }
}
.mobile-view {
  display: block;
  padding: 16px;
}
.listing-page-sidebar {
  .sidebar-title-alignment {
    @include flex-center;
    padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 2 0;
    justify-content: space-between;
    @include pointer;
    border-bottom: 1px solid var(--border-color-5);
    span {
      @include heading-7-medium;
      color: var(--gray-400);
      display: block;
    }
    i {
      color: var(--light-icon);
      transition: 0.3s ease-in-out;
    }
  }
  .meal-types-search {
    width: map-get($pixel, w-full);
    margin-top: 12px;
    .relative-div {
      position: relative;
      .icon-wrapper {
        position: absolute;
        top: 50%;
        right: 10px + 7px;
        transform: translateY(-50%);
      }
      > input {
        width: 100%;
        height: 46px;
        padding: 0 10px;
        background-color: transparent;
        transition: 0.2s ease-in-out;
        border: 1px solid var(--light-border-color);
        @include heading-text-sm-normal;
        border-radius: 4px;
        color: var(--text-current);
        &:focus {
          border: 1px solid var(--primary-color);
        }
      }
      ::placeholder {
        @include heading-text-sm-normal;
        color: var(--text-current);
        @include font-normal;
      }
    }
  }

  .meal-types-show {
    max-height: 100vh;
    overflow: auto;
    @include ease-in-out;
    margin: 12px 0px;
    .meal-types-content {
      border-radius: map-get($pixel, sm) + 3px;
      background-color: var(--bg-white);

      .grid {
        display: flex;
        flex-wrap: wrap;
      
        .grid-items {
          @include flex-center;
          border-radius: 40px;
          padding: map-get($pixel, md);
          @include pointer;
          @include ease-in-out;
          margin:4px;
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          background-color: transparent;
          &:hover {
            border: 1px solid var(--primary-color);
          }

          .department-name {

            p {
              @include heading-text-sm-normal;
            }
          }
          .department-icon {
            width: 36px;
            height: 36px;

            >img {
              width: 36px;
              height: 36px;
              object-fit: cover;
            }
          }

          &.active {
            background-color: var(--primary-color) !important;
            color: white !important;
          }
        }
      }
    }
  }

  .department-item {
    @include flex-center;
    @include pointer;
    .add-icon {
      @include pointer;
      i {
        color: var(--gray-text-color);
      }
    }
    padding: 10px 15px;
    justify-content: space-between;
    // padding: map-get($pixel, sm) * 2 0 map-get($pixel, sm) * 2 0;
    &:hover {
      @include ease-in-out;
      background-color: var(--primary-light-color);
      color: var(--primary-color);
    }

    .title-alignment {
      @include flex-center;
      position: relative;
      @include pointer;
      &:hover {
        @include ease-in-out;
        background-color: var(--primary-light-color);
        color: var(--primary-color) !important;
      }
    }
    input[type="checkbox"] {
      width: 15px;
      height: 15px;
      margin: 0;
      padding: 0;
    }
    label {
      @include flex-center;
    }
    span {
      @include text;
      color: var(--gray-400);
      display: block;
      // padding-left: map-get($pixel, sm) * 2 + 2px;
      padding: 10px 0 10px map-get($pixel, sm) * 2 + 2px;
      &:hover {
        @include ease-in-out;
        background-color: var(--primary-light-color);
        color: var(--primary-color);
      }
    }
  }
  .active {
    background-color: var(--primary-light-color);
    color: var(--primary-color);
  }

  .other-department-list {
    a {
      display: block;
      padding: 10px 15px;
      @include ease-in-out;
      &:hover {
        color: var(--secondary-color);
      }
    }
  }
}
