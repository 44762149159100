@import "styles/mixins/index";

.deal-of-week-theme2{
    padding-bottom: 40px;
    @media (max-width: 992px) {
      padding-bottom: 20px;
    }
    .deals-product{
        .heading{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            h2{
                color: #1a1a1a;
                font-size: 30px;
                font-weight: 500;
                @media (max-width: 1200px) {
                    font-size: calc(20px + (30 - 20) * ((100vw - 280px) / (1200 - 280)));
                }  
            }
            a{
                svg{
                    vertical-align: middle;
                    path{
                        fill: var(--fill-svg-color-theme2);
                    }
                }
            }
        }
    }
    
.product-row {
    display: flex;
    flex-wrap: wrap;
    // margin: 0 -15px;
  }
  .product-col-xl-4 {
    float: left;
    width: 33.33333%;
  }
  .product-col-xl-6 {
    float: left;
    width: 50%;
  }
  .product-col-xl-12 {
    float: left;
    width: 100%;
  }
  .product-col-sm-4 {
    float: left;
    width: 33.33333%
  }
  .product-col-sm-8 {
    float: left;
    width: 66.66667%
  }
  @media (max-width: 1024px) {
    .product-col-xl-4 {
      width: 50%;
    }
    .product-col-sm-4 {
      width: 50%;
    }
    .product-col-sm-8 {
      width: 50%;
    }
    .product-row {
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    .product-col-xl-4 {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .product-col-xl-4 {
      width: 50%;
    }
    .product-col-sm-4 {
      width: 100%;
      margin-bottom: 15px;
    }
    .product-col-sm-8 {
      width: 100%;
    }
  }
  @media (max-width: 480px) {
    .product-col-xl-4 {
      width: 100%;
    }
  }
}