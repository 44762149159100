@import "styles/mixins/index";


.ebt-checkout-confirmation-screen{

    .transaction-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 450px;

        .payment-status{
        color: var(--primary-color);
        margin-bottom: 12px;
        }
        .payment-status-error{
            color: var(--bg-danger);
            margin-bottom: 12px;
        }
        .payment-transaction-id{
            margin-bottom: 12px;
        }
        .card-bal-info{
            margin-bottom: 12px; 
        }
    }

}