.contact-page {
    .mission-container {
        width: 100%;
        padding: 3% 10% 40px;
        text-align: center;
        max-width: 1440px;
        margin: 0 auto;

        .hrLine {
            border-bottom: 1px solid #fff;
            margin: 3% 15%;
        }

        h2 {
            font-weight: 600;
            line-height: 100%;
            font-size: 4.3vw;
            line-height: 115%;
            letter-spacing: 3.25px;
            text-transform: uppercase;
            color: #000;
            font-size: calc(18px + (50 - 18) * ((100vw - 280px) / (1440 - 280)));

            @media screen and (min-width: 1450px) {
                font-size: 56px;
            }
        }

        .quality-block-holder {
            display: inline-block;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 250px;

            .img-text-holder {
                display: flex;
                align-items: center;
                padding-top: 10px;
                padding-top: 0;
                margin-bottom: 8px;

                img {
                    display: inline;
                    margin: 0;
                    padding-right: 20px;
                }

                h5 {
                    font-weight: 400;
                    line-height: 100%;
                    display: inline;
                    padding-bottom: 0;
                    text-align: left;
                    font-size: 16px;
                    line-height: 110%;
                    letter-spacing: 1px;
                    position: relative;
                }
            }
        }

        .nf-error-msg,
        .ninja-forms-req-symbol {
            color: #e80000;
        }

        .nf-form-fields-required {
            font-size: 16px;
            padding-bottom: 15px;
            font-weight: 400;
            line-height: 100%;
            display: flex;
            align-items: flex-start;
        }

        .nf-form-layout {
            display: block;
            max-width: 1400px;
            margin: 0 auto;
            padding: 30px 10% 15px;
        }

        .nf-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .nf-cell {
                display: flex;
                flex-direction: column;
                margin: 0px 20px 0px 0px;

                .nf-field-container {
                    clear: both;
                    position: relative;
                    margin-bottom: 25px;

                    @media (max-width: 1024px) {
                        margin-bottom: calc(15px + (20 - 15) * ((100vw - 280px) / (1024 - 280)));
                    }

                    .nf-field {
                        .field-wrap {
                            .nf-field-element {
                                input {
                                    width: 100%;
                                }

                                .err-msg {
                                    color: #e80000;
                                    text-align: left;
                                }

                            }

                            .nf-element {
                                height: 40px;
                                width: 100%;
                                background-color: #fff;
                                border: 1px #000 solid;
                                font-size: 16px;
                                line-height: 1.5;

                                @media (max-width: 1024px) {
                                    font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1024 - 280)));
                                }
                            }

                            .nf-field-label {
                                margin-bottom: 6px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 1.2;
                                display: flex;
                            }
                        }
                    }
                }
            }
        }

        .nf-row-store {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .nf-cell {
                display: flex;
                flex-direction: column;
                margin: 0px 20px 0px 0px;

                .nf-field-container {
                    clear: both;
                    position: relative;
                    margin-bottom: 25px;

                    .nf-field {
                        .field-wrap {
                            .nf-field-element {
                                input {
                                    width: 100%;
                                    margin-bottom: 5px;
                                }

                                .err-msg {
                                    color: #e80000;
                                    text-align: left;
                                }
                            }

                            .nf-element {
                                font-size: 16px;
                                line-height: 24px;
                                height: 40px;
                                width: 100%;
                                margin: 0px 25px 0px 0px;

                                @media (max-width: 1024px) {
                                    font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1024 - 280)));
                                }
                            }

                            .nf-field-label {
                                margin-bottom: 6px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 1.2;
                                display: flex;
                            }
                        }
                    }
                }
            }
        }

        .nf-row-submit {
            text-align: center;
            padding-top: 15px;

            .nf-element-button {
                font-size: 17px;
                padding: 10px 30px;
                background-color: var(--primary-color);
                color: var(--bg-white);
                border-radius: 5px;
                border: none;
                outline: none;
            }
        }

        .nf-row-message {
            margin: 0px 22px 0px 0px;

            .nf-element-textarea {
                width: 100%;
                height: 200px;
                font-weight: 400;
                line-height: 100%;
                background: #fff;
                font-size: 16px;

                .err-msg {
                    color: #e80000;
                    text-align: left;
                }
            }

            .nf-field-label {
                font-weight: 700;
                font-size: 1.7vw;
                line-height: 100%;
                display: flex;

                label {
                    margin-bottom: 6px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.2;
                }
            }

            .nf-error-msg,
            .ninja-forms-req-symbol {
                color: #e80000;
                font-size: 16px;
            }
        }
    }
}