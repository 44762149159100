@import "styles/mixins/index";
.address-book-section-theme2 {
    .page-title {
        margin: 0 0 27px 0;
        @include flex-between;
        @include breakpoint("max-sm") {
            padding: 27px 0 0;
        }
        h1 {
            @include heading-2;
            margin: 0;
            color: var(--invert-secondary-color);
            @include breakpoint("max-sm") {
                @include heading-3xl;
            }
        }
        button {
            padding: 8px 20px;
            background-color: var(--primary-color);
            color: var(--bg-white);
            @include heading-text-sm;
            border: none;
            border-radius: 4px;
        }
    }
    .table-wrapper {
        position: relative;
        margin-bottom: 20px;
        border-radius: 8px;
        border: 1px solid var(--border-color-8);
        overflow: hidden;
        .scrollable {
            //   overflow-x: auto; /* Ensure horizontal scrolling */
            overflow-y: hidden;
            table {
                width: 100%;
                border-collapse: collapse;
            }
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 0 auto;
            //   table-layout: auto; /* Ensure the table layout is set correctly */
            tr:first-child {
                background-color: var(--primary-light-color);
                border-radius: 4px 4px;
                @media (max-width: 575px) {
                    display: none;
                }
            }
            th {
                padding: 15px 20px;
                color: var(--invert-primary-color);
                @include heading-text-sm;
                // display: table-cell; /* Ensure the display property is correct */
            }
            td {
                padding: 13px 20px;
                @include text-xs-normal;
                color: #373737;
                // display: table-cell; /* Ensure the display property is correct */
                @media (max-width: 575px) {
                    border: none;
                    font-size: 14px;
                    border-bottom: 1px solid #eee;
                    position: relative;
                    word-break: break-all;
                    padding-left: 50%;
                }
                &.btn-wrapper {
                    padding: 10px;
                    ::before {
                        content: unset;
                    }
                }
                .action-btn {
                    display: inline-flex;
                    button {
                        padding: 6px 15px;
                        background-color: var(--secondary-color);
                        color: var(--bg-white);
                        @include heading-text-sm-normal;
                        @include font-medium;
                        height: 28px;
                        border: none;
                        border-radius: 4px;
                        margin: 3px;
                    }
                    .delete {
                        background-color: #dc3545;
                    }
                }
            }
            tbody tr {
                // display: table-row; /* Ensure the display property is correct */
                &:nth-of-type(even) {
                    background-color: #f9f9f9;
                }
                &:hover {
                    background-color: #f1f1f1;
                }
            }
        }
        .pinned {
            display: none;
        }
    }
    @media (max-width: 1100px) {
        .table-wrapper {
            border-right: 1px solid #ccc;
            .scrollable {
                // overflow-x: auto; /* Ensure horizontal scrolling */
                overflow-y: hidden;
            }
            .pinned {
                display: none;
                table {
                    border-right: none;
                    border-left: none;
                    width: 100%;
                }
            }
        }
    }
    tr.table-detail {
        @media (max-width: 575px) {
            border: none;
            ::before {
                color: #000;
                position: absolute;
                top: auto;
                font-size: 14px;
                left: 6px;
                width: 45%;
                padding: 0 10px;
                white-space: nowrap;
            }
            :nth-of-type(1)::before {
                content: "Address";
            }
            :nth-of-type(2)::before {
                content: "Contact";
            }
            :nth-of-type(3)::before {
                content: "Zip";
            }
            :nth-of-type(4)::before {
                content: "Contact";
            }
            :last-child::before {
                display: none;
            }
        }
    }
    @media (max-width: 768px) {
        .table-wrapper {
            padding: 0.5rem;
            .scrollable {
                table {
                    th,
                    td {
                        // padding: 0.5rem;
                    }
                    .action-btn {
                        button {
                            padding: 0.25rem 0.5rem;
                            font-size: 0.9rem;
                        }
                    }
                }
            }
        }
    }
}