@import "styles/mixins/index";

.catering-ads-space-section-department {
  background-color: var(--bg-light-300);
  padding: map-get($pixel, md) * 1-4 0;
  @include flex-center;
  justify-content: map-get($layout, center);

  @include breakpoint("max-sm") {
    display: none;
  }
  img {
    max-width: 100%;
  }
  margin: 4px;

  > h1 {
    @include heading-2;
    color: var(--black-text-color);
    opacity: 0.22;

    @include breakpoint("max-md") {
      @include text-center;
    }
  }

  .catering-ads-space-section-no-ads {
    background-color: var(--bg-light-300);
    padding: map-get($pixel, md) * 4 0;
    @include flex-center;
    justify-content: map-get($layout, center);

    @include breakpoint("max-sm") {
      display: none;
    }

    > h1 {
      @include heading-2;
      color: var(--black-text-color);
      opacity: 0.22;

      @include breakpoint("max-md") {
        @include text-center;
      }
    }
  }
}
