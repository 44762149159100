@import "styles/mixins/index";

.ads-space-section-weeklyAdd {
  @include flex-center;
  justify-content: map-get($layout, center);

  // @include breakpoint("max-sm") {
  //   display: block;
  // }

  img {
    width: 100%;
  }

  > h1 {
    @include heading-2;
    color: var(--black-text-color);
    opacity: 0.22;

    @include breakpoint("max-md") {
      @include text-center;
    }
  }
}
.ads-space-section-weeklyAdd-noad {
  background-color: var(--bg-light-300);
  padding: map-get($pixel, md) * 5 + 4px 0;
}
