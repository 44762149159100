@import "styles/mixins/index";

.ept-payment-gateway{
    width: 100%;
    padding: 12px 12px 7px;
    iframe{
    width: 100%;
    border-width: 0px;
    min-height: 566px;
    }
}