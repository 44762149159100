@import "styles/mixins/index";
.multi-banner {
  padding-bottom: 40px;
  @media (max-width: 992px) {
    padding-bottom: 20px;
  }
  .multi-img-wrap {
    display: flex;
    flex-direction: row;
    gap: 30px;
    @include breakpoint("max-md") {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .multi-banner-item {
    @media screen and (min-width: 1300px) {
      width: 33.33%;
    }
      .multi-img {
        overflow: hidden;
        cursor: pointer;
        img {
          border-radius: 4px;
          border: 1px solid var(--border-color-4);
          max-width: 100%;
          height: auto;
          width: 100%;
        }
      }
    }
  }
}