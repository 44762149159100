.right-offerbanners {
    .banner-wrapper {
        a {
            img {
                height: 899px;
                max-width: 100%;
                display: inline-block;
                border-radius: 10px;
                object-fit: fill;
                @media (max-width: 1024px) {
                height: 300px;
                }
            }
        }
    }
}