@import "styles/mixins/index";

.recipes-page {
  .breadcrumb-detailed-section {
    padding: 50px;
  }
  .recipes-page-wrapper {
    padding: map-get($pixel, md) * 1-4 0;
    @include grid-layout;
    grid-template-columns: max(321px) minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm) + 3px;

    @include breakpoint("max-sm") {
      padding: 0;
    }

    @include breakpoint("max-md") {
      display: block;
    }

    @include breakpoint("max-sm") {
      display: block;
    }
  }
  .login-message {
    margin: 50px;
    text-align: center;
    .login-link {
      color: var(--primary-color);
      margin-top: 15px;
    }
  }
}
