@import "styles/mixins/index";

.ads-space-section {
    // background-color: var(--bg-light-300);
    padding: map-get($pixel, md) * 1 0;
    @include flex-center;
    justify-content: map-get($layout, center);

    @include breakpoint("max-sm") {
        display: none;
    }

    >h1 {
        @include heading-2;
        color: var(--black-text-color);
        opacity: .22;

        @include breakpoint("max-md") {
            @include text-center;
        }
    }

    img {
        max-width: 100%;
        object-fit: cover;
    }

}

.ads-space-section-no-ads {
    background-color: var(--bg-light-300);
    padding: map-get($pixel, md) * 4 0;
    @include flex-center;
    justify-content: map-get($layout, center);

    @include breakpoint("max-sm") {
        display: none;
    }

    >h1 {
        @include heading-2;
        color: var(--black-text-color);
        opacity: .22;

        @include breakpoint("max-md") {
            @include text-center;
        }
    }

}