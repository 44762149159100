@import "styles/mixins/index";

.recipe-product-info {
  padding: 0 0 100px 0;

  @include breakpoint("max-sm") {
    padding: 40px 0;
  }

  .info-box {
    border: 0.5px solid var(--border-color-11);
    border-radius: 15px;
    padding: 51px 43px;
    margin: 0 70px;

    @include breakpoint("max-md") {
      margin: 0;
    }

    @include breakpoint("max-sm") {
      padding: 0;
      margin: 0;
      border: none;
    }

    .info-details {
      p {
        @include text-md;
        color: var(--gray-6);
        margin: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-sm") {
          @include text-sm-normal;
        }
      }
      .html-parser {
        text-align: left;
      }
      p:last-child {
        margin: 0;
      }
      .Nutrition-heading {
        margin: 0 0 map-get($pixel, md) * 2 0;
        padding-left: 15px;
      }
      .nutrition-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include breakpoint("max-md") {
          display: block;
        }
        .nutrition-image {
          width: 33%;
          overflow: hidden;
          @include breakpoint("max-sm") {
            width: 100%;
          }

          @include breakpoint("max-md") {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        .nutrition-information {
          width: 100%;
          padding: 0 20px;
        }
      }

      .equipment-wrapper {
        display: flex;
        flex-wrap: wrap;
        .equipment {
          display: flex;
          flex-direction: column;
          margin-right: 12px;
          justify-content: center;
          align-items: center;
          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      .nutrition-wrapper {
        display: flex;
        flex-direction: column;
        .nutrition {
          display: grid;
          grid-template-columns: max(150px) minmax(0, 1fr);
          gap: 50px;
        }
      }
      .ingradients-wrapper {
        display: flex;
        flex-direction: column;
        .ingradient-item {
          display: grid;
          grid-template-columns: auto auto;
          margin-bottom: 20px;
          max-width: 500px;
          @include breakpoint("max-sm") {
            grid-template-columns: auto;
          }
          strong {
            margin-bottom: 6px;
          }
          .ingradient {
            display: flex;
            flex-direction: column;
            margin-right: 12px;
            min-width: 270px;
            @include breakpoint("max-sm") {
              margin-bottom: 10px;
            }
            .ingradient-product-info {
              display: flex;
              img {
                width: 80px;
                height: 80px;
                margin-right: 12px;
              }
              .product-detail {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
          .button-wrapper {
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 15px;
            button {
              padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
              background-color: var(--primary-light-color);
              color: var(--primary-color);
              @include text-xs-semi-bold;
              border: none;
              @include normal-radius;
              @include flex-center;
              @include pointer;
              @include ease-in-out;
              &:hover:not([disabled]) {
                background-color: var(--primary-color);
                color: var(--bg-white);
                svg {
                  stroke: var(--bg-white);
                  g {
                    path {
                      fill: var(--bg-white);
                    }
                  }
                }
              }
              span {
                padding-left: 4px;
                white-space: nowrap;
              }

              &:disabled {
                cursor: default;
              }
            }
          }
        }
      }
      .add-ingradient-button {
        button {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          background-color: var(--primary-light-color);
          color: var(--primary-color);
          @include text-xs-semi-bold;
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;
          &:hover:not([disabled]) {
            background-color: var(--primary-color);
            color: var(--bg-white);
            svg {
              stroke: var(--bg-white);
              g {
                path {
                  fill: var(--bg-white);
                }
              }
            }
          }
          span {
            padding-left: 4px;
          }

          &:disabled {
            cursor: default;
          }
        }
      }
    }

    .info-tab {
      padding: 0 0 map-get($pixel, md) * 2 0;
      // @include breakpoint("max-sm") {
      //   @include flex-around;
      // }

      button:last-child {
        margin: 0;
      }

      button {
        padding: 15px 35px;
        border: 1px solid var(--border-light);
        margin-right: map-get($pixel, sm) * 3;
        border-radius: 999px;
        background-color: transparent;
        color: var(--gray-6);
        @include text-md;
        @include font-semi-bold;

        @include breakpoint("max-sm") {
          @include text-sm-semi-bold;
          margin-bottom: 12px;
        }
      }
      .active {
        border: 1px solid var(--primary-color);
        box-shadow: (0px 0px 10px rgba(126, 126, 126, 0.16));
        color: var(--primary-color);
      }
    }
  }
}

.step-product-info {
  padding: 0 0 100px 0;

  @include breakpoint("max-sm") {
    padding: 40px 0;
  }

  .step-box {
    border: 0.5px solid var(--border-color-11);
    border-radius: 15px;
    padding: 43px;
    margin: 0 70px;

    @include breakpoint("max-md") {
      margin: 0;
    }

    @include breakpoint("max-sm") {
      padding: 0;
      margin: 0;
      border: none;
    }

    .info-details {
      p {
        @include text-md;
        color: var(--gray-6);
        margin: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-sm") {
          @include text-sm-normal;
        }
      }
      .html-parser {
        text-align: left;

        li {
          display: list-item;
          list-style: auto;
        }
      }
      p:last-child {
        margin: 0;
      }

      .steps-instruction {
        margin-bottom: 12px;

        .step-label {
          display: flex;
          margin-bottom: 8px;

          .success-checkmark {
            margin-right: 8px;
            font-size: 14px;
            color: white;
            width: 20px;
            height: 20px;
            text-align: center;
            background: var(--primary-color);
            border-radius: 50%;
            // box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
          }
        }
      }
    }

    .step-tab {
      margin-bottom: 18px;

      button:last-child {
        margin: 0;
      }

      button {
        padding: 15px 35px;
        border: 1px solid var(--border-light);
        margin-right: map-get($pixel, sm) * 3;
        border-radius: 999px;
        background-color: transparent;
        color: var(--gray-6);
        cursor: default;
        @include text-md;
        @include font-semi-bold;

        @include breakpoint("max-sm") {
          @include text-sm-semi-bold;
        }
      }
      .active {
        border: 1px solid var(--primary-color);
        box-shadow: (0px 0px 10px rgba(126, 126, 126, 0.16));
        color: var(--primary-color);
      }
    }
  }
}
