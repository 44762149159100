@import "styles/mixins/index";

.individual-listing-page{
  .breadcrumb-detailed-section{
    padding: 16px 50px;
    @include breakpoint("max-sm") {
      padding: 30px 0;
    }
  }
  
  .individual-listing-page-wrapper {
    padding: map-get($pixel, md) * 1-4 0;
    @include grid-layout;
    grid-template-columns: max(321px) minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm) + 3px;
  
    @include breakpoint("max-sm") {
      padding: 0;
    }
  
    @include breakpoint("max-md") {
      display: block;
    }
  
    @include breakpoint("max-sm") {
      display: block;
    }
  }
}
