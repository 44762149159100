.coupon-description {
    margin: 40px 0;
    font-family: var(--text-font-family);

    .product-main-wrapper{
        position: relative;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .07);
        padding: 50px 15px;
        border-radius: 3px;
        display: inline-block;
        width: 100%;
        margin: 0;
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 107px;
        @media screen and (max-width: 992px){
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
        }
        .product-image {
            img {
                max-height: 400px;
                width: 100%;
                object-fit: contain;
            }
        }

        .product-desc {
            .product-offer {
                h2 {
                    color: #000;

                    font-size: 22px;
                    font-weight: 600;

                }
            }

            .product-desc {
                p {
                    border-top: 1px solid #e6e6e6;
                    border-bottom: 1px solid #e6e6e6;
                    padding: 15px 0 15px;
                    font-size: 14px;
                    color: #3a3a3a;
                    line-height: 1.5;
                }
            }

            .product-card-btn {
                .add-to-cart {
                    margin: 15px 0;

                    button {
                        margin: auto;
                        background: var(--bg-color-theme2);
                        border: none;
                        text-transform: capitalize;
                        border-radius: 20px;
                        display: inline-block;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 9px 25px;
                        text-align: center;
                        color: #fff;
                        transition: all 0.5s ease-in-out;

                        span {
                            padding-left: 5px;
                            vertical-align: text-bottom;
                        }

                        &:hover {
                            background-color: #000;
                            color: #fff;

                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }

                        &:disabled {
                            cursor: no-drop;
                            opacity: 0.5;

                            .add-to-cart-icon {
                                display: none;
                            }

                            .out-of-stock-icon {
                                display: inline-block;
                                vertical-align: sub;
                            }
                        }

                        .out-of-stock-icon {
                            display: none;
                        }
                    }
                }
            }

            .expire-title {
                padding-top: 15px;
                font-weight: 500;
                font-size: 20px;
                line-height: 1.2;
                border-top: 1px solid #e6e6e6;

                span {
                    font-size: 20px;
                    font-weight: 400;
                    color: #808080;
                    font-weight: 400;
                }
            }
        }
    }
}