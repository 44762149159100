@import "styles/mixins/index";

.notification-message-show {
  padding: map-get($pixel, md) - 3px 0;
  @include breakpoint("max-md") {
    display: none;
  }
  .grid {
    @include flex-between;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    color: var(--primary-color);
    .notification-text {
      padding: 10px;
    }
    .cross-icon {
      border: 1px solid var(--primary-color);
      border-radius: 4px;
      @include flex-center;
      width: 25px;
      height: 25px;
      @include flex-center;
      justify-content: map-get($layout, center);
      @include pointer;
      margin-right: 5px;
    }
  }
}
.notification-message-hide {
  display: none;
}
