@import "styles/mixins/index";
.address-section {
  border-radius: calc(10px - 2px);
  border: 1px solid var(--border-color-8);
  padding: map-get($pixel, md) * 3 map-get($pixel, md) * 3 - 3px;
  @include breakpoint("max-sm") {
    padding: map-get($pixel, sm) * 3;
  }
  .address-form {
    display: grid;
    gap: 10px 0;
  }
  .address-submit-btn {
    button {
      padding: map-get($pixel, sm) * 2 map-get($pixel, md) * 3;
      background-color: var(--primary-color);
      color: var(--bg-white);
      border-radius: calc(5px - 0px);
      border: none;
      @include heading-4;
    }
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: default;
    }
  }
  h1 {
    @include heading-4xl;
    color: var(--black-5);
    margin: 0 0 map-get($pixel, md) * 3 + 2px 0;
    @include breakpoint("max-sm") {
      margin: 0 0 map-get($pixel, md) * 2 0;
    }
  }
  .form-control {
    margin: 0;
    span {
      @include heading-text-xs;
      display: block;
      padding: map-get($pixel, sm) 0 0 0;
      color: var(--bg-danger);
    }
  }
  .addres-sec-line {
    margin: 0;
  }
  .input-name-grid {
    @include grid-layout;
    // grid-template-columns: repeat(12, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: map-get($pixel, md) * 2;
    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint("max-sm") {
      gap: 10px;
    }
  }
  .input-name-grid3 {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: map-get($pixel, md) * 2;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint("max-sm") {
      gap: 10px;
    }
  }
  .address-submit-btn {
    margin-top: 20px;
    @media screen and (max-width: 650px) {
      margin-top: 5px;
    }
  }
}