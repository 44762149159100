@import "styles/mixins/index";

.donation-info-section {
    padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 2 + 4px 0;

    @include breakpoint("max-sm") {
        padding: map-get($pixel, md) * 5 0;
    }

    h1 {
        font-size: 40px;
        line-height: 40px;
        color: var(--primary-color);
        margin: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-sm") {
            @include heading-5;
        }
    }

    span {
        color: var(--color);
        font-size: medium;
        margin: 0 0 map-get($pixel, md) * 1 0;

    }

}

.donation-form-section {
    padding: map-get($pixel, md) * 5 map-get($pixel, md) * 5 + 5px;
    border: 1px solid var(--border-color-8);
    border-radius: 8px;
    margin: 0 0 100px 0;

    @include breakpoint("max-md") {
        margin: 0 0 100px 0;
        padding: map-get($pixel, md) * 4 map-get($pixel, md) * 3;
    }

    @include breakpoint("max-sm") {
        margin: 0 0 60px 0;
        padding: map-get($pixel, sm) * 3;
    }

    .donation-three-col-grid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        justify-content: space-between;
        padding: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
        }

        br {
            @include breakpoint("max-md") {
                display: none;
            }
        }

        @include breakpoint("max-md") {
            .form-control:last-child {
                grid-column: 1 / 3;
            }
        }

        @include breakpoint("max-sm") {
            .form-control:last-child {
                grid-column: auto;
            }
        }
    }

    .donation-form-submit-button {
        button {
            background-color: var(--primary-color);
            color: var(--bg-white);
            border: none;
            padding: 8px 30px;
            height: 48px;
            @include heading-4;
            border-radius: 5px;
        }
    }

    .donation-sec-col-grid {
        @include grid-layout;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        justify-content: space-between;
        padding: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
        }

        .leaving-text {
            margin: 0 0 20px 0;

            textarea {
                height: 72px;
            }
        }

        .reason-text {
            textarea {
                height: 150px;
            }
        }
    }

    .donation-two-col-grid {
        @include grid-layout;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        justify-content: space-between;
        padding: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
        }

        @include breakpoint("max-md") {
            .tow-col-grid-items:nth-child(1) {
                .form-control:last-child {
                    grid-column: auto;
                }
            }
        }

        @include breakpoint("max-sm") {
            .tow-col-grid-items:nth-child(1) {
                .form-control:last-child {
                    grid-column: auto;
                }
            }
        }

        .tow-col-grid-items:nth-child(1) {
            @include grid-layout;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            @include breakpoint("max-md") {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    .dropdown-show {
        height: 100vh;
        z-index: 999999;
    }

    .dropdown-hidden {
        z-index: 999999;
    }

    .form-control {
        position: map-get($layout, relative);

        p {
            color: var(--bg-danger);
            font-size: .8rem;
        }

        .hint-text {
            color: var(--color);
            font-size: .8rem;
            font-weight: 500;

        }
    }

    .one-col-grid {
        @include grid-layout;
        padding: 0 0 map-get($pixel, md) * 2 0;
        grid-template-columns: repeat(1, 1fr);

        textarea {
            height: 150px;
        }
    }

    .select-dropdown {
        font-size: 14px;
        font-family: var(--heading-font-family), var(--default-font-family);
        padding: 11px 15px;
        cursor: pointer;

        font-weight: 400;
        color: var(--black-3);
        transition: 0.3s ease-in-out;
        line-height: 16px;



    }

    select.select-dropdown option {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        border: none;
        padding-left: 5px;
        border: 1px solid #fff;
        background: #ffffff;
    }


    input[type="checkbox"] {
        width: 15px;
        height: 15px;
        margin: 0;
        padding: 0;
    }

    .donation-success {
        color: var(--primary-color);
        padding: 12px;
    }

    .donation-error {
        color: var(--bg-danger);
        padding: 12px;
    }

}