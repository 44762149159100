@import "styles/mixins/index";

.product-preview-section {
  padding: map-get($pixel, md) * 7 + 7px 0 map-get($pixel, md) * 8 + 8px 0;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 4 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: max(620px) minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 10 + 7px;
    padding: 0 70px;

    @include breakpoint("max-md") {
      display: flex;
      gap: 40px;
      padding: 0;
      grid-template-columns: none;
      padding: 0;
    }

    @include breakpoint("max-sm") {
      display: block;
      grid-template-columns: none;
    }

    .grid-items:nth-child(2) {
      padding: map-get($pixel, md) * 6 0 0 0;
    }

    .grid-items {
      @include breakpoint("max-md") {
        width: 50%;
      }

      @include breakpoint("max-sm") {
        width: 100%;
      }

      h1 {
        @include heading-9-medium;
        margin: 0 0 map-get($pixel, md) * 2 - 1px 0;
        color: var(--black-text-color);
        max-width: 655px;

        @include breakpoint("max-md") {
          @include heading-7;
          @include font-medium;
        }
      }
      .coupon-pdp-used-time-wrapper {
        display: flex;
        position: relative;
        align-items: center;
        margin: 0 40px map-get($pixel, md) * 3 + 5px 0;
        // align-items: center;
        @include breakpoint("max-sm") {
          margin: 0 0 10px -2px;
        }
        @media (max-width: 450px) {
          flex-wrap: wrap;
        }
        @include breakpoint("max-md") {
          margin: 0 50px map-get($pixel, md) * 2 0;
        }
        .offer-price{
          display: inline-block;
          .offer-text{
            display: inline-block;
            margin-left: 20px;
            @media (max-width: 450px) {
             margin: 5px 0 0 0;
            }
          }
        }
        .coupon-title{
          background-color: #fff;
          border-radius: 4px;
          color: #000;
          font-size: 13px;
          left: 30%;
          right: 0;
          top: 100%;
          display: none;
          padding: 5px 10px;
          position: absolute;
          transform: translateX(-50%);
          transition: opacity 0.3s;
          z-index: 99;
          word-wrap: break-word;
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
        }
        .coupon-used-pdp-item {
          margin-top: 6px;
        }
        .coupon-design {
          .offer-text{
            display: inline-block;
          }
          background-color: var(--secondary-color);
          padding: 7px 34px 7px 18px;
          // margin: 0 40px map-get($pixel, md) * 3 + 5px 0;
          position: relative;
          width: fit-content;
          cursor: pointer;
          // @include breakpoint("max-sm") {
          //   margin: 0 0 10px -2px;
          // }

          // @include breakpoint("max-md") {
          //   margin: 0 50px map-get($pixel, md) * 2 0;
          // }

          p {
            color: var(--bg-white);
            @include heading-4;

            @include breakpoint("max-md") {
              font-size: 12px;
              line-height: 16px;
            }

            span {
              @include font-medium;
            }
          }
        
          &::before {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            top: 0;
            border-width: 21px 12px 19px 0;
            position: absolute;
            content: "";
            right: -1px;
            border-color: transparent var(--bg-white) transparent transparent;

            @include breakpoint("max-md") {
              border-width: 15px 12px 19px 0;
            }
          }
          &:hover ~ .coupon-title{
              display: block;
          }

        }
        .coupon-details {
          padding: 7px 34px 7px 18px;
          margin: 0 40px map-get($pixel, md) * 3 + 5px 0;
          position: absolute;
          width: fit-content;

          @include breakpoint("max-sm") {
            margin: 0 0 10px -2px;
          }

          @include breakpoint("max-md") {
            margin: 0 50px map-get($pixel, md) * 2 0;
          }

        }
      }

      .final-price {
        h2 {
          color: var(--black-5-light);
          font-weight: 500;
        }
        span{
          color: var(--primary-color);
        }
      }

      .department-code {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 3  0;

        div:first-child {
          padding-right: map-get($pixel, md) * 4;
        }

        div {
          p {
            @include text-sm-normal;
            color: var(--black-5-light);

            span {
              color: var(--primary-color);
            }
          }
        }
      }
      .restricted {
        p {
          color: var(--bg-danger);
        }
      }
      .substitution {
        padding: 0 0 map-get($pixel, md) * 3 0;

        @include breakpoint("max-sm") {
          padding: 0 0 20px 0;
        }

        p {
          @include heading-4;
          margin: 0 0 map-get($pixel, md) 0;
          color: var(--black-5-light);
        }

        .substitution-border {
          border: 1px solid var(--light-border-color) !important;
        }

        .select-substitition-dropdown {
          position: relative;
          width: fit-content;

          button {
            @include heading-text-sm;
            padding: 12px 17px;
            border: 1px solid var(--border-color-2);
            color: var(--black-5-light);
            border-radius: 5px;
            @include flex-center;
            justify-content: map-get($layout, space-between);
            background-color: transparent;
            @include font-normal;
            width: 155px;
            position: relative;

            span {
              padding-right: map-get($pixel, md) - 3px;
            }
          }

          .substitution-dropdown {
            position: absolute;
            width: 100%;

            .list-menu {
              border: none;
            }
          }
        }
      }

      .add-product-option {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 3 + 5px 0;

        @include breakpoint("max-md") {
          display: block;
        }

        @include breakpoint("max-sm") {
          display: flex;
          flex-wrap: wrap;
        }
        .button-disable {
          pointer-events: none;
          opacity: 0.5;
        }

        .link-button {
          margin-left: map-get($pixel, md);

          @include breakpoint("max-md") {
            padding: map-get($pixel, md) * 2 0 0 0;
            margin-left: 0;
          }

          @include breakpoint("max-sm") {
            padding: map-get($pixel, md) * 2 - map-get($pixel, md) * 2 0 0 0;
            margin-left: 0;
          }

          button {
            width: 137px;
            height: 50px;
            @include flex-center;
            justify-content: map-get($layout, center);
            border-radius: 5px;
            background-color: transparent;
            border: 1px solid #ccc;

            i {
              color: #ccc;
            }
          }

          .fa-solid {
            color: red;
          }
        }

        .add-to-cart {
          @include breakpoint("max-md") {
            padding: map-get($pixel, md) * 2 0 0 0;
          }

          @include breakpoint("max-sm") {
            padding: map-get($pixel, md) * 2 - map-get($pixel, md) * 2 0 0 0;
            padding-bottom: map-get($pixel, md);
          }

          button {
            background-color: var(--primary-color);
            height: 50px;
            padding: 10px 25px;
            border-radius: 5px;
            @include flex-center;
            justify-content: map-get($layout, center);
            border: none;
            @include text-sm-normal;
            @include font-semi-bold;
            color: var(--bg-white);
            min-width: 155px;

            &:disabled {
              cursor: no-drop;
              opacity: 0.5;
              .add-to-cart-icon{
                display: none;
              }
              .out-of-stock-icon{
                display: inline-block;
                vertical-align: sub;
              }
            }
            .out-of-stock-icon{
              display: none;
            }

            span {
              padding-left: map-get($pixel, md);
              vertical-align: middle;
            }
          }
        }
        .new-list-input-dropdown {
          @include breakpoint("max-sm") {
            right: -110px;
          }
        }
        .couter-button {
          padding-right: map-get($pixel, md);
          @include breakpoint("max-sm") {
            padding-bottom: map-get($pixel, md);
          }
          button {
            min-width: 90px;
            height: 50px;
            @include heading-text-md-medium;
            color: var(--primary-color);
            position: relative;
            border-radius: 5px;
            cursor: text;
            background-color: transparent;
            text-align: left;
            border: 2px solid var(--primary-color);

            &:disabled {
              cursor: default;
            }

            span {
              padding-left: 30px;
              display: block;
            }

            .arrow-right-side {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 14px;

              i {
                font-size: 12px;
                @include pointer;
              }
            }
          }
        }
      }

      .other-price-details {
        p {
          padding-bottom: 5px;
        }

        p:first-child & p:last-child {
          padding: 0 0 map-get($pixel, md) * 2 0;
        }
      }
      .offer-price{
        span{
          margin-left: 20px;
          color: #1e1e1e;
          font-size: 20px;
          display: inline-block;
          font-weight: 600;
        }
      }
      .final-price {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-md") {
          display: block;
        }
        .product-card-onsale-lable{
          display: inline-block;
          z-index: 1;
          font-size: 12px;
          position: relative;
          padding: 3px 3px 3px 15px;
          border-radius: 5px;
          margin: 0;
          font-weight: 600;
          background-color: var(--add-to-cart-sale-bg-color);
          color: var(--add-to-cart-sale-font-color);
          &::before {
            display: inline-block;
            width: 0;
            // height: 0; 
            border-style: solid;
            top: 0;
            bottom: 0;
            border-width: 12px 12px 12px 0;
            position: absolute;
            content: "";
            left: -1px;
            border-color: var(--bg-white) transparent var(--bg-white) transparent;
          }
        }
        .price {
          padding-right: map-get($pixel, md) * 3 + 4px;
          display: flex;
          align-items: center;
          h2 {
            @include text-5xl;
            color: var(--gray-text-color);
            margin: 0;
          }

          img{
            height: 50px;
            width: 50px;
            margin-left: 12px
          }
        }

        .discount-price {
          p {
            @include text-sm-semi-bold;
            color: #fcbb0a;
            margin: 0;
          }

          span {
            @include text-4xl;
            display: block;
            color: var(--gray-8);
          }
        }
      }

      .rating-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 + 3px 0;

        .review-text {
          span {
            @include text;
            color: var(--gray-7);
          }
        }

        .star-alignment {
          @include flex-center;
          padding-right: map-get($pixel, sm) * 3 - 1px;

          i:last-child {
            color: #c2c2c2;
            margin: 0;
          }

          i {
            color: #f9c945;
            @include pointer;
            margin-right: map-get($pixel, sm);
          }
        }
      }
    }
  }
}