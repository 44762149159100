@import "styles/mixins/index";
.banner-section {
  padding-bottom: 40px;
  @media (max-width: 992px) {
    padding-bottom: 20px;
  }
  .banner-img-wrap {
    display: flex;
    flex-direction: row;
    gap: 30px;
    @include breakpoint("max-md") {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .banner-image {
      width: 100%;
    }
    .banner-banner-item {
      .banner-img {
        overflow: hidden;
        cursor: pointer;
        img {
          border-radius: 4px;
          border: 1px solid var(--border-color-4);
          max-width: 100%;
          height: auto;
          width: 100%;
        }
      }
    }
  }
  .banner-list-grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
    @include breakpoint("max-xl") {
      grid-template-columns: repeat(4, 1fr);
    }
    @include breakpoint("max-md") {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 890px) {
      gap: 15px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    // height: 100vh;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    // max-height: 100%;
  }
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    min-width: 800px;
    // width: calc(100% - 30px);
    margin: 15px;
    height: calc(100vh - 30px);
    max-width: 90%;
    overflow-y: auto;
    text-align: center;
    position: relative;
    @media (max-width: 992px) {
      min-width: calc(100% - 30px);
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: var(--light-border-color);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-color);
    }
    .title {
      font-size: 18px;
      color: #333;
      line-height: 1;
      margin-bottom: 20px;
    }
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      font-size: 24px;
      cursor: pointer;
      height: 40px;
      width: 40px;
      margin: auto;
      line-height: 40px;
      border-radius: 0;
      overflow: hidden;
      background: var(--secondary-color);
      color: var(--bg-white);
    }
    .banner-all-product {
      height: 100%;
      position: relative;
      .banner-all-product-main-wrapper {
        font-family: var(--text-font-family);
        height: 100%;
        position: relative;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 10px 10px 15px;
        outline: 3px solid transparent;
        outline-offset: -3px;
        transition: all 0.6s ease-in-out;
        &:hover {
          border-color: var(--border-color-theme2);
          outline-color: var(--border-color-theme2);
        }
        .banner-right-product-wrap {
          height: 100%;
          .banner-right-product-main-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            // overflow: hidden;
            justify-content: space-between;
            position: relative;
            .offer-tag-quickview {
              position: absolute;
              top: 0;
              left: 0;
              right: auto;
              bottom: auto;
              z-index: 11;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              align-items: center;
              justify-content: space-between;
              .offer-product-tag {
                .product-tag {
                  min-height: 36px;
                  border-radius: 4px;
                  color: #fff;
                  font-size: 13px;
                  font-weight: 400;
                  background: var(--product-tag-bg-color);
                  padding: 8px 10px;
                  display: inline-block;
                }
              }
            }
            .banner-right-product-img {
              margin: 45px 0 0;
              text-align: center;
              overflow: hidden;
              a {
                img {
                  min-height: 210px;
                  max-height: 210px;
                  max-width: 210px;
                  margin: auto;
                  object-fit: contain;
                  -webkit-transition: all 0.6s ease-in-out;
                  -o-transition: all 0.6s ease-in-out;
                  transition: all 0.6s ease-in-out;
                }
              }
              .offer-product-list-icon {
                text-align: right;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #959595;
                  text-transform: capitalize;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  overflow: hidden;
                }
                .add-to-list-icon {
                  border: 1px solid #bfbfbf;
                  background-color: #fff;
                  position: relative;
                  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
                  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
                  border-radius: 50%;
                  display: inline-block;
                  line-height: 2.8;
                  width: 35px;
                  height: 35px;
                  text-align: center;
                  cursor: pointer;
                  transition: all 0.5s ease-in-out;
                  @media (max-width: 480px) {
                    width: 30px;
                    height: 30px;
                  }
                  svg {
                    @media (max-width: 480px) {
                      width: 16px;
                      height: 27px;
                      vertical-align: super;
                    }
                  }
                  .list-tooltip {
                    position: absolute;
                    bottom: auto;
                    display: inline-block;
                    padding: 3px 6px;
                    color: #000;
                    font-size: 12px;
                    z-index: 1;
                    line-height: normal;
                    left: -42px;
                    white-space: nowrap;
                    right: 0;
                    top: -28px;
                    margin: auto;
                    width: max-content;
                    background-color: #fff;
                    border-radius: 4px;
                    display: none;
                    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
                  }
                  &:hover .list-tooltip {
                    display: block;
                  }
                  svg {
                    transition: all 0.5s ease-in-out;
                  }
                  &:hover {
                    background-color: var(--bg-color-theme2);
                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }
              }
            }
            .banner-right-product-caption {
              font-family: var(--text-font-family);
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              margin-bottom: 5px;
              .card-price-title {
                width: 70%;
                text-align: left;
                .product-price3 {
                  height: 35px;
                  min-height: 35px;
                  font-family: var(--text-font-family);
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  color: #4d4d4d;
                  display: -webkit-box;
                  font-size: 13px;
                  font-weight: 400;
                  overflow: hidden;
                  @media (max-width: 480px) {
                    font-size: 13px;
                  }
                }
                .product-title {
                  height: 35px;
                  min-height: 35px;
                  font-family: var(--text-font-family);
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  color: #4d4d4d;
                  display: -webkit-box;
                  font-size: 13px;
                  font-weight: 400;
                  overflow: hidden;
                  @media (max-width: 480px) {
                    font-size: 13px;
                  }
                }
                .product-price-shipping {
                  font-size: 13px;
                  font-weight: 800;
                  color: #000000;
                  display: inline-block;
                  vertical-align: middle;
                  letter-spacing: 0;
                  @media (max-width: 480px) {
                    font-size: 13px;
                  }
                }
                .regular-price {
                  margin-left: 7px;
                  font-size: 13px;
                  font-weight: 500;
                  text-decoration: line-through;
                  color: #999;
                  letter-spacing: 0.3px;
                  display: inline-block;
                }
              }
              .bag-icon {
                border: 1px solid #bfbfbf;
                background-color: var(--primary-color);
                border-radius: 50%;
                cursor: pointer;
                display: inline-block;
                height: 38px;
                width: 38px;
                position: relative;
                line-height: 29px;
                text-align: center;
                line-height: 2.8;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
                transition: all 0.5s ease-in-out;
                .cart-tooltip {
                  position: absolute;
                  bottom: auto;
                  display: inline-block;
                  padding: 3px 6px;
                  color: #000;
                  font-size: 12px;
                  z-index: 1;
                  line-height: normal;
                  left: -50px;
                  white-space: nowrap;
                  right: 0;
                  top: -28px;
                  margin: auto;
                  width: max-content;
                  background-color: #fff;
                  border-radius: 4px;
                  display: none;
                  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
                }
                &:hover .cart-tooltip {
                  display: block;
                }
                svg {
                  transition: all 0.5s ease-in-out;
                }
                &:hover {
                  background-color: var(--bg-extra-color-theme2);
                }
                img {
                  transition: all 0.5s ease-in-out;
                }
                &:hover {
                  svg {
                    transform: rotate(360deg);
                    path {
                      fill: #fff;
                    }
                  }
                }
                svg {
                  path {
                    transition: all 0.5s ease-in-out;
                    fill: #fff;
                  }
                }
              }
              .increment-decrement-qty {
                align-items: center;
                border: 1px solid var(--border-color-theme2);
                border-radius: 20px;
                display: flex;
                height: 35px;
                justify-content: space-between;
                max-width: 85px;
                padding: 4px;
                text-align: center;
                width: 90px;
                button {
                  border: 1px solid #f2f2f2;
                  color: #fff;
                  background-color: var(--border-color-theme2);
                  border-radius: 50%;
                  height: 25px;
                  width: 25px;
                  font-size: 14px;
                  transition: all 0.3s ease-in-out;
                  &:hover {
                    background-color: #000;
                    color: #fff;
                  }
                }
                span {
                  border: none;
                  height: auto;
                  line-height: 37px;
                  width: 38px;
                }
              }
              .load-icon {
                text-align: center;
                display: inline-block;
                height: 38px;
                width: 38px;
                border-radius: 50%;
                line-height: 3.1;
                img {
                  mix-blend-mode: bannerply;
                  height: 25px;
                  width: 25px;
                }
              }
            }
            .coupon-used-time-ribbon {
              border: 1px solid #f2f2f2;
              background-color: #fff;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
              border-radius: 50%;
              display: inline-block;
              line-height: 2.8;
              width: 35px;
              height: 35px;
              text-align: center;
              line-height: 2.2;
            }
          }
        }
        &:hover {
          .banner-right-product-img {
            a {
              img {
                transform: scale(1.2);
              }
            }
          }
        }
      }
      &:hover {
        .banners-offer-text {
          display: block;
          height: 46px;
          opacity: 1;
        }
        .card-price-title {
          .product-title {
            color: var(--product--theme2-text-color) !important;
          }
        }
      }
      .banners-offer-text {
        background-color: var(--product-tag-bg-color);
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        height: 0;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        p {
          font-size: 12px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          padding: 7px;
        }
      }
    }
  }
}