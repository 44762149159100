@import "styles/mixins/index";

.navigation-bar-theme2 {
  border-bottom: 1px solid var(--gray-border-color);

  .padding-top-bottom-12px {
    padding: 12px 0px !important;
  }

  .navigation-bar-wrapper {
    // padding: map-get($pixel, md) + 1px 0;
    padding: 0;
    @include flex-between;

    @include breakpoint("max-md") {
      display: none;
    }

    .download-app {
      @include breakpoint("max-md") {
        display: none;
      }

      .secondary-button {
        padding: map-get($pixel, sm) * 2 + 1px;
        background-color: var(--secondary-color);
        color: var(--text-white);
        @include heading-text-sm;
        height: 40px;
        border: none;
        border-radius: map-get($pixel, sm) - 1px;
        white-space: nowrap;
      }
    }

    .menu {
      @include flex-center;

      @include breakpoint("max-md") {
        display: none;
      }

      .sub-menu-dropdown {
        position: absolute;
        min-width: 180px;
        background-color: var(--bg-white);
        max-height: 0px;
        overflow: hidden;
        @include ease-in-out;
        top: 30px;
        z-index: 999;

        .list-align {
          padding: map-get($pixel, sm) * 3;
          box-shadow: $normal-shadow;
          border: 1px solid var(--light-border-color);
          border-radius: 0;

          a:last-child {
            padding: 0;
          }

          >a {
            @include text-semi-bold;
            color: var(--black-3);
            display: block;
            margin: 0;
            padding: 0 0 10px;
            @include ease-in-out;
            font-weight: 400;
            font-size: 15px;

            &:hover {
              color: var(--secondary-color);
            }
          }

          >span {
            @include text-semi-bold;
            color: var(--black-3);
            display: block;
            padding: 0 0 map-get($pixel, sm) * 2 0;
            @include ease-in-out;
            font-weight: 400;

            &:hover {
              color: var(--secondary-color);
            }
          }

          .highlight-menu {
            color: var(--secondary-color);
          }
        }
      }

      .submenu-wrapper:hover .sub-menu-dropdown {
        max-height: 100vh !important;
        @include ease-in-out;
      }

      .nav-menu {
        padding-left: 0 !important;
        @include flex-center;

        a:last-child {
          margin-right: 0;
        }

        a {
          margin-right: map-get($pixel, md) * 4;

          @include breakpoint("md") {
            margin-right: map-get($pixel, md) * 2;
          }
          @media (max-width: 1440px) {
            margin-right: 15px;
          }
          @include text-semi-bold;
          position: map-get($layout, relative);
          color: var(--black-3);
          @include pointer;
          //  @include flex-center;
          position: relative;
          font-size: 14px;
          font-weight: 500;
          font-family: var(--text-font-family);
          @include ease-in-out;

          @media screen and (min-width: 1350px) and (max-width: 1750px) {
            font-size: 12px;
          }

          &:hover {
            color: var(--secondary-color);
          }

          img {
            margin-left: map-get($pixel, md);
          }
        }

        .submenu-wrapper {
          @media screen and (min-device-width: 1440px) and (max-device-width: 1800px) {
            font-size: 12px;
          }
          @media screen and (min-width: 1350px) and (max-width: 1750px) {
            font-size: 12px;
          }
          margin-right: map-get($pixel, md) * 4;
          
          @include breakpoint("md") {
            margin-right: map-get($pixel, md) * 2;
          } 
          @media (max-width: 1440px) {
            margin-right: 15px;
          }
          @include text-semi-bold;
          position: map-get($layout, relative);
          color: var(--black-3);
          @include pointer;
          @include flex-center;
          position: relative;
          @include ease-in-out;
          font-size: 14px;
          font-weight: 500;

          &:hover {
            color: var(--secondary-color);
          }

          img {
            margin-left: map-get($pixel, md);
          }
        }

        .highlight-menu {
          color: var(--secondary-color);
        }
      }

      .wrapper {
        @include flex-center;

        .shop-by-department {
          position: relative;

          .shop-by-department-menu-show {
            max-height: 100vh;
            overflow: hidden;
            @include ease-in-out;
          }

          .shop-by-department-menu-hidden {
            max-height: 0px;
            overflow: hidden;
            @include ease-in-out;
          }

          .shop-by-department-menu {
            position: absolute;
            width: 550px;
            z-index: 99999;
            top: 53px;

            .shop-by-department-menu-content {
              border-radius: map-get($pixel, sm) + 3px;
              border: 1px solid var(--primary-color);
              background-color: var(--bg-white);
              padding: map-get($pixel, md) * 2;
              max-height: calc(100vh - 250px);
              overflow: hidden;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 4px;
                background-color: var(--light-border-color);
              }

              &::-webkit-scrollbar-thumb {
                background-color: var(--primary-color);
              }

              .grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: map-get($pixel, sm) * 3;

                .grid-items {
                  border: 1px solid var(--border-color);
                  @include flex-center;
                  border-radius: map-get($pixel, sm) - 1px;
                  padding: map-get($pixel, md);
                  @include pointer;
                  @include ease-in-out;

                  &:hover {
                    border: 1px solid var(--primary-color);
                  }

                  .department-name {
                    padding-left: map-get($pixel, md);

                    p {
                      @include heading-text-sm-normal;
                      color: var(--black-text-color);
                    }
                  }

                  .department-icon {
                    width: 60px;
                    height: 60px;

                    >img {
                      width: 60px;
                      height: 60px;
                      border-radius: 100%;
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }
        }

        .invert-button {
          padding: map-get($pixel, sm) * 2 map-get($pixel, sm) * 2 + 3px;
          background-color: var(--invert-primary-color);
          color: var(--text-white);
          border: none;
          margin-right: map-get($pixel, md);

          >svg {
            display: none;
          }

          >img {
            min-width: 18px;
          }

          >span {
            padding-left: map-get($pixel, md);
            display: block;
          }
        }
      }
    }
  }

  &.navigation-bar-theme2 {
    background-color: var(--navigation-bar-theme2);
    font-family: var(--text-font-family);

    .navigation-bar-wrapper {

      // padding: 0 15px;
      .menu {
        .nav-menu {
          .shop-by-department {

            .invert-button {
              padding: 0 !important;
            }
          }

          .submenu-wrapper {
            color: var(--navigation-bar-theme2-text-color);

            @media screen and (min-device-width: 1540px) and (max-device-width: 1680px) {
              font-size: 15px;
            }

            img {
              filter: contrast(5);
            }
          }

          a {
            color: var(--navigation-bar-theme2-text-color);
          }
        }
      }

      .download-app-img {
        display: block;
      }

      .download-app {
        display: flex;
        align-items: center;
        padding: 2px 0;

        img {
          height: 35px;
          width: auto;
          vertical-align: middle;

          &:first-child {
            margin-right: 10px;
            @media (max-width: 1440px) {
             margin-right: 8px;
            }
          }

          @media (max-width: 1500px) {
            width: 90px;
          }

          @media (max-width: 1400px) {
            height: 23px;
          }
        }
      }
    }

    .invert-button {
      padding: map-get($pixel, sm) * 5 map-get($pixel, sm) * 10 map-get($pixel, sm) * 5 0 !important;
      background-color: var(--shop-by-department-theme2);
      color: var(--text-white);
      border: none;
      border-radius: 4 !important;
      overflow: hidden;
      position: relative;
      margin-right: map-get($pixel, md);

      a {
        padding: 22px 15px 22px 15px;
        background: var(--shop-by-department-img-theme2);
        vertical-align: middle;
      }

      >img {
        min-width: 18px;
      }

      >span {
        padding-left: map-get($pixel, md);
        display: block;
        font-size: 13px;

        @media screen and (min-device-width: 1540px) and (max-device-width: 1920px) {
          font-size: 15px;
        }
        @media screen and (min-width: 1350px) and (max-width: 1750px) {
          font-size: 12px;
        }
      }

      >svg {
        display: block !important;
        position: absolute;
        left: auto;
        right: 15px;
      }
    }

    .primary-outlined-button {
      padding: map-get($pixel, md) map-get($pixel, md) * 2;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      background-color: transparent;
      margin-right: 30px;
    }

    button {
      height: map-get($pixel, md) * 4;
      @include pointer;
      border-radius: calc(5px - 1px);
      @include flex-center;
      @include heading-text-sm-normal;
      justify-content: map-get($layout, center);
    }

    .primary-outlined-button {
      padding: map-get($pixel, md) map-get($pixel, md) * 2;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      background-color: transparent;
      margin-right: 30px;
    }
  }
}