@import "styles/mixins/index";

.information-section {
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: map-get($pixel, md) * 4 100px;
  @include breakpoint("max-md") {
    padding: 80px;
  }
  @include breakpoint("max-sm") {
    padding: 50px 0;
  }
  .information-alignment {
    @include flex-between;
    @include breakpoint("max-md") {
      @include grid-layout;
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .info-box {
    h1 {
      @include heading-large;
      color: var(--text-white);
      @include text-center;
      font-size: 40px;
      @include breakpoint("max-sm") {
        @include heading-2;
      }
    }
    p {
      @include heading-7;
      @include font-medium;
      @include text-center;
      color: var(--text-white);
      @include breakpoint("max-sm") {
        @include heading-text-md-medium;
      }
    }
  }
}
