.winepairing-section {
    margin-bottom: 30px;
    .paring-sec {
        @media (min-width: 992px) {
            // display: flex;
            // margin: 0 -15px;
        }
        .main-winepairing {
            .wine-title {
                color: #1a1a1a;
                text-transform: capitalize;
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 20px;
                @media (max-width: 1200px) {
                    font-size: calc(20px + (30 - 20) * ((100vw - 280px) / (1200 - 280)));
                }
            }
            .pairing-items {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 30px;
                @media screen and (max-width: 992px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media screen and (max-width: 767px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media screen and (max-width: 650px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media screen and (max-width: 575px) {
                    grid-template-columns: repeat(1, 1fr);
                }
                h2 {
                    font-size: 16px;
                    color: #D90416;
                    text-transform: uppercase;
                    line-height: 1.1;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                .dish-item-box {
                    padding: 15px;
                    background: white;
                    border-radius: 10px;
                    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.3);
                }
                .selection-list {
                    li {
                        cursor: pointer;
                        text-transform: capitalize;
                        font-size: 16px;
                        display: block;
                        padding: 5px 15px;
                        color: #333;
                        text-decoration: none;
                        margin-bottom: 15px;
                        background-color: #e0e0e0;
                        border-radius: 10px;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        @media (max-width: 575px) {
                            padding: 8px 12px;
                            border-radius: 5px;
                        }
                    }
                }
                .main-dish {
                    .selection-list {
                        li {
                            &.selected {
                                background: #F28705;
                                color: #fff;
                            }
                        }
                    }
                }
                .spice-dish {
                    .selection-list {
                        li {
                            &.selected {
                                background: #97BF04;
                                color: #fff;
                            }
                        }
                    }
                }
                .recommended-dish {
                    .selection-list {
                        li {
                            span {
                                padding: 2px;
                                border-radius: 3px;
                                text-align: center;
                                width: 40px;
                                margin-top: 3px;
                                font-size: 12px;
                                float: right;
                                clear: right;
                                color: #fff;
                                &.good {
                                    background: #F28705;
                                }
                                &.better {
                                    background: #97BF04;
                                }
                                &.best {
                                    background: #9EDEC3;
                                    color: #000;
                                }
                            }
                        }
                        .recommended {
                            &.good {
                                text-decoration: underline;
                            }
                            &.better {
                                text-decoration: underline;
                            }
                            &.best {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .recommended-dish-desc{
                margin-top: 20px;
                h1{
                    color: #1a1a1a;
                    text-transform: capitalize;
                    font-size: 30px;
                    font-weight: 500;
                    margin-bottom: 15px;
                    @media (max-width: 1200px) {
                        font-size: calc(20px + (30 - 20) * ((100vw - 280px) / (1200 - 280)));
                    }
                }
                p{
                    @media (max-width: 1200px) {
                        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1200 - 280)));
                    } 
                }
            }
        }
    }
}