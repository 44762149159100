@use "styles/mixins/breakpoint" as break-point;
@import "styles/mixins/breakpoint";
@import "styles/mixins/variables";
@import "styles/functions/common";
$break-point: (
  base: 0px,
  sm: 540px,
  md: 992px,
  lg: 1280px,
);
.container {
  width: 100%;
  padding: 0 160px;
  margin: 0 auto;
  @media (max-width: 1560px) {
    padding: 0 map-get($pixel, md) * 4;
  }
  @include breakpoint("max-sm") {
    padding: 0 map-get($pixel, md) * 2;
  }
}
.breadcrumb-container {
  width: 100%;
  ;
  padding: 0 110px;
  margin: 0 auto;
  @media (max-width: 1560px) {
    padding: 0 map-get($pixel, md) * 4;
  }
  @include breakpoint("max-sm") {
    padding: 0 map-get($pixel, md) * 2;
  }
}
.sub-container {
  max-width: 1260px;
  padding: 0 0;
  margin: 0 auto;
}
.page-container {
  max-width: 1320px;
  padding: 0 map-get($pixel, md) * 2;
  margin: 0 auto;
}
@media (min-width: 1250px) {
  .custom-container {
    max-width: 1250px;
    padding: 0;
    margin: auto;
  }
}
@media (max-width: 1200px) {
  .custom-container {
    width: 1250px;
    max-width: 100%;
    max-width: 100%
  }
}
@media (max-width: 1600px) {
  .custom-container {
    max-width: 100%;
    padding: 0 45px
  }
}
@media (max-width: 540px) {
  .custom-container {
    padding: 0 20px
  }
}