@import "styles/mixins/index";
.department-slider-theme2 {
  padding: 0 0 40px 0px;
  @media (max-width: 992px) {
    padding: 0 0 20px 0;
  }
  .page-title {
    margin-bottom: 20px;
  }
  .slick-slide {
    padding: 10px 32px;
    @include breakpoint("max-md") {
      padding: 10px 20px;
    }
    @include breakpoint("max-sm") {
      padding: 10px 12px;
    }
  }
  .slick-list {
    margin: 0 -32px;
    @include breakpoint("max-md") {
      margin: 0 -20px;
    }
    @include breakpoint("max-md") {
      margin: 0 -12px;
    }
  }
  .left-arrow-alignment {
    position: absolute;
    right: 42px;
    top: -63px;
    @include breakpoint("max-sm") {
      top: 50%;
      left: 13px;
      z-index: 9999;
    }
  }
  .right-arrow-alignment {
    position: absolute;
    right: 0;
    top: -63px;
    @include breakpoint("max-sm") {
      top: 50%;
      right: 13px;
      z-index: 9999;
    }
    svg {
      transform: rotate(178deg);
    }
  }
  .page-title-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-title {
      margin: 0;
      h2 {
        color: #1a1a1a;
        text-transform: capitalize;
        font-size: 30px;
        font-weight: 500;
        margin: 0 0 20px 0;
        @media (max-width: 1200px) {
          font-size: calc(16px + (30 - 16) * ((100vw - 280px) / (1200 - 280)));
        }
      }
    }
    a {
      @media (max-width: 1200px) {
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1200 - 280)));
      }
      svg {
        vertical-align: middle;
        path {
          fill: var(--fill-svg-color-theme2);
        }
      }
    }
  }
  .department-slider-theme2 {
    display: grid;
    flex-direction: row;
    gap: 30px;
    grid-template-columns: repeat(6, 1fr);
    @media (max-width: 1380px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1280px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 680px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
    @media (max-width: 300px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .card {
    background-color: #FEFFFA;
    padding: map-get($pixel, md) map-get($pixel, md) * 2 map-get($pixel, md) * 2 map-get($pixel, md) * 2;
    box-shadow: 0px 0px 15px rgba(129, 129, 129, 0.16);
    border-radius: 20px;
    @include pointer;
    border: 1px solid transparent;
    @include ease-in-out;
    min-height: 211px;
    &:hover {
      border: 1px solid var(--primary-color);
    }
    @media (max-width: 480px) {
      padding: 10px;
      min-height: 160px;
    }
    .card-details {
      padding: map-get($pixel, sm) + 2px 0 0 0;
      p {
        @include text-bold;
        color: var(--black-text-color);
        @include text-center;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 992px) {
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1200 - 280)));
        }
      }
    }
    .card-image {
      @include flex-center;
      justify-content: map-get($layout, center);
      img {
        max-width: 160px;
        max-height: 160px;
        height: 160px;
        object-fit: cover;
        padding: 10px;
        border-radius: 100%;
        @media (max-width: 480px) {
          max-width: 100px;
          max-height: 100px;
          height: 100px;
        }
      }
    }
  }
  .custom-arrow-design {
    width: 32px;
    border-radius: 50%;
    background-color: var(--bg-light-400);
    @include pointer;
    height: 32px;
    @include flex-center;
    justify-content: map-get($layout, center);
    @include ease-in-out;
    color: var(--gray-text-color);
    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-white);
    }
  }
}