@import "styles/mixins/index";
.city-item-theme2 {
  .show-items {
    position: relative;
    @include breakpoint("max-sm") {
      width: 100%;
    }
    @include breakpoint("min-md") {
      width: unset;
    }
    button {
      font-size: 15px;
      line-height: 19px;
      font-family: var(--heading-font-family), var(--default-font-family);
      font-weight: 500;
      padding: 9px 12px;
      border: 1px solid var(--light-border-color);
      color: var(--black-5-light);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      font-weight: 400;
      width: 416px;
      position: relative;
      transition: 0.2s ease-in-out;
      @include breakpoint("max-sm") {
        width: 100%;
      }
      &:focus {
        border: 1px solid var(--primary-color);
      }
      span {
        padding: 0 map-get($pixel, sm) + 1px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
      }
    }
    .submenu-list {
      .disable-lable {
        font-size: 14px;
        line-height: 16px;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 400;
        color: var(--black-3);
        padding: 11px 15px;
        opacity: 0.6;
      }
      .submenu-item {
        padding: 11px 30px;
      }
    }
    .text-danger {
      color: var(--bg-danger);
    }
    .dropdown-show {
      max-height: 300px;
      z-index: 999999;
      overflow-y: auto;
      border: 1px solid var(--light-border-color);
      // overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 4px;
        background: #999;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--invert-primary-color);
      }
      .list-menu{
        border: none;
        ul{
          overflow: hidden;
          max-height: none;
        }
      }
    }
    .dropdown-hidden {
      z-index: 999999;
    }
  }
}