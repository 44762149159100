@import "styles/mixins/index";

.navigation-bar {
  border-bottom: 1px solid var(--gray-border-color);

  .navigation-bar-wrapper {
    padding: map-get($pixel, md) + 2px 0;
    @include flex-between;

    @include breakpoint("max-md") {
      display: none;
    }

    .download-app {
      @include breakpoint("max-md") {
        display: none;
      }

      .secondary-button {
        padding: map-get($pixel, sm) * 2 + 1px;
        background-color: var(--secondary-color);
        color: var(--text-white);
        @include heading-text-sm;
        height: 40px;
        border: none;
        border-radius: map-get($pixel, sm) - 1px;
      }
    }

    .menu {
      @include flex-center;

      @include breakpoint("max-md") {
        display: none;
      }

      .sub-menu-dropdown {
        position: absolute;
        min-width: 180px;
        background-color: var(--bg-white);
        max-height: 0px;
        overflow: hidden;
        @include ease-in-out;
        top: 30px;
        z-index: 999;

        .list-align {
          padding: map-get($pixel, sm) * 3;
          box-shadow: $normal-shadow;
          border: 1px solid var(--light-border-color);
          @include normal-radius;

          a:last-child {
            padding: 0;
          }

          > a {
            @include text-semi-bold;
            color: var(--black-3);
            display: block;
            padding: 0 0 map-get($pixel, sm) * 2 0;
            @include ease-in-out;

            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }

      .submenu-wrapper:hover .sub-menu-dropdown {
        max-height: 100vh !important;
        @include ease-in-out;
      }

      .nav-menu {
        padding-left: map-get($pixel, md) * 3;

        .shop-by-department {
          position: relative;

          .invert-button {
            color: var(--black-text-color);
            border: none;
            margin-right: map-get($pixel, md) * 3;
            font-size: 16px;
            line-height: 22px;
            background-color: #fff;
            display: flex;
            @include text-semi-bold;

            > img {
              min-width: 9px;
              margin-left: 9px;
              margin-top: 8px;
            }

            > span {
              display: block;
            }
          }

          .shop-by-department-menu {
            position: absolute;
            width: 550px;
            z-index: 99999;
            top: 45px;

            .shop-by-department-menu-content {
              border-radius: map-get($pixel, sm) + 3px;
              border: 1px solid var(--primary-color);
              background-color: var(--bg-white);
              padding: map-get($pixel, md) * 2;

              .grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: map-get($pixel, sm) * 3;
                a {
                  margin-right: 0px;
                }
                .grid-items {
                  border: 1px solid var(--border-color);
                  @include flex-center;
                  border-radius: map-get($pixel, sm) - 1px;
                  padding: map-get($pixel, md);
                  @include pointer;
                  @include ease-in-out;

                  &:hover {
                    border: 1px solid var(--primary-color);
                  }

                  .department-name {
                    padding-left: map-get($pixel, lg);

                    p {
                      @include heading-text-sm-normal;
                      color: var(--black-text-color);
                    }
                  }

                  .department-icon {
                    width: 36px;
                    height: 36px;

                    > img {
                      width: 36px;
                      height: 36px;
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }

          .shop-by-department-menu-show {
            max-height: 100vh;
            overflow: hidden;
            @include ease-in-out;
          }

          .shop-by-department-menu-hidden {
            max-height: 0px;
            overflow: hidden;
            @include ease-in-out;
          }
        }

        a:last-child {
          margin-right: map-get($pixel, null);
        }

        a {
          margin-right: map-get($pixel, md) * 4;

          @include breakpoint("md") {
            margin-right: map-get($pixel, md) * 3;
          }

          @include text-semi-bold;
          position: map-get($layout, relative);
          color: var(--black-3);
          @include pointer;
          position: relative;
          @include ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }

          img {
            margin-left: map-get($pixel, md);
          }
        }

        .submenu-wrapper {
          margin-right: map-get($pixel, md) * 4;

          @include breakpoint("md") {
            margin-right: map-get($pixel, md) * 3;
          }

          @include text-semi-bold;
          position: map-get($layout, relative);
          color: var(--black-3);
          @include pointer;
          @include flex-center;
          position: relative;
          @include ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }

          img {
            margin-left: map-get($pixel, md);
          }
        }
      }

      .wrapper {
        @include flex-center;

        button {
          height: map-get($pixel, md) * 4;
          @include pointer;
          border-radius: calc(5px - 1px);
          @include flex-center;
          @include heading-text-sm-normal;
          justify-content: map-get($layout, center);
        }

        .primary-outlined-button {
          padding: map-get($pixel, md) map-get($pixel, md) * 2;
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          background-color: transparent;
        }
      }
    }
  }
}
