@import "styles/mixins/index";

.giving-back-card-section {
  padding: map-get($pixel, md) * 7 100px map-get($pixel, md) * 10 100px;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 4 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 727px;
    gap: 0 map-get($pixel, md) * 8;
    padding: 0 map-get($pixel, md) * 5 + 5px;
    align-items: top center;

    @include breakpoint("max-xl") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    @include breakpoint("max-sm") {
      padding: 0;
    }

    .grid-items {
      img {
        width: 100%;
        max-height: 634px;
        border-radius: 8px;
        // object-fit: cover;

        @include breakpoint("max-md") {
          height: 360px;
        }

        @include breakpoint("max-sm") {
          height: 240px;
        }
      }

      span {
        @include text-lg;
        color: var(--gray-3);
        font-size: 18px;
        margin: 0 0 map-get($pixel, md) * 2 0;
        line-height: 28px;

        @include breakpoint("max-sm") {
          @include text-sm;
        }
      }

      p {
        @include text-md;
        color: var(--gray-3);
        margin: 0 0 map-get($pixel, md) * 2 0;
        line-height: 36px;
        text-align: justify;


        @include breakpoint("max-sm") {
          @include heading-text-md-medium;
        }
      }

      h1 {
        margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
        @include heading-9-medium;
        color: var(--invert-secondary-color);

        @include breakpoint("max-sm") {
          @include heading-3;
        }
      }
    }
  }
}