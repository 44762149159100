.input {
  position: map-get($layout, relative);
  input {
    width: 100%;
    height: 46px;
    border: 1px solid var(--light-border-color);
    padding: 0 37px;
    @include heading-text-sm-normal;
    color: var(--gray-4);
    @include font-normal;
    border-radius: map-get($pixel, sm) - 1px;
    transition: 0.2s ease-in-out;
    &:focus {
      border: 1px solid var(--primary-color);
    }
  }
  ::placeholder {
    outline: none;
    @include heading-text-sm-normal;
    @include font-normal;
  }
  .left-icon-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: map-get($pixel, sm) * 3;
  }
  .right-icon-align > img {
    @include pointer;
  }
  .right-icon-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: map-get($pixel, sm) * 3;
  }
}

input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label:before {
  content: "";
  border: 1px solid #a5a5a5;
  border-radius: 2px;
  display: flex;
  width: 14px;
  height: 14px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  font-size: 8px !important;
  justify-content: center;
  align-items: center;
}
input[type="checkbox"]:checked + label:before {
  background-color: var(--primary-color);
  color: var(--bg-white);
  border: 1px solid var(--primary-color);
}

input[type="checkbox"]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 13px;
  border: 2px solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
input[type="checkbox"]:disabled + label:before {
  opacity: 0.6;
}

.form-control {
  .relative-div {
    position: map-get($layout, relative);
  }
  label {
    @include heading-6-normal;
    color: var(--black-5);
    display: block;
    padding: 0 0 map-get($pixel, sm) * 2 - 4px 0;

    @include breakpoint("max-md") {
      font-size: 13px;
      line-height: 18px;
    }

    span {
      color: var(--bg-danger);
    }
  }
  input {
    width: 100%;
    height: 46px;
    border: 1px solid var(--light-border-color);
    padding: 0 16px;
    @include heading-6-normal;
    color: var(--gray-4);
    @include font-normal;
    border-radius: map-get($pixel, sm) - 1px;
    transition: 0.2s ease-in-out;
    &:focus {
      border: 1px solid var(--primary-color);
    }
  }
  textarea {
    width: 100%;
    height: 101px;
    border: 1px solid var(--border-color-7);
    padding: 16px;
    @include heading-6-normal;
    color: var(--gray-4);
    @include font-normal;
    border-radius: map-get($pixel, sm) - 1px;
    transition: 0.2s ease-in-out;
    &:focus {
      border: 1px solid var(--primary-color);
    }
  }
}
