@import "styles/mixins/index";

#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 50%;
    padding: 0;
    width: 45px;
    height: 45px;
    border: 0;
    z-index: 999;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
    @media (max-width: 768px) {
      bottom: 80px;
      width: 35px;
    height: 35px;
    }
    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
    &:hover {
        background-color: var(--primary-color);
    }
  }