@import "styles/mixins/index";


.special-comment-section {
    padding: map-get($pixel, md) * 3 + 2px 0 0 0;

    .form-control {
        label {
            font-size: 21px;
            @include font-medium;

            @include breakpoint("max-sm") {
                @include heading-text-sm;
            }
        }
    }
}