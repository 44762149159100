@import "styles/mixins/index";

.newsletter-section {
  background-color: var(--bg-dark-black);
  padding: map-get($pixel, md) * 4 0;
  .grid {
    @include grid-layout;
    grid-template-columns: min(398px) minmax(0, 1fr) min(266px);
    gap: 0 map-get($pixel, md) * 4;
    justify-content: space-between;
    align-items: map-get($layout, center);
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: map-get($pixel, md) * 2 + 5px 0;
    }
    .grid-items:last-child {
      @include flex-center;
      @media only screen and (max-width: 767px) {
          justify-content: center;
      }
    }
    .grid-items {
      color: var(--text-white);
      h1 {
        @include heading-5;
        margin: 0 0 map-get($pixel, md) 0;
        @include breakpoint("max-sm") {
          @include heading-7;
          text-align: center;
        }
      }
      p {
        @include text-sm-normal;
        @include breakpoint("max-sm") {
          @include text-sm;
          text-align: center;
        }
      }
      span {
        @include heading-6;
      }
      .social-align {
        @include flex-center;
        padding-left: 10px;

        > div {
          width: 35px;
          height: 35px;
          background-color: var(--bg-white);
          @include flex-center;
          border-radius: 50%;
          justify-content: map-get($layout, center);
          margin-right: map-get($pixel, md) + 6px;
          @include pointer;
          > i {
            color: var(--black-1);
          }
          > a {
            color: var(--black-1);
            display: block;
            border-radius: 50%;
            padding: 10px;
          }
        }
        > div:last-child {
          margin-right: 0px;
        }
      }

      .subscribe {
        position: map-get($layout, relative);
        .button-align {
          position: absolute;
          top: 0;
          right: 0;
          > button {
            padding: map-get($pixel, md) + 4px map-get($pixel, md) * 2 + 1px;
            background-color: var(--newsletter-btn-bg-color);
            @include heading-text-sm;
            color: var(--newsletter-btn-font-color);
            height: 46px;
            border: none;
            border-radius: 0 4px 4px 0;
          }
        }
        input {
          width: 100%;
          padding-left: map-get($pixel, md) * 2 + 5px;
          height: 46px;
          @include normal-radius;
          background-color: var(--bg-white);
          @include heading-text-sm-normal;
          @include font-normal;
          color: var(--text-current);
          border: none;
        }
        ::placeholder {
          color: var(--text-current);
        }
        .success-text {
          color: var(--text-white);
        }
        .error-text {
          color:var(--text-white);
        }
      }
      form{
        width: 100%;
      }
    }
  }
}
