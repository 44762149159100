@import "styles/mixins/index";

.listing-sidebar-sticky-jobs {
  position: sticky;
  top: 40px;

  @include breakpoint("max-md") {
    display: block;
  }

  @include breakpoint("max-sm") {
    display: block;
  }

  p {
    font-size: 14px;
    padding-top: 3px;
    margin-top: 3px;
    padding-left: 4px;

    span {
      color: var(--secondary-color);
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 600;
    font-family: var(--default-font-family);
    color: var(--gray-400);
  }

  .post-list {
    position: relative;
    width: 100%;
    min-height: 1px;
    margin-top: 25px;
    font-family: var(--default-font-family);
    border: 1px solid #f9f9ff;

    .single-post {
      padding: 30px;
      margin-bottom: 30px;
      background-color: #f9f9ff;
    }

    .flex-row {
      flex-direction: row;
    }

    .d-flex {
      display: flex;

      .html-parser{
        text-align: left;
      }
    }

    .justify-content-between {
      justify-content: space-between;
    }

    .secondary-button {
      // padding: map-get($pixel, sm) * 2 + 1px;
      padding: 10px 15px;

      background-color: var(--secondary-color);
      color: var(--text-white);
      @include heading-text-sm;
      height: 40px;
      // border: none;
      border-radius: map-get($pixel, sm) - 1px;
      display: inline-block;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 22px;
      color: var(--primary-color);
      font-weight: 500;
      font-family: var(--default-font-family) !important;
    }

    h6 {
      margin-top: 10px;
      color: #222;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
      font-family: var(--default-font-family) !important;
    }

    li {
      display: inline-block;
      background-color: #fff;
      border: 1px solid #eee;
    }

    p {
      margin-top: 4px;
      margin-bottom: 1rem;
      line-height: 28px;
      justify-content: center;
      font-size: 15px;
    }

    h5 {
      color: #222;
      font-weight: 400;
      margin-bottom: 15px;
      font-family: var(--default-font-family) !important;
    }

    .address {
      margin-bottom: 15px;

      span {
        font-weight: 400;
        padding: 5px;
      }
    }
  }
}