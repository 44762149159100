.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .button-container {
    background-color: gray;
    width: 40.5%;
    border: 2px solid black;
    span {
      padding: 2rem;
    }
  }

  .pdf-container {
    max-height: 100%;
    border: 2px solid black;
    overflow: auto;

    .pdf-inner {
      transform: scale(1);
    }
  }
}
