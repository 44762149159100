@import "styles/mixins/index";

.copyright-section {
  background-color: var(--footer-theme2-bg-color);
  padding: map-get($pixel, md) 0;
  @include breakpoint("max-sm") {
    padding: 0;
  }
  .powered-by-all-align-content {
    @include flex-between;
    @include breakpoint("max-sm") {
      display: block;
      padding: 0;
    }
    .payment{
      @include breakpoint("max-sm") {
        @include text-center;
        margin: 0 0 map-get($pixel, sm) * 3 0;
      }
      .preview{
        img{
          border-radius: 2px;
          margin-right: 8px;
        }
      }
    }
  }
  .CoprRight{
    padding: 15px 0;
    border-top: 2px solid #333;
    @media (max-width: 768px) {
    padding: 15px 0 0 0;
      padding-bottom: 60px;
    }
  }
  p {
    @include text-sm-normal;
    color: var(--footer-font-color);
    margin: 0;
    font-size: 13px;
    @include breakpoint("max-sm") {
      @include text-center;
      margin: 0 0 map-get($pixel, sm) * 3 0;
    }
  }
  .social-media{
    display: inline-block;
    @include breakpoint("max-sm") {
      display: block;
      margin: 0 0 map-get($pixel, sm) * 3 0;
    }
    ul{
      display: flex;
      align-items: center;
      @include breakpoint("max-sm") {
       justify-content: center;
      }
      li{
        margin: 0 11px;
        a{
          display: block;
        }
      }
    }
  }
  .powered-by-align {
    @include flex-center;
    @include breakpoint("max-sm") {
      display: block;
    }
    img {
      @include breakpoint("max-sm") {
        margin: 0 auto;
        @include flex-center;
        justify-content: map-get($layout, center);
      }
      max-width: 130px;
    }
    span {
      @include text-xs-normal;
      padding-right: 15px;
      color: var(--black-text-color);
      @include breakpoint("max-sm") {
        display: block;
        @include text-center;
      }
    }
  }
}
