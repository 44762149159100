@import "styles/mixins/index";

.custom-list-cart-section {
  .cart-grid {
    @include grid-layout;
    padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 7 +
      map-get($pixel, sm) 0;
    grid-template-columns: minmax(0, 1fr) 504px;
    gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm);

    @include breakpoint("max-xl") {
      grid-template-columns: minmax(0, 1fr) 404px;
    }

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
      padding: 20px 0 40px 0;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
    }
  }
}

.custom-list-cart-section-align {
  .cart-title {
    padding: map-get($pixel, md) * 5 0 0 0;

    @include breakpoint("max-sm") {
      padding: map-get($pixel, md) * 3 0 0 0;
    }

    h1 {
      @include heading-9-medium;
      color: var(--black-text-color);
      margin: 0 0 map-get($pixel, md) * 2 - 1px 0;

      @include breakpoint("max-sm") {
        @include heading-5;
      }
    }
  }

  .page-grid-align {
    @include flex-between;
    width: calc(100% - 547px);

    @include breakpoint("max-xl") {
      min-width: calc(100% - 450px);
    }

    @include breakpoint("max-md") {
      width: 100%;
      @include grid-layout;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .cart-action-align {
      @include flex-center;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        gap: 20px 0;
      }

      .clear-cart {
        .clear-icon {
          cursor: pointer;
        }
        .disabled {
          pointer-events: none;
          cursor: default;
          opacity: 0.6;
        }
      }

      button {
        @include heading-text-sm-normal;
        @include font-medium;
        height: 35px;
        padding: 8px 20px;
        line-height: 13px;
        border-radius: calc(5px - 1px);

        @include breakpoint("max-sm") {
          padding: 8px 17px;
        }
      }

      .add-to-list-hidden {
        max-height: 0px;
        transition: 0.3s ease-in-out;
        overflow: hidden;
      }

      .add-to-list-show {
        max-height: calc(100vh - 20px);
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      .new-list-input-dropdown {
        width: 223px;
        height: 150px;
        position: absolute;
        left: 229px;
        top: 41px;
        z-index: 99999999;

        @include breakpoint("max-sm") {
          right: 0;
          left: auto;
        }

        .new-list-input-list-style {
          filter: drop-shadow(0px 0px 20px rgba(116, 116, 116, 0.16));
          background-color: var(--bg-white);
          border: 1px solid var(--primary-color);
          border-radius: 6px;
          padding: 13px 17px;

          .save-number {
            span {
              @include text-sm-semi-bold;
              color: var(--primary-color);
              display: block;
              text-align: right;
              @include pointer;
            }
          }

          .form-control {
            margin: 0 0 9px 0;

            label {
              font-size: 15px;
              color: #000;
              line-height: 16px;
            }

            input {
              height: 24px;
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }

      .add-to-list-dropdown {
        width: 221px;
        border-radius: 6px;
        position: absolute;
        top: 41px;
        z-index: 99999;

        .add-list-dropdown-design {
          background-color: var(--bg-white);
          border: 1px solid var(--primary-color);
          filter: drop-shadow(0px 0px 20px rgba(116, 116, 116, 0.16));
          height: 100%;
          border-radius: 6px;
          user-select: none;

          .dropdown-body {
            padding: 13px 18px;

            .apply-text-alignment {
              p {
                @include text-sm-semi-bold;
                color: var(--primary-color);
                @include pointer;
                text-align: right;
              }
            }

            .relative-checkbox {
              position: relative;
              @include flex-center;
              padding: 0 0 11px 0;

              label {
                @include flex-center;
                @include text-sm-normal;
                color: var(--black-5);

                span {
                  padding-left: 7px;
                }
              }
            }
          }

          .new-list-title {
            background-color: #f6f6f6;
            padding: 4px 8px;
            border-radius: 6px;
            position: relative;

            p {
              @include text-sm-semi-bold;
              color: var(--secondary-color);
              margin: 0;
              text-align: right;
            }
          }
        }
      }

      .add-list-button {
        padding-right: map-get($pixel, md) * 5;
        position: relative;

        @include breakpoint("max-sm") {
          padding: 0;
          display: flex;
          justify-content: flex-end;
        }

        button {
          padding: 8px 13px;
          background-color: transparent;
          color: var(--primary-color);
          @include flex-center;
          border: 1px solid var(--primary-color);

          span {
            padding-right: 9px;
          }
        }
      }

      .check-reward {
        margin-right: map-get($pixel, sm) * 3;

        @include breakpoint("max-sm") {
          margin: 0;
        }

        button {
          border: none;
          background-color: var(--secondary-color);
          color: var(--bg-white);
        }
      }
    }

    div {
      p {
        @include text;
        margin: 0;
        color: var(--gray-7-light);
        // padding-left: 60px;
      }
    }
   
   
    .action-buttons{
      .add-list-button {
        padding-left: 15px;
        padding-right: 0px;
        position: relative;
  
        @include breakpoint("max-sm") {
          padding: 0;
          display: flex;
          justify-content: flex-end;
        }
  
        button {
          padding: 8px 13px;
          background-color: transparent;
          color: var(--primary-color);
          @include flex-center;
          border-radius: 4px;
          border: 1px solid var(--primary-color);
  
          span {
            padding-right: 9px;
          }
        }
      }
      display: flex;
      justify-content: space-around;
      @media (max-width: 480px) {
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
}
