@import "styles/mixins/index";

.tab-alignment {
  ul {
    border-bottom: 1px solid var(--gray-text-color);
    padding: 0 0 map-get($pixel, sm) * 3 0;
    @include flex-center;
    @include breakpoint("max-sm") {
      @include flex-between;
    }
    .active-offer-type {
      color: var(--black-text-color);
      &::before {
        content: "";
        position: absolute;
        height: 3px;
        width: 100%;
        bottom: -16px;
        left: 0;
        background-color: var(--secondary-color);
      }
    }
    li {
      position: map-get($layout, relative);
      padding: 0 map-get($pixel, md) * 3;
      margin-right: map-get($pixel, md) * 8;
      @include pointer;
      @include heading-3-normal;
      color: var(--gray-9);
      @include ease-in-out;
      @include breakpoint("max-md") {
        @include heading-text-md-medium;
        margin-right: 26px;
        padding: 0;
      }
      @include breakpoint("max-sm") {
        @include heading-text-md-medium;
        @include font-normal;
        font-size: 16px;
        margin: 0;
      }
      &:hover {
        color: var(--black-text-color);
      }
      &::after {
        content: "";
        position: absolute;
        height: 3px;
        width: 100%;
        bottom: -16px;
        left: 0;
        background-color: var(--secondary-color);
        transform: scaleX(0);
        transform-origin: left;
        opacity: 0;
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;
      }
    }
  }
}
.tab-alignment ul li:hover::after {
  transform: scaleX(1);
  opacity: 1;
}
