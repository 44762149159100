@import "styles/mixins/index";

.address-info-wrapper {
  .address-info-section {
    border: 1px solid var(--border-color-8);
    padding: 23px 27px 10px 27px;
    border-radius: 8px;
    margin: 25px 0;

    @include breakpoint("max-sm") {
      padding: 15px;
    }

    > div {
      @include flex-between;
      padding-bottom: 10px;

      h1 {
        @include heading-4xl;
        margin: 0;
        color: var(--black-5);
      }
    }

    .radio-alignment {
      padding: 0 0 20px 0;
      @include flex-center;

      div {
        position: relative;
        display: -webkit-box;

        span {
          @include heading-text-sm;
          display: block;
          padding-left: 15px;
          color: var(--primary-color);
        }
        p {
          max-width: 250px;
        }
      }

      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        padding-left: 25px;
      }

      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        top: 5px;
      }
    }
  }

  .primary-outlined-button {
    padding: map-get($pixel, md) map-get($pixel, md) * 2;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    @include heading-text-sm;
    background-color: transparent;
    @include normal-radius;
  }
  .contact-helper-text {
    color: var(--bg-danger);
    font-size: 12px;
    width: 100%;
    display: block;
    padding-left: 145px;
    align-self: left;
  }
}
