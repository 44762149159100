@import "styles/mixins/index";

.slick-slide div {
  outline: none;
}

.slick-dots {
  li {
    margin: 0px;
    > button::before {
      font-size: 14px;
    }
  }
  li.slick-active > button::before {
    opacity: 1;
    color: var(--secondary-color);
  }
}

.spotlight-banner-section {
  min-height: 200px;
  padding-bottom: 20px;
  .slick-dots {
    bottom: 24px;
    // @include breakpoint("max-md") {
    //   bottom: 50px;
    // }
  }
  .spotlight-banner {
    width: 100%;
    height: 596px;
    background-size: cover;
    background-position: center center;

    @include breakpoint("max-md") {
      height: 480px;
    }
    // @media screen and (max-width: 768px) {
    //   height: 320px;
    // }
    @media screen and (max-width: 768px) {
      height: 480px;
    }
    @media screen and (max-width: 391px) {
      height: 350px;
    }
    .spotlight-banner-grid {
      @include grid-layout;
      grid-template-columns: minmax(0, 2fr) 724px;
      gap: 0 204px;
      justify-content: space-between;
      height: 550px;
      padding-left: 130px;
      align-items: center;
      @include breakpoint("max-xl") {
        padding: 0;
        gap: 0 60px;
      }
      @include breakpoint("max-md") {
        height: 360px;
        // padding: map-get($pixel, md) * 4 + 7px 0 0 0;
      }
      @media screen and (max-width: 390px) {
        height: 320px;
      }
      @include breakpoint("max-sm") {
        // height: auto;
        gap: 0 10px;
        padding: 0 0 0 0;
      }
      @include breakpoint("max-md") {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
      }
      .spotlight-banner-grid-items:nth-child(1){
        padding: map-get($pixel, md) * 3 + 2px 0 0 0;
      }
      .spotlight-banner-grid-items:nth-child(2) {
        padding: map-get($pixel, md) * 3 + 2px 0 0 0;
        height: 100%;
        @media screen and (max-width: 992px) {
          height: auto;
          padding: map-get($pixel, md) * 1 + 2px 0 0 0;
          }
        @include breakpoint("max-md") {
          display: block;
        }
        iframe {
          @media screen and (max-width: 768px) {
            width: 100%;
            height: auto;
          }
        }
        @media only screen and (min-device-width: 768px) {
          display: block;
        }
      }
      .spotlight-banner-grid-items {
        img {
           height: auto;
            max-height: 89%;
            max-width: 100%;
            width: 100%;
            @media screen and (max-width: 768px) {
              width: 40%;
              margin: auto;
            }
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 912px) {
          video {
            width: 500px;
            height: 310px;
          }
          iframe {
            width: 400px;
          }
        }
        @media only screen and (min-device-width: 912px) and (max-device-width: 1316px) {
          video {
            width: 600px;
            height: 360px;
          }
          iframe {
            width: 550px;
          }
        }
        > h1,
        h2 {
          // @include heading-1;
          font-size: calc(12px + (55 - 12) * ((100vw - 280px) / (1850 - 280)));
          font-weight: 400;
          line-height: 1.2;
          color: var(--slider-text-h1-color);
          @include breakpoint("max-sm") {
            font-size: calc(8px + (22 - 8) * ((100vw - 280px) / (767 - 280)));
          }
          @media screen and (max-width: 390px) {
            font-size: calc(12px + (15 - 12) * ((100vw - 280px) / (390 - 280)));
          }
        }
        h2 {
          color: var(--slider-text-h2-color);
          margin: map-get($pixel, sm) * 3  0 map-get($pixel, sm) * 3 0;
          @include breakpoint("max-sm") {
            margin: map-get($pixel, md) 0 map-get($pixel, md) 0;
            font-size: calc(15px + (30 - 15) * ((100vw - 280px) / (767 - 280)));
          }
          @media screen and (max-width: 390px) {
            font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (390 - 280)));
          }
        }
        > p {
          @include text-sm;
          font-size: 18px;
          line-height: 1.5;
          margin: 0 0 map-get($pixel, md) * 4 0;
          color: var(--slider-text-p-color);
          @include breakpoint("max-sm") {
            font-size: calc(13px + (17 - 13) * ((100vw - 280px) / (767 - 280)));

            margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
          }
        }
        > button {
          background-color: var(--primary-color);
          padding: map-get($pixel, sm) * 3 - 1px map-get($pixel, md) * 3;
          border: none;
          color: var(--text-white);
          @include text-semi-bold;
          font-family: var(--heading-font-family);
          text-transform: capitalize;
          border-radius: 4px;
          @include breakpoint("max-sm") {
            padding: map-get($pixel, md) map-get($pixel, md) * 1 + 3px;
            @include text-sm-semi-bold;
            font-size: 12px;
          }
          @media screen and (max-width: 390px) {
            padding: 6px 10px;
            font-size: 10px;

          }
        }
      }
    }
  }
}
