@import "styles/mixins/index";

.welcome-white-foodliners-section {
  padding: map-get($pixel, md) * 8 0 map-get($pixel, md) * 1 0;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 4 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 727px;
    gap: 0 map-get($pixel, md) * 8;
    padding: 0 map-get($pixel, md) * 5 + 5px;
    align-items: center;

    @include breakpoint("max-xl") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    @include breakpoint("max-sm") {
      padding: 0;
    }

    .grid-items {
      h3 {
        text-align: left;
        font-size: 34px;
        font-weight: 600;
      }

      img {
        width: 100%;
        height: auto;
        max-height: 634px;
        border-radius: 8px;
        object-fit: cover;

        // @include breakpoint("max-md") {
        //   height: 360px;
        // }

        // @include breakpoint("max-sm") {
        //   height: 240px;
        // }
        @media only screen and (max-width: 450px) {
          height: auto;
        }
      }

      .app-notes {
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          text-align: left;

          h4 {
            line-height: 20px;
            margin: 0 0 6px 0;
            font-size: 18px;
            font-weight: 500;
            color: blueviolet;
          }
        }

        img {
          height: 80px !important;
          width: 180px !important;
          object-fit: contain;
          /* margin-left: auto;
          margin-right: auto; */
          display: block;
          padding: 14px;
        }


        .innergrid {
          display: inline-flex;
          margin: auto;
        }
      }

      .notes {
        span {
          line-height: 10px;
          margin: 0 0 6px 0;

          p {
            font-size: small;
            color: #000;
            line-height: 12px;
            font-style: italic;
            margin: 0px;
          }
        }
      }
      .innergrid{
        span{
        @media only screen and (max-width: 480px) {
          padding: 10px 5px;
        }
      }
      }
      span {
        @include text-lg;
        color: var(--gray-3);
        font-size: 18px;
        margin: 0 0 map-get($pixel, md) * 2 0;
        line-height: 28px;
        padding: 20px;

        img {
          height: 80px !important;
          width: 226px !important;
          @media only screen and (max-width: 650px) {
            height: auto !important;
            width: 100px !important;
          }
          @media only screen and (max-width: 400px) {
            width: 120px !important;
          }

        }

        @include breakpoint("max-sm") {
          @include text-sm;
        }
      }

      p {
        font-size: 18px;
        color: var(--gray-3);
        margin: map-get($pixel, md) * 2 0 map-get($pixel, md) * 2 0;
        line-height: 34px;
        text-align: justify;


        @include breakpoint("max-sm") {
          @include heading-text-md-medium;
        }
      }

      h1 {
        margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
        @include heading-9-medium;
        color: var(--invert-secondary-color);

        @include breakpoint("max-sm") {
          @include heading-3;
        }
      }
    }

    .faq {
      h1 {
        font-size: 24px;
        font-weight: 600;
      }

      span {
        margin: 20px 0 20px 0;

        p {
          font-size: 16px;
          line-height: 26px;
          text-align: justify;
        }
      }
    }
  }
}