@import "styles/mixins/index";
.campaign-of-week-theme2 {
  padding-bottom: 20px;
  .compaign-product {
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      @media (max-width: 1200px) {
        margin-bottom: calc(15px + (20 - 15) * ((100vw - 280px) / (1200 - 280)));
      }
      h2 {
        color: #1a1a1a;
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        @media (max-width: 1200px) {
          font-size: calc(17px + (25 - 17) * ((100vw - 280px) / (1200 - 280)));
        }
      }
      a {
        font-size: 16px;
        svg {
          vertical-align: middle;
          path {
            fill: var(--fill-svg-color-theme2);
          }
        }
        @media (max-width: 1200px) {
          font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1200 - 280)));
        }
      }
    }
    &.main-product {
      h2 {
        color: #1a1a1a;
        text-transform: capitalize;
        font-size: 30px;
        font-weight: 500;
        @media (max-width: 1200px) {
          font-size: calc(17px + (25 - 17) * ((100vw - 280px) / (1200 - 280)));
        }
      }
      a {
        @media (max-width: 1200px) {
          font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1200 - 280)));
        }
        svg {
          vertical-align: middle;
          path {
            fill: var(--fill-svg-color-theme2);
          }
        }
      }
    }
  }
  .product-row {
    &.deal-all-wrapper{
      margin: -7.5px -7.5px -7.5px 7.5px;
      @media (max-width: 767px) {
        margin: 0 -7.5px;
      }
     .product-col-xl-4{
      padding: 7.5px;
     }
     }
  }
  .product-row {
    display: flex;
    flex-wrap: wrap;
  }
  .product-col-xl-4 {
    float: left;
    width: 25%;
  }
  .product-col-xl-6 {
    float: left;
    width: 50%;
  }
  .product-col-xl-12 {
    float: left;
    width: 100%;
  }
  .product-col-sm-4 {
    float: left;
    width: 25%
  }
  .product-col-sm-8 {
    float: left;
    width: 75%
  }
  @media (max-width: 1024px) {
    .product-col-xl-4 {
      width: 50%;
    }
    .product-col-sm-4 {
      width: 50%;
    }
    .product-col-sm-8 {
      width: 50%;
    }
    .product-row {
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    .product-col-xl-4 {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .product-col-xl-4 {
      width: 50%;
    }
    .product-col-sm-4 {
      width: 100%;
      margin-bottom: 15px;
    }
    .product-col-sm-8 {
      width: 100%;
    }
  }
  @media (max-width: 480px) {
    .product-col-xl-4 {
      width: 50%;
    }
  }
}