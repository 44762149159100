@import "styles/mixins/index";

.deal-product {
    .deal-product-main-wrapper {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 12px 0 #20b52652;
        padding: 10px 10px 15px;
        height: 100%;
        border: 1px solid #ddd;
        border-radius: 10px;
        outline: 3px solid transparent;
        outline-offset: -3px;
        transition: all 0.6s ease-in-out;

        @media (max-width: 767px) {
            height: auto;
        }

        &:hover {
            border-color: var(--border-color-theme2);
            outline-color: var(--border-color-theme2);
        }

        .deal-product-wrap {
            .deal-product-main-wrap {
                .offer-tag-quickview {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    z-index: 11;
                    position: relative;

                    .offer-product-tag {
                        position: relative;
                        display: inline-block;

                        .product-tag {
                            border-radius: 4px;
                            color: #fff;
                            font-size: 13px;
                            font-weight: 400;
                            background: var(--product-tag-bg-color);
                            padding: 8px 10px;
                            display: inline-block;
                        }
                    }
                }

                .deal-product-img {
                    margin-bottom: 15px;
                    text-align: center;
                    overflow: hidden;

                    img {
                        min-width: 300px;
                        min-height: 300px;
                        max-height: 300px;
                        object-fit: contain;
                        transition: all 0.6s ease-in-out;

                        @media (max-width: 767px) {
                            min-width: auto;
                            min-height: auto;
                        }
                    }
                }

                .offer-product-list-icon {
                    a {
                        background: #f2f2f2;
                        border: 1px solid #f2f2f2;
                        border-radius: 50%;
                        display: inline-block;
                        line-height: 2.6;
                        width: 35px;
                        height: 35px;
                        text-align: center;
                    }
                }
            }

            .product-info-wrapper {
                .add-cart-list-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    // justify-content: space-between;
                    text-align: center;
                    position: relative;

                    .load-icon {
                        text-align: center;
                        display: inline-block;
                        height: 38px;
                        width: 38px;
                        border-radius: 50%;
                        line-height: 3.1;
                        min-width: 50px;
                        margin: auto;

                        img {
                            mix-blend-mode: multiply;
                            height: 25px;
                            width: 25px;
                        }
                    }

                    .increment-decrement-qty {
                        align-items: center;
                        border: 1px solid var(--border-color-theme2);
                        border-radius: 20px;
                        margin: auto;
                        display: flex;
                        height: 35px;
                        justify-content: space-between;
                        max-width: 85px;
                        padding: 4px;
                        text-align: center;
                        width: 90px;

                        button {
                            border: 1px solid #f2f2f2;
                            border-radius: 50%;
                            height: 25px;
                            width: 25px;
                            font-size: 14px;
                            color: #fff;
                            background-color: var(--border-color-theme2);

                            &:hover {
                                background-color: #000;
                                color: #fff;
                            }
                        }

                        span {
                            border: none;
                            height: auto;
                            line-height: 37px;
                            width: 38px;
                        }
                    }

                    .add-to-cart-btn {
                        max-width: 237px;
                        margin: auto;
                        background: var(--bg-color-theme2);
                        border: none;
                        text-transform: capitalize;
                        border-radius: 50px;
                        display: inline-block;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 14px 25px;
                        text-align: center;
                        width: 60%;
                        color: #fff;
                        transition: all 0.5s ease-in-out;
                        position: relative;

                        @media (max-width: 767px) {
                            padding: 9px 15px;
                        }

                        .cart-tooltip {
                            position: absolute;
                            bottom: auto;
                            display: inline-block;
                            padding: 3px 6px;
                            color: #000;
                            font-size: 12px;
                            z-index: 1;
                            line-height: normal;
                            left: -23px;
                            white-space: nowrap;
                            right: 0;
                            top: -28px;
                            margin: auto;
                            width: max-content;
                            background-color: #fff;
                            border-radius: 4px;
                            display: none;
                            box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
                        }

                        &:hover .cart-tooltip {
                            display: block;
                        }

                        svg {
                            vertical-align: text-bottom;
                        }

                        span {
                            margin-left: 8px;

                            img {
                                filter: brightness(11);
                                width: 15px;
                                vertical-align: middle;
                            }
                        }

                        &:hover {
                            background-color: #000;
                            color: #fff;

                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }

                    .product-list {
                        .offer-product-list-icon {
                            border: 1px solid #bfbfbf;
                            box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
                            -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
                            border-radius: 50%;
                            display: inline-block;
                            line-height: 2.8;
                            width: 35px;
                            height: 35px;
                            text-align: center;
                            cursor: pointer;
                            transition: all 0.5s ease-in-out;

                            .add-to-list-icon {
                                svg {
                                    transition: all 0.5s ease-in-out;
                                }
                            }

                            &:hover {
                                background-color: var(--bg-color-theme2);

                                .add-to-list-icon {
                                    svg {
                                        path {
                                            fill: #fff;
                                        }
                                    }
                                }
                            }

                            .list-tooltip {
                                position: absolute;
                                bottom: auto;
                                display: inline-block;
                                padding: 3px 6px;
                                color: #000;
                                font-size: 12px;
                                z-index: 1;
                                line-height: normal;
                                left: auto;
                                white-space: nowrap;
                                right: 0;
                                top: -28px;
                                margin: auto;
                                width: max-content;
                                background-color: #fff;
                                border-radius: 4px;
                                display: none;
                                box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
                            }

                            &:hover .list-tooltip {
                                display: block;
                            }
                        }
                    }
                }

                .deal-product-info {
                    text-align: center;
                    margin-bottom: 20px;

                    .product-title {
                        font-family: var(--text-font-family);
                        color: #000;
                        font-size: 19px;
                        font-weight: 600;
                        margin: 0px 0 10px 0;
                    }

                    .product-price-shipping {
                        .price {
                            font-size: 20px;
                            font-weight: 900;
                            color: #000000;
                            display: inline-block;
                            vertical-align: middle;
                            letter-spacing: 0;
                        }

                        .regular-price {
                            margin-left: 7px;
                            font-size: 20px;
                            font-weight: 400;
                            text-decoration: line-through;
                            color: #999;
                            letter-spacing: .3px;
                            vertical-align: middle;
                            display: inline-block;
                        }
                    }
                }
            }

            .deals-offer-text {
                background-color: var(--product-tag-bg-color);
                border-radius: 5px;
                color: #fff;
                font-size: 12px;
                height: 0;
                opacity: 0;
                padding: 7px;
                margin-top: 10px;
                transition: all 0.5s ease-in-out;
            }
        }

        &:hover {
            .deals-offer-text {
                display: block;
                height: 46px;
                opacity: 1;
            }
        }

        &:hover {
            .deal-product-img {
                a {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}