@import "styles/mixins/index";

.listing-sidebar-sticky-coupon-sidebar {
  // position: sticky;
  top: 40px;

  @include breakpoint("max-md") {
    display: none;
  }

  @include breakpoint("max-sm") {
    display: none;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    font-family: var(--heading-font-family), var(--default-font-family);
    color: var(--gray-400);
  }
  .h2-theme2 {
    font-size: 24px;
    font-weight: 500 !important;
    font-family: var(--heading-font-family), var(--default-font-family);
    color: var(--black-1);
  }

  .sidebar-banner {
    padding: map-get($pixel, md) * 4 0 0 0;

    img {
      width: 100%;
      height: 337px;
      object-fit: cover;
    }
  }

  .sidebar-item-list {
    padding: map-get($pixel, sm) * 5 0;

    .ift-item-with-checkbox:last-child {
      padding: 0;
    }

    .spaceDiv {
      background: #fff;
      height: 0.5em;
    }

    .ift-item-with-checkbox {
      @include flex-center;
      position: relative;
      padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;

      // span:after {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   z-index: -1;
      // }

      label {
        @include flex-center;

        span {
          padding-left: 7px;
          cursor: pointer;
        }

        &:click {
          color: #2d8cf0;
        }
      }

      div {
        @include flex-center;

        input[type="checkbox"] {
          width: 15px;
          height: 15px;
          margin: 0;
          padding: 0;
        }
      }
    }

    .ift-item-with-checkbox-theme2 {
      @include flex-center;
      position: relative;
      padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
      font-weight: 600 !important;
      label {
        @include flex-center;

        span {
          padding-left: 7px;
          cursor: pointer;
        }

        &:click {
          color: #2d8cf0;
        }
      }

      div {
        @include flex-center;

        input[type="checkbox"] {
          width: 15px;
          height: 15px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  .banner-image{
    .multi-banner-item{
      img{
        width: auto;
      }
    }
  }
  .sidebar-toggle-item {
    padding-bottom: map-get($pixel, sm) * 2;
    border-bottom: 1px solid var(--border-color-5);

    .range-details {
      padding: map-get($pixel, sm) * 4 0 0 0;

      p,
      span {
        @include text;
        color: var(--gray-400);
      }

      span {
        @include font-semi-bold;
      }
    }

    .shop-by-price {
      padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 2 0;
      border-bottom: 1px solid var(--border-color-5);
      @include flex-between;

      h2 {
        @include heading-7-medium;
        color: var(--gray-400);
      }

      i {
        color: var(--light-icon);
      }
    }

    .range-style {
      padding-top: 30px;

      .rc-slider-handle {
        -webkit-appearance: none;
        appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #04aa6d;
        cursor: pointer;
        border: unset;
      }

      .rc-slider-track {
        background: #04aa6d;
      }

      .rc-slider-rail {
        background: #bfbfbf;
      }
    }
  }
}
.mobile-view {
  display: block;
  padding: 16px;
}
.listing-page-sidebar {
  .child-department-list {
    padding-left: 28px;

    .ift-item-with-checkbox {
      @include flex-center;
      position: relative;
      padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;

      label {
        @include flex-center;
        cursor: pointer;
      }

      span {
        @include text;
        color: var(--gray-400);
        display: block;
        padding-left: map-get($pixel, sm) + 2px;
        cursor: pointer;
      }
    }
  }

  .sidebar-title-alignment {
    @include flex-center;
    padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 2 0;
    justify-content: space-between;
    @include pointer;
    border-bottom: 1px solid var(--border-color-5);

    span {
      @include heading-7-medium;
      color: var(--gray-400);
      display: block;
    }

    i {
      color: var(--light-icon);
      transition: 0.3s ease-in-out;
    }
  }

  .department-item {
    @include flex-center;

    .add-icon {
      @include pointer;

      i {
        color: var(--gray-text-color);
      }
    }

    padding: 10px 15px;
    justify-content: space-between;

    // padding: map-get($pixel, sm) * 2 0 map-get($pixel, sm) * 2 0;
    &:hover {
      @include ease-in-out;
      background-color: var(--primary-light-color);
      color: var(--primary-color);
    }

    .title-alignment {
      @include flex-center;
      position: relative;
      @include pointer;

      &:hover {
        @include ease-in-out;
        background-color: var(--primary-light-color);
        color: var(--primary-color) !important;
      }
    }

    input[type="checkbox"] {
      width: 15px;
      height: 15px;
      margin: 0;
      padding: 0;
    }

    label {
      @include flex-center;
    }

    span {
      @include text;
      color: var(--gray-400);
      display: block;
      // padding-left: map-get($pixel, sm) * 2 + 2px;
      padding: 10px 0 10px map-get($pixel, sm) * 2 + 2px;

      &:hover {
        @include ease-in-out;
        background-color: var(--primary-light-color);
        color: var(--primary-color);
      }
    }
  }

  .active {
    background-color: var(--primary-light-color);
    color: var(--primary-color);
  }

  .other-department-list {
    a {
      display: block;
      padding: 10px 15px;
      @include ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }
  }
}