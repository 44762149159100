@import "styles/mixins/index";
.new-sale-product {
    padding-bottom: 50px;
    @media (max-width: 992px) {
        padding-bottom: 30px;
    }
    .new-sale-all-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        @media (max-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 480px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .product-col-xl-4 {
            float: none;
            width: auto;
        }
    }
    .deals-product {
        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            h2 {
                color: #1a1a1a;
                text-transform: capitalize;
                font-size: 30px;
                font-weight: 500;
                @media (max-width: 1200px) {
                    font-size: calc(20px + (30 - 20) * ((100vw - 280px) / (1200 - 280)));
                }
            }
            a {
                svg {
                    vertical-align: middle;
                    path {
                        fill: var(--fill-svg-color-theme2);
                    }
                }
            }
        }
    }
}