@import "styles/mixins/index";

.recipes-card {
  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  min-height: 260px;
  width: auto;
  max-width: 335px;
  // min-height: 460px;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;
  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }
  &:hover {
    border: 1px solid var(--primary-color);
    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
  .coupon-used-time-ribbon {
    position: absolute;
    right: 0;
    top: 0;
    @include breakpoint("max-sm") {
      top: 4px;
    }
    .coupon-used-time {
      background-color: var(--secondary-color);
      width: fit-content;
      padding: 17px 6px 16px 7px;
      margin: 2px 2px 15px -18px;
      position: relative;
      @include breakpoint("max-sm") {
        margin: 0 0 10px -2px;
      }
      p {
        color: var(--bg-white);
        @include heading-text-sm-normal;
        @include breakpoint("max-md") {
          font-size: 12px;
          line-height: 16px;
        }
        span {
          @include font-medium;
        }
      }
      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        bottom: -8px;
        right: 8px;
        border-width: 14px 12px 14px 0;
        transform: rotate(90deg);
        position: absolute;
        content: "";

        border-color: transparent var(--bg-white) transparent transparent;
      }
    }
  }

  .recipes-card-image {
    max-height: 137px;

    img {
      // max-width: 137px;
      width: 100%;
      max-height: 137px;
      object-fit: cover;
      border-radius: 8px;
      
      @include breakpoint("max-sm") {
        // min-width: 92px;
        // max-width: 92px;
        // max-height: 116px;
        // min-height: 116px;
        width: 100%;
      }
    }
  }
  .recipes-info {
    padding-top: 10px;
    @include breakpoint("max-sm") {
      padding-top: 20px;
    }
  }
  .recipes-card-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: map-get($pixel, md) * 1 + 4px 0 0 0;
    @include breakpoint("max-sm") {
      padding: 0;
    }

    a {
      @include heading-4;
      color: var(--black-text-color);
      display: block;
      line-height: 22px;
      // min-height: 44px;
      font-weight: bold;
      @include ease-in-out;
      margin: 0 0 map-get($pixel, sm) + 3px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        color: var(--secondary-color);
      }
    }
    .recipes-info {
      width: 100%;
      padding-right: 8px;
    }
    h3 {
      @include text-sm;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      display: block;
      color: var(--color);
    }
    .description {
      color: var(--gray-7);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      .html-parser {
        text-align: left;
      }
    }
    .other-description {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      p {
        @include text-sm;
        @include font-medium;
        color: var(--black-text-color);
        span {
          color: var(--gray-7);
          @include font-semi-bold;
        }
      }
    }
  }
  .button-group-alignment {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    .col {
      color: var(--gray-7);
      max-width: 100px;
      align-items: center;
      display: flex;
    }
    .add-to-cart {
      button {
        padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
        background-color: var(--primary-light-color);
        color: var(--primary-color);
        @include text-xs-semi-bold;
        border: none;
        @include normal-radius;
        @include flex-center;
        @include pointer;
        @include ease-in-out;
        &:hover:not([disabled]) {
          background-color: var(--primary-color);
          color: var(--bg-white);
          svg {
            stroke: var(--bg-white);
            g {
              path {
                fill: var(--bg-white);
              }
            }
          }
        }
        span {
          padding-left: 4px;
        }
        svg {
          stroke: var(--primary-color);
          @include ease-in-out;
          g {
            path {
              fill: var(--primary-color);
            }
          }
        }
        &:disabled {
          cursor: default;
        }
      }
    }
  }
}
