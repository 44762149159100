@import "styles/mixins/index";
.deal-of-week-coupon {
  padding-bottom: 50px;
  @media (max-width: 992px) {
    padding-bottom: 30px;
  }
  .deals-coupon {
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      h2 {
        color: #1a1a1a;
        font-size: 30px;
        text-transform: capitalize;
        font-weight: 500;
        @media (max-width: 1200px) {
          font-size: calc(20px + (30 - 20) * ((100vw - 280px) / (1200 - 280)));
        }
      }
      a {
        svg {
          vertical-align: middle;
          path {
            fill: var(--fill-svg-color-theme2);
          }
        }
      }
    }
  }
  .product-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .deals-coupon-card-theme2 {
    border: 1px solid var(--border-color-4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 15px;
    min-height: 330px;
    height: 660px;
    overflow: hidden;
    outline: 3px solid transparent;
    outline-offset: -3px;
    transition: all 0.6s ease-in-out;
    @media (max-width: 767px) {
      height: auto;
    }
    @include normal-radius;
    // @include pointer;
    @include ease-in-out;
    position: relative;
    @include breakpoint("max-sm") {
      margin-bottom: 20px;
    }
    &:hover {
      border-color: var(--border-color-theme2);
      outline-color: var(--border-color-theme2);
    }
    .deals-coupon-card-ribbons {
      display: flex;
      align-items: center;
      position: absolute;
      top: 10px;
      left: 10px;
      right: auto;
      bottom: auto;
      z-index: 11;
    }
    .deals-coupon-used-time-ribbon {
      display: inline-block;
      background-color: #ddd;
      height: 100%;
      border: none;
      font-size: 13px;
      line-height: 1.1;
      margin: 0 10px;
      text-align: center;
      border-radius: 4px;
      padding: 10px;
      font-weight: 500;
      color: #000;
      .deals-coupon-used-time {
        background-color: #ddd;
        border: none;
        font-size: 12px;
        line-height: 35px;
        margin: 0 10px;
        text-align: center;
        border-radius: 4px;
        padding: 10px;
        p {
          color: var(--bg-white);
          @include heading-text-sm-normal;
          @include breakpoint("max-md") {
            font-size: 12px;
            line-height: 16px;
          }
          span {
            @include font-medium;
            color: #000;
          }
        }
      }
    }
    .deals-coupon-design-ribbon {
      .deals-coupon-design {
        background-color: var(--secondary-color);
        width: -moz-fit-content;
        width: fit-content;
        margin: 2px 0 0px 2px;
        position: relative;
        color: #fff;
        border-radius: 4px;
        padding: 10px;
        p {
          color: var(--bg-white);
          @include heading-text-sm-normal;
          @include breakpoint("max-md") {
            font-size: 12px;
            line-height: 16px;
          }
          span {
            @include font-medium;
          }
        }
      }
    }
    .triangle-up-right {
      width: 0;
      height: 0;
      border-top: 50px solid var(--secondary-color);
      border-left: 50px solid transparent;
      position: absolute;
      right: 0;
      top: 0;
      .deals-coupon-limit-align {
        position: relative;
        p {
          position: absolute;
          top: -43px;
          right: 6px;
          font-size: 14px;
          line-height: 16px;
          z-index: 10;
        }
      }
    }
    .inside-triangle {
      border-top: 46px solid #fff;
      border-left: 46px solid transparent;
      right: 1px;
      top: 1px;
    }
    @include breakpoint("max-sm") {
      min-height: unset;
      border: none;
      border: 1px solid var(--border-color-4);
      min-height: unset;
    }
    &:hover {
      border: 1px solid var(--primary-color);
      @include breakpoint("max-sm") {
        // display: none;
        border-bottom: 1px solid var(--primary-color);
      }
    }
    .product-card-image {
      margin: 45px 0 0;
      @include flex-center;
      overflow: hidden;
      justify-content: map-get($layout, center);
      @include breakpoint("max-sm") {
        padding: 16px;
      }
      img {
        min-width: 300px;
        min-height: 300px;
        object-fit: cover;
        transition: all 0.6s ease-in-out;
        @include breakpoint("max-sm") {
          min-width: 92px;
          max-width: 92px;
          max-height: 116px;
          min-height: 116px;
        }
      }
    }
    .product-card-details {
      font-family: var(--text-font-family);
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 5px;
      padding: map-get($pixel, md) * 1 + 4px 0 0 0;
      @include breakpoint("max-sm") {
        padding: 0;
      }
      .deals-coupon-details {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 13px;
      }
      .regular-price {
        padding: 0 0 map-get($pixel, md) - 2px 0;
        p {
          @include text-sm;
          @include font-medium;
          color: var(--black-text-color);
          span {
            color: var(--gray-7);
            @include font-semi-bold;
          }
        }
      }
      a {
        @include heading-4;
        color: var(--black-text-color);
        display: block;
        line-height: 22px;
        @include ease-in-out;
        margin: 0 0 map-get($pixel, sm) + 3px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &:hover {
          color: var(--secondary-color);
        }
      }
      .button-group-alignment {
        @include flex-between;
        justify-content: flex-end;
        padding-top: 10px;
        .add-to-cart{
          .load-icon {
            text-align: center;
            display: inline-block;
            height: 38px;
            width: 38px;
            border-radius: 50%;
            line-height: 3.1;
            img {
              mix-blend-mode: multiply;
              height: 25px;
              width: 25px;
            }
          }
        }
      }
      .final-price {
        padding: 0 0 map-get($pixel, md) - 2px 0;
        p {
          @include text-sm-normal;
          color: var(--black-text-color);
          font-size: 16px;
          @include font-medium;
          span {
            color: var(--primary-color);
            @include font-semi-bold;
          }
        }
      }
      .add-to-cart {
        button {
          border: 1px solid #bfbfbf;
          background-color: var(--bg-color-theme2);
          border-radius: 50%;
          cursor: pointer;
          display: inline-block;
          height: 38px;
          width: 38px;
          text-align: center;
          line-height: 2.8;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
          transition: all 0.5s ease-in-out;
          svg {
            transition: all .5s ease-in-out;
          }
          &:hover:not([disabled]) {
            background-color: var(--bg-color-theme2);
            color: var(--bg-white);
            svg {
              stroke: var(--bg-white);
              transform: rotate(360deg);
              g {
                display: inline-block;
                path {
                  fill: var(--bg-white);
                }
              }
            }
          }
          span {
            padding-left: 4px;
          }
          svg {
            @include ease-in-out;
            g {
              path {
                fill: #fff;
              }
            }
            circle,
            path{
              fill: #fff;
            }
          }
          &:disabled {
            cursor: default;
          }
        }
      }
      .button-disable {
        pointer-events: none;
        opacity: 0.5;
      }
      .button-group {
        border: 1px solid var(--border-color-5);
        @include normal-radius;
        @include flex-center;
        @include pointer;
        width: fit-content;
        .action-button,
        .counter {
          width: 24px;
          height: 23px;
          @include flex-center;
          justify-content: map-get($layout, center);
        }
        .action-button {
          color: var(--gray-text-color);
          @include ease-in-out;
          &:hover {
            background-color: var(--primary-color);
            color: var(--bg-white);
          }
          i {
            font-size: 12px;
          }
        }
        .action-button-plus {
          width: 29px;
          height: 23px;
          @include flex-center;
          color: var(--gray-text-color);
          justify-content: map-get($layout, center);
          @include ease-in-out;
          &:hover {
            background-color: var(--primary-color);
            color: var(--bg-white);
          }
          i {
            font-size: 12px;
          }
        }
        .counter {
          background-color: var(--border-color);
          @include heading-text-sm;
          color: var(--black-text-color);
        }
      }
      .price {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 + 8px 0;
        h3 {
          @include text-bold;
          color: var(--primary-color);
          padding-right: 21px;
        }
        del {
          @include text-sm-semi-bold;
          font-size: 13px;
          color: var(--gray-8);
        }
      }
      .star-text-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, sm) + 3px 0;
        .rating-text {
          span {
            @include text-xs-normal;
            color: var(--gray-7);
          }
        }
        .star-alignment {
          @include flex-center;
          padding-right: 10px;
          i:last-child {
            margin-right: 0px;
          }
          i {
            color: var(--secondary-color);
            margin-right: 3px;
            @include pointer;
            font-size: 12px;
          }
        }
      }
      h3 {
        @include text-sm;
        padding: 0 0 map-get($pixel, sm) + 2px 0;
        display: block;
        color: var(--color);
      }
      .product-card-main-detail {
        width: 70%;
      }
    }
    &:hover {
      .product-card-image {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
  .coupon-product-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .coupon-deal-all-wrapper {
    .coupon-card-theme2 {
      max-width: 100%;
      min-width: auto;
    }
    &.coupon-product-row {
      margin: 0;
    }
    .coupon-card-theme2 {
      float: left;
      width: 33.33333%;
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
  .coupon-product-col-xl-4 {
    float: left;
    width: 33.33333%;
  }
  .coupon-product-col-xl-6 {
    float: left;
    width: 50%;
  }
  .coupon-product-col-xl-12 {
    float: left;
    width: 100%;
  }
  .coupon-product-col-sm-4 {
    float: left;
    width: 33.33333%
  }
  .coupon-product-col-sm-8 {
    float: left;
    width: 66.66667%
  }
  @media (max-width: 992px) {
    .coupon-product-col-xl-4 {
      width: 50%;
    }
    .coupon-product-col-sm-4 {
      width: 40%;
    }
    .coupon-product-col-sm-8 {
      width: 60%;
    }
    .coupon-product-row {
      margin: 0;
    }
  }
  @media (max-width: 767px) {
    .coupon-product-col-xl-4 {
      width: 50%;
    }
    .coupon-product-col-sm-4 {
      width: 100%;
      margin-bottom: 15px;
    }
    .coupon-product-col-sm-8 {
      width: 100%;
    }
  }
  @media (max-width: 480px) {
    .coupon-product-col-xl-4 {
      width: 100%;
    }
  }
}