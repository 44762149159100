@import "styles/mixins/index";

.recipe-product-preview-slider {
  .selected-product {
    border: 1px solid var(--border-color-10);
    border-radius: map-get($pixel, sm) * 3;
    padding: 50px;
    margin: 0 0 map-get($pixel, md) * 3 0;
    // height: 680px;
    text-align: center;

    @include breakpoint("max-md") {
      padding: 40px;
    }
    @include breakpoint("max-sm") {
      height: 380px;
    }

    img {
      width: 100%;
      max-height: 489px;
      // height: 489px;
      object-position: top;
       object-fit: contain;

      @include breakpoint("max-md") {
        // height: 289px;
      }

      @include breakpoint("max-sm") {
        // height: 289px;
      }
    }
  }
}
