.breadcrumb {
  padding: map-get($pixel, md) + 5px 0;
  border-bottom: 1px solid var(--gray-border-color);
  min-height: 53px;
  @media (max-width: 650px) {
    display: none;
  }
  ul {
    @include flex-center;
    list-style: none;

    li:last-child {
      &:hover {
        text-decoration: none;
      }
    }

    li {
      text-decoration: none;
      @include text;
      color: var(--black-200);
      padding-right: map-get($pixel, sm) * 2;
      @include pointer;
      @include breakpoint("max-sm") {
        @include text-xs-medium;
        @include font-normal;
      }

      &:hover {
        text-decoration: underline;
      }
      i {
        font-size: 13px;
        color: var(--black-200);
        @include breakpoint("max-sm") {
          font-size: 10px;
        }
      }
    }
  }
}
