@import "styles/mixins/index";

.home-add-section-alignment {
  padding: map-get($pixel, md) * 7 0;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 5 0;
  }

  .add-grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 map-get($pixel, md) * 5;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: map-get($pixel, md) * 5;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: map-get($pixel, md) * 2;
    }

    // .add-grid-items:last-child {
    //   @include breakpoint("max-sm") {
    //     display: none;
    //   }
    // }

    .add-grid-items {
      min-height: 400px;
      @include flex-center;
      background-color: var(--bg-light-200);
      justify-content: center;
      img {
        width: 100%;
        min-height: 400px;
      }

      @include breakpoint("max-sm") {
        height: 200px;
      }

      h1 {
        @include heading-2;
        padding: 0 map-get($pixel, md) * 8;
        color: var(--gray-9);
        @include text-center;

        @include breakpoint("max-md") {
          padding: 0;
        }

        @include breakpoint("max-sm") {
          @include heading-text-md-medium;
          font-size: 19px;
          padding: 0;
        }

        span {
          @include font-normal;
        }
      }
    }
  }
}
