@import "styles/mixins/index";
.featured-product-theme2 {
    padding-bottom: 40px;
    @media (max-width: 992px) {
        padding-bottom: 20px;
    }
    .deal-all-wrapper {
        &.product-row {
            margin: 0 -7.5px;
        }
    }
    .features-all-wrapper {
        // display: grid;
        // grid-template-columns: repeat(5, 1fr);
        // @media (max-width: 1200px) {
        //     grid-template-columns: repeat(4, 1fr);
        // }
        // @media (max-width: 1024px) {
        //     grid-template-columns: repeat(3, 1fr);
        // }
        // @media (max-width: 768px) {
        //     grid-template-columns: repeat(2, 1fr);
        // }
        // @media (max-width: 480px) {
        //     grid-template-columns: repeat(1, 1fr);
        // }
        .product-col-xl-4 {
            // float: none;
            // width: auto;
            margin: 0 7.5px;
        }
    }
    .deals-product {
        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            h2 {
                color: #1a1a1a;
                text-transform: capitalize;
                font-size: 30px;
                font-weight: 500;
                @media (max-width: 1200px) {
                    font-size: calc(20px + (30 - 20) * ((100vw - 280px) / (1200 - 280)));
                }
            }
            a {
                svg {
                    vertical-align: middle;.bestseller-slider
                    path {
                        fill: var(--fill-svg-color-theme2);
                    }
                }
            }
        }
    }
    .slick-list {
        margin: 0;
    }
    .left-arrow-alignment {
        position: absolute;
        right: 42px;
        top: -56px;
        @include breakpoint("max-sm") {
            top: -45px;
        }
    }
    .right-arrow-alignment {
        position: absolute;
        right: 0;
        top: -56px;
        @include breakpoint("max-sm") {
            top: -45px;
        }
    }
    .custom-arrow-design {
        width: 32px;
        border-radius: 50%;
        background-color: var(--bg-light-400);
        @include pointer;
        height: 32px;
        @include flex-center;
        justify-content: map-get($layout, center);
        @include ease-in-out;
        color: var(--gray-text-color);
        &:hover {
            background-color: var(--primary-color);
            color: var(--bg-white);
        }
    }
}