@import "styles/mixins/index";

.related-product-section {
    padding: 0 70px map-get($pixel, md) * 10 70px;

    @include breakpoint("max-md") {
        padding: 0 0 100px 0;
    }

    h1 {
        @include heading-2;
        color: var(--black-text-color);
        margin: 0;
    }
}