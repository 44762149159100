@import "styles/mixins/index";

.single-address-section-theme2 {
  border-radius: calc(10px - 2px);
  border: 1px solid var(--border-color-8);
  padding: map-get($pixel, md) * 3 map-get($pixel, md) * 3 - 3px;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, sm) * 3;
  }

  .single-address-submit-btn {

    button {
      padding: map-get($pixel, sm) * 2 map-get($pixel, md) * 3;
      background-color: var(--primary-color);
      color: var(--bg-white);
      border-radius: calc(5px - 0px);
      border: none;
      @include heading-4;
      margin: 12px;
    }

    div:first-child {
      // margin-right: 15px;

      button {
        background-color: var(--bg-light-200);
        color: #989898;
      }
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: default;
    }
  }

  h1 {
    @include heading-4xl;
    color: var(--black-5);
    margin: 0 0 map-get($pixel, md) * 3 + 2px 0;

    @include breakpoint("max-sm") {
      margin: 0 0 map-get($pixel, md) * 2 0;
    }
  }

  .form-control {
    margin: 0 0 map-get($pixel, md) * 2 0;

    span {
      @include heading-text-xs;
      display: block;
      padding: map-get($pixel, sm) 0 0 0;
      color: var(--bg-danger);
    }
  }

  .single-addres-sec-line {
    margin: map-get($pixel, md) * 2 0 0 0;
  }

  .single-input-name-grid {
    @include grid-layout;
    grid-template-columns: repeat(12, 1fr);
    gap: map-get($pixel, md) * 2;

    @include breakpoint("max-sm") {
      gap: 0;
    }

    .form-control {
      @include breakpoint("max-md") {
        grid-column: span 12;
      }
    }

    div {
      grid-column: span 6;
    }
  }
}