@import "styles/mixins/index";

.col-text {
    height: 30rem;
    font-family: var(--heading-font-family), var(--default-font-family);
}

.grid-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: space-around;
    padding: 20px 3px;
}

.col {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.col-left {
    -webkit-box-ordinal-group: -1;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}

.col-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Aligner-item {
    width: 80%;

    h1 {
        padding: 16px;
        color: var(--gray-3);

    }

    p {
        line-height: 34px;
        font-size: 18px;
        text-align: justify;
        color: var(--gray-3);

    }
}

.col-image {
    background-size: cover;
    background-position: center center;
}

.store-video {
    height: 580px;
    width: 100%;

    &__container {
        padding: 15px 100px;
        height: 92%;
        width: 70%;
        margin: 0 auto;

        img {
            position: relative;
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            width: 100%
        }
    }



}

@media (max-width: 640px) {
    .grid-flex {
        height: 40em;
        display: -webkit-flex;
        -webkit-flex-direction: column;
        flex-direction: column;
    }

    .col {
        order: vertical;
    }

    .col-left {
        -webkit-box-ordinal-group: 0;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
    }

    .col-text div p {
        padding: 1em;
    }

    .Aligner-item {
        width: 90%;
    }
}