.coupon-banner-theme2{
    padding-bottom: 30px;
    @media (max-width: 992px) {
      padding-bottom: 10px;
    }
    .coupon-img-wrap{
      .coupon-img{
        img{
            border: 1px solid var(--border-color-4);
          max-width: 100%;
          height: auto;
          border-radius: 10px;
        }
      }
    }
  }