@mixin pointer {
  cursor: map-get($layout, pointer);
}

@mixin text-center {
  text-align: map-get($layout, center);
}

// grid-layout//
@mixin grid-layout {
  display: map-get($layout, grid);
}

// border-radius//
@mixin normal-radius {
  border-radius: map-get($pixel, sm) - 1px;
}

// shadow

@mixin white-shaow {
  box-shadow: (0px 0px 10px rgba(135, 135, 135, 0.16));
}
