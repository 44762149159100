.listing-page-wrapper-special-coupon-items-theme2 {
    h2 {
        margin-bottom: 20px;
        @media (max-width: 992px) {
            font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (992 - 280)));
            margin-bottom: 10px;
        }
    }
    .coupon-list-item {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 15px;
        @media (max-width: 1280px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 992px) {
            gap: 15px;
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 450px) {
            grid-template-columns: repeat(2, 1fr);
        }
        .coupon-card-theme2 {
            max-width: 100%;
            @media (max-width: 575px) {
                max-width: 100%;
                min-width: auto;
                width: 100%;
            }
        }
    }
}