@import "styles/mixins/index";

.provide-section {
  padding: map-get($pixel, md) * 10 0 8px 0;
  @include breakpoint("max-sm") {
    padding: 60px 0 0 0;
  }
  .provide-section-title {
    padding: 0 0 map-get($pixel, md) * 6 0;
    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 4 0;
    }
    h1 {
      @include heading-9-medium;
      color: var(--invert-secondary-color);
      margin: 0;
      @include text-center;
      @include breakpoint("max-sm") {
        @include heading-2;
      }
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: map-get($pixel, md) * 4;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .grid-items {
      border: 1px solid var(--border-color-9);
      background-color: var(--bg-white);
      border-radius: 7px;
      padding: 50px 50px 90px 50px;
      @include breakpoint("max-md") {
        padding: 30px;
      }
      h2 {
        @include heading-3xl;
        color: var(--invert-secondary-color);
        @include text-center;
        margin: 0 0 map-get($pixel, md) + 5px 0;
        @include breakpoint("max-md") {
          @include heading-3;
        }
      }
      p {
        @include text;
        font-size: 17px;
        line-height: 25px;
        @include text-center;
        color: var(--dark-gray-300);
        @include breakpoint("max-md") {
          @include text-sm;
          line-height: 22px;
        }
      }
      .icon-center-align {
        @include flex-center;
        justify-content: map-get($layout, center);
        padding: 0 0 map-get($pixel, md) * 3 0;
        img {
          height: auto;
          max-height: 75%;
          max-width: 100%;
          object-fit: cover;
          width: 40%;
          /* width: 80%;
          height: 130px;
          object-fit: fill; */
          @media screen and (max-width: 992px) {
            max-width: 97px;
            height: 70px; 
             }
        }
      }
    }
  }
}
