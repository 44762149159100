@import "styles/mixins/index";

.career-page-wrapper {
  padding: 0 40px;
  @include grid-layout;
  grid-template-columns: max(1000px) minmax(0, 1fr);
  gap: 0 map-get($pixel, md) * 5 + map-get($pixel, sm) + 3px;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 3 0;
  }

  @include breakpoint("max-md") {
    display: block;
    padding: map-get($pixel, md) * 3 0;
  }

  @include breakpoint("max-sm") {
    display: block;
  }

  .sidebar {
    position: relative;
    width: 100%;
    min-height: 1px;
    font-family: var(--default-font-family);
    border: 1px solid var(--primary-light-color);

    .single-slidebar {
      padding: 30px 20px;
      margin-bottom: 30px;
    }

    h4 {
      background-color: #f9f9ff;
      padding: 12px 10px;

      margin-bottom: 20px;
      font-weight: 500;
      font-size: 26px;
    }

    li {
      padding: 0px 10px;
      transition: all 0.3s ease 0s;
      background-color: #f9f9ff;
      display: list-item;
    }

    a {
      padding: 10px;
      margin-bottom: 10px;
      color: var(--gray-400);
      cursor: default;
    }

    .justify-content-between {
      justify-content: space-between;
    }

    .d-flex {
      display: flex;
    }

  }


  .found-items {
    padding: 15px;

    p {
      span {
        color: var(--secondary-color);
        margin-bottom: 12px;
      }
    }
  }
}