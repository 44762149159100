@import "styles/mixins/index";

.add-item-button {
  position: relative;

  @include breakpoint("max-sm") {
    padding: 0;
    display: flex;

    // justify-content: flex-end;
  }

  button {
    padding: 8px 13px;
    background-color: transparent;
    color: var(--primary-color);
    @include flex-center;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    span {
      padding-right: 9px;
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .add-item-hidden {
    max-height: 0px;
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }

  .add-item-show {
    max-height: calc(100vh - 20px);
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .add-item-dropdown {
    width: 221px;
    border-radius: 6px;
    position: absolute;
    top: 41px;
    z-index: 99999;

    .add-item-dropdown-design {
      background-color: var(--bg-white);
      border: 1px solid var(--primary-color);
      filter: drop-shadow(0px 0px 20px rgba(116, 116, 116, 0.16));
      height: 100%;
      border-radius: 6px;
      user-select: none;

      .dropdown-body {
        padding: 13px 18px;

        .apply-text-alignment {
          p {
            @include text-sm-semi-bold;
            color: var(--primary-color);
            @include pointer;
            text-align: right;
          }
        }
      }
      .form-control {
        margin: 0 0 9px 0;

        label {
          font-size: 15px;
          color: #000;
          line-height: 16px;
        }

        input {
          height: 24px;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
    .quantity-button {
      margin: 10px 0px;
      button {
        margin: 10px 0px;
        min-width: 90px;
        height: 43px;
        border: 1px solid var(--primary-color);
        background-color: transparent;
        @include heading-xl-bold;
        color: var(--primary-color);
        border-radius: 7px;
        position: relative;
        line-height: 20px;

        span {
          position: absolute;
          top: 50%;
          display: block;
          @include flex-center;
          transform: translateY(-50%);
          left: 28px;
        }

        .arrow-alignment {
          position: absolute;
          right: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          div {
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
