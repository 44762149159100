@import "styles/mixins/index";
.cart-items-table {
  .cart-footer {
    @include flex-between;
    padding: map-get($pixel, md) * 2 + 2px 0 0 0;
    button {
      padding: 11px 12px;
      border: none;
      border-radius: 7px;
      border: none;
      @include flex-center;
      @include heading-text-sm;
    }
    .continue-shopping {
      button {
        background-color: var(--primary-color);
        color: var(--bg-white);
        span {
          padding-left: 5px;
        }
      }
    }
    .update-cart {
      button {
        background-color: var(--secondary-color);
        color: var(--bg-white);
        span {
          padding-left: 5px;
        }
      }
    }
  }
  .cart-items-body {
    border: 1px solid var(--border-color-8);
    border-radius: 0 0 7px 7px;
    @include breakpoint("max-md") {
      border: none;
    }
    .cart-items-list-view-section {
      padding: 36px 58px 45px 58px;
      border-bottom: 1px solid var(--border-color-8);
      @include breakpoint("max-md") {
        padding: 10px 0;
      }
      .allow-substitition-grid {
        .grid-items {
          display: flex;
          @media (max-width: 575px) {
            display: block;
          }
        }
        @include grid-layout;
        padding: map-get($pixel, md) * 2 - 2px 0 0 0;
        grid-template-columns: 390px minmax(0, 1fr);
        // grid-template-columns: repeat(auto-fit, minmax(auto-fit, 1fr));
        //     grid-auto-flow: column;
        // grid-auto-columns: auto;
        width: auto;
        align-items: end;
        gap: 0 map-get($pixel, md) * 6;
        @media (max-width: 575px) {
          display: block;
        }
        .allow-substitition-grid-items {
          .show-items {
            position: relative;
            @include breakpoint("max-sm") {
              width: 100%;
            }
            @include breakpoint("min-md") {
              width: unset;
            }
            button {
              font-size: 15px;
              line-height: 19px;
              font-family: var(--heading-font-family),
                var(--default-font-family);
              font-weight: 500;
              padding: 12px 17px;
              border: 1px solid var(--light-border-color);
              color: var(--black-5-light);
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              background-color: transparent;
              font-weight: 400;
              width: 164px;
              position: relative;
              transition: 0.2s ease-in-out;
              @include breakpoint("max-sm") {
                width: 100%;
              }
              &:focus {
                border: 1px solid var(--primary-color);
              }
              span {
                padding: 0 map-get($pixel, sm) + 1px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
            .submenu-list {
              .disable-lable {
                font-size: 14px;
                line-height: 16px;
                font-family: var(--heading-font-family),
                  var(--default-font-family);
                font-weight: 400;
                color: var(--black-3);
                padding: 11px 15px;
                opacity: 0.6;
              }
              .submenu-item {
                padding: 11px 30px;
              }
            }
            .dropdown-show {
              max-height: 300px;
              z-index: 999999;
              overflow-y: auto;
              border: 1px solid var(--light-border-color);
              .list-menu {
                border: none;
              }
            }
            .dropdown-hidden {
              z-index: 999999;
            }
          }
          .textarea-style {
            @media (max-width: 575px) {
              margin-top: 15px;
            }
            textarea {
              width: 100%;
              height: 67px;
              border: 1px solid var(--border-color-2);
              padding: 16px;
              border-radius: 5px;
              @include heading-text-sm;
              @include font-normal;
              color: var(--gray-7);
              vertical-align: bottom;
            }
            ::placeholder {
              @include heading-text-sm;
              color: var(--gray-7);
            }
          }
          .relative-div {
            position: relative;
            .select-option-align {
              position: absolute;
              width: 100%;
              z-index: 99999;
            }
          }
          span {
            @include heading-4;
            display: block;
            padding: 0 0 map-get($pixel, sm) * 2 0;
            color: var(--gray-5-light);
          }
          .right-icon-align {
            @include flex-center;
          }
          .input {
            input {
              border: 1px solid var(--border-color-2);
              padding: 0 17px;
            }
          }
        }
      }
      .catering-allow-substitition-grid {
        @include grid-layout;
        padding: map-get($pixel, md) * 2 - 2px 0 0 0;
        // grid-template-columns: auto auto auto;
        // align-items: flex-end;
        gap: 0 map-get($pixel, md) * 6;
        row-gap: 14px;
          grid-template-columns: repeat(3, 1fr);
          @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 0 15px;
          }
          @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 0 15px;
          }
          @media screen and (max-width: 350px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 0 15px;
          }
        // @include breakpoint("max-md") {
        //   display: none;
        // }
        .substitution {
          p {
            margin-bottom: 10px;
            span {
              color: var(--bg-danger);
            }
          }
        }
        .select-substitition-dropdown {
          .dropdown-show {
            max-height: 300px;
            z-index: 999999;
            overflow-y: auto;
            transition: none;
            border: 1px solid var(--light-border-color);
            .list-menu {
              border: none;
            }
          }
          .dropdown-hidden {
            z-index: 999999;
            transition: none;
          }
        }
      }
      .catering-textarea-style {
        margin-top: 12px;
        textarea {
          width: 100%;
          height: 67px;
          border: 1px solid var(--border-color-2);
          padding: 16px;
          border-radius: 5px;
          @include heading-text-sm;
          @include font-normal;
          color: var(--gray-7);
        }
        ::placeholder {
          @include heading-text-sm;
          color: var(--gray-7);
        }
      }
      .sub-grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 372px;
        gap: 0 20px;
        @include breakpoint("max-md") {
          grid-template-columns: repeat(1, 1fr);
        }
        .sub-grid-items {
          .product-view-grid {
            @include grid-layout;
            grid-template-columns: 98px minmax(0, 1fr);
            gap: 0 10px;
            @include breakpoint("max-md") {
              grid-template-columns: 190px minmax(0, 1fr);
              align-items: center;
            }
            @include breakpoint("max-sm") {
              grid-template-columns: 140px minmax(0, 1fr);
            }
            @media (max-width: 575px) {
              grid-template-columns: 120px minmax(0, 1fr);
            }
            .product-view-grid-items {
              img {
                width: 40px;
                height: 40px;
                @include breakpoint("max-sm") {
                  width: 20px;
                  height: 20px;
                }
              }
              .title-icon-mobile-show {
                display: none;
                @include breakpoint("max-md") {
                  display: block;
                  text-align: right;
                }
              }
              .mobile-view-title-icon-align {
                display: block;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 5px 0;
                i {
                  color: var(--gray-text-color);
                  @include pointer;
                }
                .remove-icon{
                  text-align: right;
                }
              }
              .mobile-view-show {
                display: none;
                @include breakpoint("max-md") {
                  // display: block;
                  display: flex;
                  gap: 5px;
                  flex-wrap: wrap;
                  justify-content: space-between;
                }
              }
              .mobiel-quantity-button {
                // padding: 10px 0 0 0;
                display: flex;
                    align-items: start;
                    flex-direction: column;
                button {
                  min-width: 90px;
                  height: 43px;
                  border: 1px solid var(--primary-color);
                  background-color: transparent;
                  @include heading-xl-bold;
                  color: var(--primary-color);
                  border-radius: 7px;
                  position: relative;
                  line-height: 20px;
                  span {
                    position: absolute;
                    top: 50%;
                    display: block;
                    @include flex-center;
                    transform: translateY(-50%);
                    left: 28px;
                    @include font-bold;
                    color: var(--primary-color);
                    @include text-md-semi-bold;
                  }
                  .arrow-alignment {
                    position: absolute;
                    right: 14px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    div {
                      i {
                        font-size: 14px;
                      }
                    }
                  }
                  &.disabled {
                    cursor: default;
                    pointer-events: none;
                    opacity: 0.6;
                  }
                }
              }
              .update-button {
                button {
                  background-color: var(--primary-color);
                  color: var(--bg-white);
                  padding: 11px 12px;
                  border: none;
                  border-radius: 7px;
                  border: none;
                  display: flex;
                  align-items: center;
                  font-size: 15px;
                  line-height: 19px;
                  font-family: var(--heading-font-family), var(--default-font-family);
                  font-weight: 500;
                }
                &.disabled {
                  opacity: 0.6;
                  pointer-events: none
                }
              }
              .duplicate-item {
                margin-top: 4px;
                color: var(--primary-color);
                cursor: pointer;
              }
              font {
                @include heading-xl-bold;
                color: var(--primary-color);
                @include font-medium;
                display: none;
                @include breakpoint("max-md") {
                  display: block;
                  font-size: 18px;
                }
                @media (max-width: 575px) {
                  margin-bottom: 5px;
                 }
              }
              h5 {
                @include text-sm;
                color: var(--invert-secondary-color);
                margin: 0 0 5px 0;
                display: none;
                @include breakpoint("max-md") {
                  display: block;
                }
                a {
                  color: var(--gray-7-light);
                }
              }
              .product-sku {
                display: flex;
                font-size: 14px;
                @media (max-width: 575px) {
                 line-height: 1.5;
                 margin-bottom: 8px;
                }
                span {
                  display: block;
                  line-height: 20px;
                  color: var(--primary-color);
                  @media (max-width: 575px) {
                    line-height: 1.5;
                   }
                }
              }
              span {
                @include text-sm;
                color: var(--gray-7-light);
                display: none;
                @include breakpoint("max-md") {
                  display: block;
                }
              }
              .rating-alignment {
                @include flex-center;
                @include breakpoint("max-md") {
                  display: none;
                }
                .count {
                  span {
                    @include text;
                    color: var(--gray-7);
                    display: block;
                    padding: 0;
                  }
                }
                .star {
                  padding-right: 10px;
                  i {
                    color: #f9c945;
                    margin-right: 5px;
                  }
                }
              }
              h6 {
                @include heading-text-md-medium;
                color: var(--black-text-color);
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                &:hover {
                  color: var(--secondary-color);
                  cursor: pointer;
                }
              }
              .product-image-show {
                width: 100%;
                height: 92px;
                border-radius: 10px;
                @include flex-center;
                justify-content: map-get($layout, center);
                border: 1px solid #dfdfdf;
                @include breakpoint("max-md") {
                  height: 180px;
                }
                @include breakpoint("max-sm") {
                  height: 155px;
                }
                @media (max-width: 575px) {
                  height: 127px;
                }
                img {
                  max-width: 52px;
                  max-height: 66px;
                  object-fit: cover;
                  object-position: top;
                  @include breakpoint("max-md") {
                    max-width: 100px;
                    min-width: 100px;
                    height: 120px;
                    max-height: 120px;
                    object-fit: fill;
                  }
                  @include breakpoint("max-sm") {
                    max-width: 80px;
                    min-width: 80px;
                    height: 110px;
                    max-height: 110px;
                  }
                }
              }
              .product-name {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 map-get($pixel, sm) * 2 0;
                .update-button {
                  button {
                    background-color: var(--primary-color);
                    color: var(--bg-white);
                    padding: 11px 12px;
                    border: none;
                    border-radius: 7px;
                    border: none;
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    line-height: 19px;
                    font-family: var(--heading-font-family), var(--default-font-family);
                    font-weight: 500;
                  }
                  &.disabled {
                    opacity: 0.6;
                    pointer-events: none
                  }
                }
              }
              .desktop-update-btn{
                @media (max-width: 1350px) {
                  display: none;
                }
                button {
                  padding: 8px 12px !important;
                }
              }
            }
          }
          .all-action-alignment {
            @include flex-between;
            align-items: self-start;
            @include breakpoint("max-md") {
              display: none;
            }
            .price-count {
              span {
                @include text-xl-bold;
                display: block;
                @include text-center;
                color: var(--gray-7-light);
              }
            }
            .remove-icon {
              cursor: pointer;
              i {
                color: var(--gray-text-color);
                @include pointer;
              }
            }
            .quantity-button {
              text-align: center;
              button {
                min-width: 90px;
                height: 43px;
                border: 1px solid var(--primary-color);
                background-color: transparent;
                @include heading-xl-bold;
                color: var(--primary-color);
                border-radius: 7px;
                position: relative;
                line-height: 20px;
                span {
                  position: absolute;
                  top: 50%;
                  display: block;
                  @include flex-center;
                  transform: translateY(-50%);
                  left: 28px;
                }
                .arrow-alignment {
                  position: absolute;
                  right: 14px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  div {
                    i {
                      font-size: 14px;
                    }
                  }
                }
                &.disabled {
                  cursor: default;
                  pointer-events: none;
                  opacity: 0.6;
                }
              }
            }
            .duplicate-item {
              margin-top: 4px;
              color: var(--primary-color);
              cursor: pointer;
            }
          }
        }
      }
    }
    .cart-items-list-view-section:last-child {
      border: none;
    }
  }
  .table-header-align {
    @include flex-between;
    background-color: var(--cart-page-header-title-bg-color);
    padding-top: map-get($pixel, md) * 2 + 2px;
    padding-bottom: map-get($pixel, md) * 2 + 2px;
    padding-left: map-get($pixel, md) * 2 + 2px;
    border-radius: 7px 7px 0 0;
    padding-right: map-get($pixel, md) * 5 + 5px;
    @include breakpoint("max-md") {
      display: none;
    }
    .child-all-title {
      @include flex-center;
      width: 372px;
      .child-title:last-child {
        padding-right: 0px;
      }
      .child-title {
        padding-right: map-get($pixel, md) * 5 + 5px;
        span {
          @include text;
          color: var(--cart-page-header-title-font-color);
          display: block;
          @include font-semi-bold;
        }
      }
    }
    .first-title {
      input[type=checkbox]:checked+label:before {
        background-color: var(--cart-page-header-title-font-color);
        color: var(--cart-page-header-title-bg-color);
        font-size: 10px !important;
      }
      label {
        @include text;
        @include flex-center;
        @include font-semi-bold;
        color: var(--cart-page-header-title-font-color);
        span {
          padding-left: map-get($pixel, md) * 2;
        }
      }
    }
  }
}