@import "styles/mixins/index";
.campaign-product-list-theme2 {
  .campaign-department-title {
    margin: 30px 0;
    @media only screen and (max-width: 991px) {
      margin: 15px 0;
    }
    h1 {
      padding: 0 0 10px 0;
      border-bottom: 1px solid var(--gray-text-color);
    }
  }
  .campaign-card-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding-bottom: 25px;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    @media only screen and (max-width: 470px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
    .campaign-product-card-theme2 {
      .product-card {
        min-height: 350px;
        border: none;
        @media only screen and (max-width: 575px) {
          min-height: 320px;
        }
        .product-card-onsale-lable {
          display: inline-block;
          position: absolute;
          left: 5px;
          top: 5px;
          z-index: 1;
          font-size: 12px;
          padding: 4px 15px 4px 4px;
          border-radius: 5px;
          margin: 0;
          font-weight: 600;
          background-color: var(--add-to-cart-sale-bg-color);
          color: var(--add-to-cart-sale-font-color);
        }
      }
      .product-card.campaign-add-to-list {
        min-height: 410px;
        overflow: visible;
        border: 1px solid #ddd;
        border-radius: 10px;
        outline: 3px solid transparent;
        outline-offset: -3px;
        @media only screen and (max-width: 768px) {
          min-height: 390px;
          padding: 15px;
        }
        &:hover {
          border-color: var(--border-color-theme2);
          outline-color: var(--border-color-theme2);
          .product-card-image {
            a {
              img {
                transform: scale(1.2);
              }
            }
          }
        }
      }
      justify-content: space-between;
      position: relative;
      border-radius: 4px;
      .compaign-wrapper {
        margin-bottom: 30px;
      }
      .campaign-tab-alignment {
        position: relative;
      }
      .campaign-link {
        position: absolute;
        top: 0;
        left: auto;
        right: 130px;
        bottom: 0;
        height: max-content;
        margin: auto;
        @include breakpoint("max-sm") {
          right: 0
        }
      }
      @include normal-radius;
      @include ease-in-out;
      @include breakpoint("max-md") {
        min-height: 435px;
      }
      @include breakpoint("max-sm") {
        border: none;
        padding: 10px;
        min-height: 270px;
      }
      .coupon-used-time-ribbon {
        position: absolute;
        right: 0;
        top: 0;
        @include breakpoint("max-sm") {
          top: 4px;
        }
        .coupon-used-time {
          background-color: var(--secondary-color);
          width: fit-content;
          padding: 17px 6px 16px 7px;
          margin: 2px 2px 15px -18px;
          position: relative;
          @include breakpoint("max-sm") {
            margin: 0 0 10px -2px;
          }
          p {
            color: var(--bg-white);
            @include heading-text-sm-normal;
            @include breakpoint("max-md") {
              font-size: 12px;
              line-height: 16px;
            }
            span {
              @include font-medium;
            }
          }
          &::before {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            bottom: -8px;
            right: 8px;
            border-width: 14px 12px 14px 0;
            transform: rotate(90deg);
            position: absolute;
            content: "";
            border-color: transparent var(--bg-white) transparent transparent;
          }
        }
      }
      .product-card-image {
        @include flex-center;
        justify-content: map-get($layout, center);
        min-height: 137px;
        max-height: 137px;
        @include breakpoint("max-sm") {
          padding: 16px;
        }
        img {
          max-width: 170px;
          max-height: 170px;
          object-fit: cover;
          transition: all 0.6s ease-in-out;
          @include breakpoint("max-sm") {
            max-height: 105px;
            width: 100%;
          }
        }
      }
      .product-card-details {
        padding: map-get($pixel, md) * 2 + 4px 0 0 0;
        height: 50px;
        @include breakpoint("max-sm") {
          padding: 0;
        }
        .digital-coupon-wrapper {
          display: flex;
          justify-content: space-between;
          .coupon-design {
            background-color: var(--coupon-bg-color);
            width: fit-content;
            padding: 6px 15px 6px 9px;
            // margin: 0 0 map-get($pixel, md) 0;
            position: relative;
            @include breakpoint("max-sm") {
              margin: 0 0 10px 0;
            }
            p {
              color: var(--coupon-text-color);
              @include heading-text-sm-normal;
              @include breakpoint("max-md") {
                font-size: 12px;
                line-height: 16px;
              }
              span {
                @include font-medium;
              }
            }
            &::before {
              display: inline-block;
              width: 0;
              height: 0;
              border-style: solid;
              top: 0;
              border-width: 14px 12px 14px 0;
              position: absolute;
              content: "";
              right: 0;
              border-color: transparent var(--bg-white) transparent transparent;
            }
          }
          &.hidden {
            display: none;
            min-height: 38px;
          }
        }
        .regular-price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 15px 0 13px;
          .regular-price1 {
            font-size: 18px;
          }
          p {
            @include text-sm;
            @include font-medium;
            color: var(--black-text-color);
            padding: 0;
            span {
              color: var(--primary-color);
              font-weight: 700;
            }
          }
          .hidden {
            display: none;
            min-height: 19px;
          }
          .max-h-38 {
            min-height: 38px;
          }
          .regular-price3 {
            font-size: 12px;
          }
          .regular-price4 {
            font-size: 12px;
          }
        }
        a {
          @include heading-4;
          color: var(--black-text-color);
          display: block;
          line-height: 22px;
          @include ease-in-out;
          font-size: 14px;
          margin: 0 0 map-get($pixel, sm) + 3px 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          &:hover {
            color: var(--secondary-color);
          }
        }
        .button-group-alignment {
          position: absolute;
          top: auto;
          right: 0;
          left: 0;
          bottom: 15px;
          margin-top: 15px;
          padding: 0;
          display: block;
          @include flex-between;
          padding-top: 0;
          padding: 0 10px;
          .add-to-list {
            button {
              font-family: var(--text-font-family), var(--default-font-family);
              height: 37px;
              width: 100%;
              background-color: var(--secondary-color);
              margin-top: 15px;
              position: relative;
              padding: 8px 10px;
              border: none;
              border-radius: 4px;
              justify-content: space-between;
              margin-bottom: 0;
              font-weight: 500;
              font-size: 12px;
              line-height: 0.611;
              text-transform: uppercase;
              color: #FFFFFF;
              transition: 0.3s ease-in-out;
            }
          }
          .add-button-group-section {
            align-items: center;
            display: flex;
            justify-content: space-between;
            // position: absolute;
            position: unset;
            top: auto;
            right: 0;
            left: 0;
            bottom: 15px;
            padding: 0 15px;
            width: 100%;
          }
          .button-group-section {
            align-items: center;
            display: flex;
            justify-content: space-between;
            // position: absolute;
            position: unset;
            top: auto;
            right: 0;
            left: 0;
            bottom: 15px;
            padding: 0;
            @include breakpoint("max-sm") {}
            .button-group {
              border: 1px solid var(--border-color-5);
              @include normal-radius;
              @include flex-center;
              @include pointer;
              width: fit-content;
              .action-button,
              .counter {
                width: 24px;
                height: 23px;
                @include flex-center;
                justify-content: map-get($layout, center);
              }
              .action-button {
                color: var(--gray-text-color);
                @include ease-in-out;
                &:hover {
                  background-color: var(--primary-color);
                  color: var(--bg-white);
                }
                i {
                  font-size: 12px;
                }
              }
              .action-button-plus {
                width: 29px;
                height: 23px;
                @include flex-center;
                color: var(--gray-text-color);
                justify-content: map-get($layout, center);
                @include ease-in-out;
                &:hover {
                  background-color: var(--primary-color);
                  color: var(--bg-white);
                }
                i {
                  font-size: 12px;
                }
              }
              .counter {
                background-color: var(--border-color);
                @include heading-text-sm;
                color: var(--black-text-color);
              }
              @include breakpoint("max-sm") {
                margin: 0 2px;
              }
            }
            .add-list-button {
              padding-right: 0px;
              i {
                cursor: pointer;
              }
              button {
                border-radius: 4px;
              }
              @include breakpoint("max-sm") {
                margin: 0 2px;
              }
            }
            .add-to-list-show {
              overflow: visible;
            }
            .new-list-input-dropdown {
              top: auto;
              bottom: -108px;
              left: -7px;
            }
            .add-to-list-dropdown {
              right: 0px;
              top: auto;
              bottom: 40px;
              @include breakpoint("max-sm") {
                right: 5px;
              }
            }
          }
          .add-list-button {
            padding: 0;
            margin-left: 10px;
            i {
              font-size: 25px;
            }
          }
        }
        .final-price {
          padding: 15px 0 8px 0;
          p {
            @include text-sm-normal;
            color: var(--black-text-color);
            font-size: 20px;
            @include font-medium;
            span {
              color: var(--primary-color);
              font-weight: 700;
              margin: 0 10px 0 0;
            }
            .price1 {
              font-size: 15px;
              text-decoration: line-through;
              color: #7a7a7a;
              font-weight: 400;
            }
          }
          &.hidden {
            display: none;
            min-height: 19px;
          }
        }
        .add-to-cart {
          width: 100%;
          button {
            font-family: var(--text-font-family), var(--default-font-family);
            height: 37px;
            width: 100%;
            background-color: var(--add-to-card-bg-color);
            position: relative;
            padding: 8px 10px;
            justify-content: space-between;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 0.611;
            color: #000;
            transition: 0.3s ease-in-out;
            @include text-xs-semi-bold;
            border: none;
            @include normal-radius;
            @include flex-center;
            @include pointer;
            @include ease-in-out;
            &:hover:not([disabled]) {
              background-color: var(--primary-color);
              color: var(--bg-white);
              span {
                color: #fff;
              }
              svg {
                stroke: var(--bg-white);
                g {
                  path {
                    fill: var(--bg-white);
                  }
                }
              }
            }
            span {
              padding-left: 4px;
              font-size: 12px;
            }
            svg {
              stroke: var(--add-to-card-font-color);
              @include ease-in-out;
              g {
                path {
                  fill: var(--add-to-card-font-color);
                }
              }
            }
            &:disabled {
              cursor: default;
            }
          }
          .add-to-cart-display-block {
            display: block;
          }
          @include breakpoint("max-sm") {
            margin: 0 2px;
          }
        }
        .add-to-cart.add-outof-stock-btn {
          button {
            background-color: var(--add-to-cart-outofstock-bg-color);
            color: var(--add-to-cart-outofstock-font-color);
            cursor: no-drop;
            &:hover:not([disabled]) {
              background-color: var(--add-to-cart-outofstock-bg-color);
              color: var(--add-to-cart-outofstock-font-color);
              svg {
                stroke: var(--add-to-cart-outofstock-font-color);
                g {
                  path {
                    fill: var(--add-to-cart-outofstock-font-color);
                  }
                }
              }
            }
            span {
              color: var(--add-to-cart-outofstock-font-color);
            }
            svg {
              stroke: var(--add-to-cart-outofstock-font-color);
              // @include ease-in-out;
              g {
                path {
                  fill: var(--add-to-cart-outofstock-font-color);
                }
              }
            }
            &:disabled {
              cursor: no-drop;
              .add-to-cart-icon {
                display: none;
              }
              .out-of-stock-icon {
                display: inline-block;
                vertical-align: baseline;
              }
            }
          }
        }
        .button-disable {
          pointer-events: none;
          opacity: 0.5;
        }
        .price {
          @include flex-center;
          padding: 0 0 map-get($pixel, md) * 2 + 8px 0;
          h3 {
            @include text-bold;
            color: var(--color);
            padding-right: 21px;
          }
          del {
            @include text-sm-semi-bold;
            font-size: 13px;
            color: var(--gray-8);
          }
        }
        .star-text-alignment {
          @include flex-center;
          padding: 0 0 map-get($pixel, sm) + 3px 0;
          .rating-text {
            span {
              @include text-xs-normal;
              color: var(--gray-7);
            }
          }
          .star-alignment {
            @include flex-center;
            padding-right: 10px;
            i:last-child {
              margin-right: 0px;
            }
            i {
              color: var(--secondary-color);
              margin-right: 3px;
              @include pointer;
              font-size: 12px;
            }
          }
        }
        h3 {
          @include text-sm;
          padding: 0 0 map-get($pixel, sm) + 3px 0;
          display: block;
          color: var(--color);
        }
        .d-none {
          display: none;
        }
      }
    }
  }
}