@import "styles/mixins/index";

.image-card-wrapper {
  border: 1px solid var(--border-color-4);
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 500px;
  overflow: auto;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;
  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }
  &:hover {
    border: 1px solid var(--primary-color);
    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
  img {
    width: 100%;
    position: relative;
    object-fit: cover;
  }
}

.new-sale-product-theme2 {
  .new-sale-all-wrapper {
    gap: 15px;
  }
  .image-card-wrapper-theme2 {
    border: 1px solid var(--border-color-4);
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 15px;
    height: 500px;
    overflow: auto;
    outline: 3px solid transparent;
    outline-offset: -3px;
    transition: all 0.6s ease-in-out;
    @include normal-radius;
    // @include pointer;
    @include ease-in-out;
    a {
      overflow: hidden;
      display: block;
    }
    @include breakpoint("max-sm") {
      min-height: unset;
      border: none;
      padding: 28px;
      border: 1px solid var(--border-color-4);
      min-height: unset;
    }
    img {
      width: 100%;
      position: relative;
      object-fit: cover;
      transition: all 0.6s ease-in-out;
    }
    &:hover {
      border: 1px solid var(--primary-color);
      border-color: var(--border-color-theme2);
      outline-color: var(--border-color-theme2);
      @include breakpoint("max-sm") {
        // display: none;
        border-bottom: 1px solid var(--primary-color);
      }
      img {
        transform: scale(1.1);
      }
    }
  }
}