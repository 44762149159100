 .my_account_orders {
     border-collapse: collapse;
     border-spacing: 0;
     max-width: 640px;
     width: 100%;
     tbody{
        tr{
            td{
                .order-number{
                    img{
                        max-height: 70px;
                        max-width: 280px;
                    }
                }
            }
        }
     }
 }

 .my_account_orders th {
     text-align: left;
 }

 tr {
     border-bottom: 1px solid #ccc;
 }

 th,
 td {
     padding: 6px;
 }

 @media only screen and (max-width: 600px) {

     /* Force table to not be like tables anymore */
     table,
     thead,
     tbody,
     th,
     td,
     tr {
         display: block;
     }

     /* Hide table headers (but not display: none;, for accessibility) */
     thead tr {
         position: absolute;
         top: -9999px;
         left: -9999px;
     }

     td {
         /* Behave  like a "row" */
         border: none;
         border-bottom: 1px solid #eee;
         position: relative;
         padding-left: 3%;
     }

     td:last-child {
         border-width: 0;
     }

     td:before {
         content: attr(data-title);
         color: #ccc;
         /* Now like a table header */
         position: absolute;
         /* Top/left values mimic padding */
         top: 6px;
         left: 6px;
         width: 45%;
         padding-right: 10px;
         white-space: nowrap;
     }
 }