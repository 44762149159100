.contact-section {
    margin-bottom: 20px;
    .error-message{
        color: #ff0000;
    }
    .title-wrap {
        margin-bottom: 30px;
        @media (max-width: 1024px) {
            margin-bottom: calc(10px + (25 - 10) * ((100vw - 280px) / (1024 - 280)));
        }
        h1 {
            font-size: 70px;
            font-weight: 600;
            line-height: 1.3;
            text-transform: capitalize;
            @media (max-width: 1024px) {
                font-size: calc(20px + (60 - 20) * ((100vw - 280px) / (1024 - 280)));
            }
        }
    }
    .contact-content {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 2px #14171C solid;
        .contact-desc {
            margin-bottom: 30px;
            @media (max-width: 1024px) {
                margin-bottom: calc(20px + (25 - 20) * ((100vw - 280px) / (1024 - 280)));
            }
            p {
                font-size: 17px;
                line-height: 1.57;
                @media (max-width: 1024px) {
                    font-size: calc(10px + (17 - 10) * ((100vw - 280px) / (1024 - 280)));
                }
            }
        }
        .form-wrapper {
            margin: 0 0 1rem;

            input,
            .form-lable {
                display: inline-block;
            }
            .form-lable {
                @media (min-width: 991px) {
                    width: 20%;
                }
                @media (max-width: 991px) {
                    margin-bottom: 15px;
                }
                span {
                    border: none;
                    color: #ff4136;
                    font-size: 0.9rem;
                }
            }
            input {
                padding: 14px 12px;
                border: 2px #dddddd solid;
                font-size: 17px;
                width: 100%;
                @media (min-width: 991px) {
                    width: 40%;
                }
            }
            select {
                width: 100%;
                outline: none;
                position: relative;
                background-color: #fff;
                font-size: 17px;
                appearance: none;
                height: 49px;
                color: #757575;
                padding: 14px 12px;
                border: 2px #dddddd solid;
                background-position: right 10px center;
                background-repeat: no-repeat;
                background-size: auto 50%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
                @media (min-width: 991px) {
                    width: 40%;
                }
            }
        }
        .re-captcha{
            @media (max-width: 350px) {
                transform: scale(0.8);
                transform-origin: 0 0;
              }
        }
        .signup-btn-wrapper {
            .signup-btn {
                padding: 16px 35px;
                background-color: var(--primary-color);
                color: var(--bg-white);
                border-radius: calc(5px - 0px);
                border: none;
                font-size: 18px;
                margin: 0;
                line-height: 28px;
                border-radius: 50px;
                font-weight: 500;
                width: 30%;
                transition: all 0.5s ease-in-out;
                margin-top: 20px;
                @media (max-width: 991px) {
                    width: 40%;
                }
                @media (max-width: 480px) {
                    width: 50%;
                }
            }
        }
    }
    .message {
        margin-top: 15px;
        font-size: 15px;
        color: #14171c;
        a {
            color: #ee0303;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
.support-div {
    display: flex;
    justify-content: start;
    text-align: center;
    margin-top: 8px;
    h1 {
        margin: 0;
        font-size: 2rem;
    }
}