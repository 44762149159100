@import "styles/mixins/index";

.catering-product-list-grid {
  @include grid-layout;
  grid-template-columns: repeat(4, 1fr);
  gap: map-get($pixel, sm) * 4;
  @include breakpoint("max-xl") {
    grid-template-columns: repeat(4, 1fr);
  }
  @include breakpoint("max-md") {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include breakpoint("max-sm") {
    .product-list-grid-items:first-child {
      .product-card {
        padding-top: 0;
      }
    }
  }

  .catering-product-list-grid-items {
    .product-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include breakpoint("max-sm") {
        padding: 28px 0;
        border: none;
        border-bottom: 1px solid var(--border-color-4);
        @include grid-layout;
        grid-template-columns: 128px minmax(0, 1fr);
        gap: 0 10px;
        align-items: flex-start;
      }
      &:hover {
        border: 1px solid var(--primary-color);
        @include breakpoint("max-sm") {
          // display: none;
          border-bottom: 1px solid var(--primary-color);
        }
      }
      .product-card-image {
        padding: 16px;
        border-radius: calc(5px - 1px);

        @include breakpoint("max-sm") {
          border: 1px solid var(--border-color-4);
        }
      }
      @include breakpoint("max-sm") {
        border-bottom: 1px solid var(--border-color-4);
      }
    }
  }
}
