@import "styles/mixins/index";

.forgot-password-page-wrapper {
  padding: map-get($pixel, md) * 12 0 map-get($pixel, md) * 29 0;
  @include breakpoint("max-md") {
    padding: map-get($pixel, md) * 3 0 0 0;
  }
  .container {
    padding: 0;
  }
  .forgot-password-error {
    display: block;
    margin-bottom: 10px;
    color: var(--bg-danger);
  }
  .auth-grid-alignment {
    @include flex-center;
    justify-content: center;
    @include breakpoint("max-xs") {
      justify-content: flex-start;
    }
    @include breakpoint("max-sm") {
      justify-content: flex-start;
      display: block;
    }
    .auth-grid {
      @include grid-layout;
      grid-template-columns: min(454px) max(597px);
      gap: 0;
      @include breakpoint("max-xs") {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
      }
      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 46px 0;
      }
      .link-send-success-message {
        color: var(--secondary-color);
        margin-bottom: 20px;
        display: block;
      }
      .auth-grid-items:nth-child(1) {
        padding-right: map-get($pixel, md) * 4;
        border-right: 1px solid var(--border-color-6);
        @include breakpoint("max-md") {
          border: none;
          padding: 0;
        }
      }
      .auth-grid-items:nth-child(2) {
        padding-left: map-get($pixel, md) * 6 + 4px;
        @include breakpoint("max-md") {
          padding: map-get($pixel, md) * 5 map-get($pixel, md) * 4;
        }
        @include breakpoint("max-sm") {
          padding: map-get($pixel, md) * 5 map-get($pixel, md) * 2;
          background-color: var(--primary-light-color);
        }
      }
      .auth-grid-items {
        > h1 {
          @include heading-8-normal;
          @include text-center;
          color: var(--invert-secondary-color);

          span {
            color: var(--primary-color);
          }
        }
        .sign-in-button {
          button {
            padding: map-get($pixel, sm) * 2 map-get($pixel, md) * 3;
            background-color: var(--primary-color);
            color: var(--bg-white);
            border-radius: calc(5px - 0px);
            border: none;
            @include heading-4;
          }
          .back-to-login-link {
            text-align: center;
            text-decoration: underline;
            color: var(--primary-color);
            font-weight: 500;
          }
        }
        .form-control {
          margin: 0 0 map-get($pixel, sm) * 4 0;
          a {
            @include text-sm;
            color: var(--secondary-color);
            @include pointer;
            padding: calc(5px + 2px) 0 0 0;
            display: block;
            text-align: right;
          }
          span {
            @include heading-text-xs;
            display: block;
            padding: map-get($pixel, sm) 0 0 0;
            color: var(--bg-danger);
          }
        }
        p {
          @include text;
          font-size: calc(20px - 3px);
          color: var(--invert-secondary-color);
          margin: 0 0 map-get($pixel, md) * 3 0;
          @include breakpoint("max-sm") {
            margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
          }
          span {
            color: var(--primary-color);
            @include pointer;
          }
        }
        > h2 {
          @include heading-9-medium;
          margin: 0 0 map-get($pixel, md) - 3px 0;
          color: var(--invert-secondary-color);
          @include breakpoint("max-sm") {
            @include heading-2;
          }
        }
        .logo-center-wrapper {
          @include flex-center;
          padding: 0 0 map-get($pixel, md) * 2 0;
          justify-content: map-get($layout, center);
          img {
            max-width: 209px;
          }
        }
      }
    }
  }
}
