@import "styles/mixins/index";
.product-info {
  padding: 0 0 100px 0;
  @include breakpoint("max-sm") {
    padding: 40px 0;
  }
  .info-box {
    border: 0.5px solid var(--border-color-11);
    border-radius: 15px;
    padding: 51px 43px;
    margin: 0 70px;
    @include breakpoint("max-md") {
      margin: 0;
    }
    @include breakpoint("max-sm") {
      padding: 0;
      margin: 0;
      border: none;
    }
    .info-details {
      p {
        @include text-md;
        color: var(--gray-6);
        margin: 0 0 map-get($pixel, md) * 2 0;
        @include breakpoint("max-sm") {
          @include text-sm-normal;
        }
      }
      .html-parser {
        text-align: left;
      }
      p:last-child {
        margin: 0;
      }
      .Nutrition-heading {
        margin: 0 0 map-get($pixel, md) * 2 0;
        padding-left: 15px;
      }
      .nutrition-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include breakpoint("max-md") {
          display: block;
        }
        .nutrition-image {
          width: 33%;
          overflow: hidden;
          @include breakpoint("max-sm") {
            width: 100%;
          }
          @include breakpoint("max-md") {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        .nutrition-information {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
    .info-tab {
      padding: 0 0 map-get($pixel, md) * 5 0;
      // @include breakpoint("max-sm") {
      //   @include flex-around;
      // }
      button:last-child {
        margin: 0;
      }
      button {
        padding: 15px 35px;
        border: 1px solid var(--border-light);
        margin-right: map-get($pixel, sm) * 3;
        border-radius: 999px;
        background-color: transparent;
        color: var(--gray-6);
        @include text-md;
        @include font-semi-bold;
        @include breakpoint("max-sm") {
          @include text-sm-semi-bold;
        }
      }
      .active {
        border: 1px solid var(--primary-color);
        box-shadow: (0px 0px 10px rgba(126, 126, 126, 0.16));
        color: var(--primary-color);
      }
    }
  }
}