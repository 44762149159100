@import "styles/mixins/index";

.coupon-cart-section {
  .coupon-cart-grid {
    @include grid-layout;
    padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 7 + map-get($pixel, sm) 0;
    grid-template-columns: minmax(0, 1fr) 504px;
    gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm);

    @include breakpoint("max-xl") {
      grid-template-columns: minmax(0, 1fr) 30px;
    }

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
      padding: 20px 0 40px 0;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
    }
  }
}

.coupon-cart-section-align {
  .coupon-cart-title {
    padding: 0 0 map-get($pixel, md) * 2 0;

    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 1 0;
    }

    h1 {
      @include heading-9-medium;
      color: var(--black-text-color);
      margin: 0 0 map-get($pixel, md) * 2 - 1px 0;

      @include breakpoint("max-sm") {
        @include heading-5;
      }
    }
  }

  .coupon-page-grid-align {
    @include flex-between;
    width: calc(100% - 547px);

    @include breakpoint("max-xl") {
      min-width: calc(100% - 450px);
    }

    @include breakpoint("max-md") {
      width: 100%;
      @include grid-layout;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }



  }
}