@use "styles/mixins/breakpoint";

@mixin heading-large {
  font-size: 75px;
  line-height: 80px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
  letter-spacing: -0.01em;
  @include breakpoint("max-xl") {
    font-size: 55px;
    line-height: 65px;
  }
}

@mixin heading-1 {
  font-size: 65px;
  line-height: 70px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
  letter-spacing: -0.01em;
  @include breakpoint("max-xl") {
    font-size: 55px;
    line-height: 65px;
  }
}

@mixin heading-2 {
  font-size: 36px;
  line-height: 45px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}

@mixin heading-3 {
  font-size: 26px;
  line-height: 38px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}

@mixin heading-3-normal {
  font-size: 26px;
  line-height: 38px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin heading-8-normal {
  font-size: 40px;
  line-height: 40px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin heading-9-medium {
  font-size: 45px;
  line-height: 50px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium();
}

@mixin heading-5 {
  font-size: 30px;
  line-height: 40px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}

@mixin heading-3xl {
  font-size: 28px;
  line-height: 35px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium();
}

@mixin heading-6 {
  font-size: 16px;
  line-height: 28px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}

@mixin heading-6-normal {
  font-size: 16px;
  line-height: 28px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin heading-7 {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin heading-4 {
  font-size: 17px;
  line-height: 28px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}

@mixin heading-text-sm {
  font-size: 15px;
  line-height: 19px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}
@mixin heading-4xl {
  font-size: 19px;
  line-height: 29px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}
@mixin heading-text-sm-normal {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-normal;
}

@mixin heading-text-md-medium {
  font-size: 18px;
  line-height: 24px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium();
}

@mixin heading-text-md-semi-bold {
  font-size: 18px;
  line-height: 24px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-semi-bold();
}

@mixin heading-text-xs {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium;
}

@mixin text-lg {
  font-size: 24px;
  line-height: 28px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal;
}

@mixin heading-xl-bold {
  font-size: 21px;
  line-height: 25px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium();
}

@mixin text {
  font-size: 16px;
  line-height: 22px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal;
}
@mixin text-4xl {
  font-size: 22px;
  line-height: 28px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold();
}

@mixin text-xl-bold {
  font-size: 21px;
  line-height: 25px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold();
}

@mixin text-5xl {
  font-size: 30px;
  line-height: 40px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold();
}
@mixin text-bold {
  font-size: 16px;
  line-height: 22px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold();
}

@mixin text-sm-normal {
  font-size: 15px;
  line-height: 20px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal;
}

@mixin text-semi-bold {
  font-size: 16px;
  line-height: 22px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold;
}

@mixin text-sm {
  font-size: 14px;
  line-height: 19px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal;
}
@mixin text-sm-semi-bold {
  font-size: 14px;
  line-height: 19px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold();
}

@mixin text-md {
  font-size: 18px;
  line-height: 24px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin text-md-normal {
  font-size: 18px;
  line-height: 24px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin text-md-semi-bold {
  font-size: 19px;
  line-height: 22px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold;
}

@mixin text-md-regular {
  font-size: 19px;
  line-height: 22px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin text-xs-medium {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-medium;
}

@mixin text-xs-semi-bold {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-semi-bold();
}

@mixin text-xs-normal {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--text-font-family), var(--default-font-family);
  @include font-normal();
}

@mixin font-normal {
  font-weight: 400;
}

@mixin font-medium {
  font-weight: 500;
}

@mixin font-semi-bold {
  font-weight: 600;
}

@mixin font-bold {
  font-weight: 700;
}

@mixin heading-7-medium {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--heading-font-family), var(--default-font-family);
  @include font-medium();
}
