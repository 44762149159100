@import "styles/mixins/index";
.listing-sidebar-sticky-theme2 {
  position: sticky;
  top: 40px;
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  @include breakpoint("max-md") {
    display: none;
  }
  @include breakpoint("max-sm") {
    display: none;
  }
  .sidebar-banner {
    padding: map-get($pixel, md) * 4 0 0 0;
    img {
      width: 100%;
      height: 337px;
      object-fit: cover;
    }
  }
  .sidebar-item-list {
    padding: map-get($pixel, sm) * 5 0;
    .reorder-dropdown-subheading {
      display: block;
      color: var(--gray-400);
      margin: 10px 0;
      font-weight: 600;
    }
    .re-order-checkbox-wrapper {
      padding-top: 0;
    }
    .ift-item-with-checkbox:last-child {
      padding: 0;
    }
    .ift-item-with-checkbox {
      @include flex-center;
      position: relative;
      padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
      label {
        @include flex-center;
        span {
          font-weight: 500;
          padding-left: 7px;
        }
      }
      input[type="checkbox"]:checked+label {
        &:after {
          height: 9px;
          top: 6px;
          left: 6px;
        }
      }
      div {
        @include flex-center;
        input[type="checkbox"] {
          width: 15px;
          height: 15px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  .sidebar-toggle-item {
    padding-bottom: map-get($pixel, sm) * 2;
    border-bottom: 1px solid var(--border-color-5);
    .range-details {
      padding: map-get($pixel, sm) * 4 0 0 0;
      p,
      span {
        @include text;
        color: var(--gray-400);
      }
      span {
        @include font-semi-bold;
      }
    }
    .shop-by-price {
      padding: 20px 0;
      border-bottom: 1px solid var(--border-color-5);
      @include flex-between;
      font-family: var(--text-font-family);
      h2 {
        @include heading-7-medium;
        display: block;
        font-family: var(--text-font-family);
        color: #000;
        line-height: 1;
        font-weight: 500;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
      i {
        color: var(--light-icon);
      }
    }
    .range-style {
      padding-top: 20px;
      margin: 0px 12px;
      .rc-slider-handle {
        -webkit-appearance: none;
        appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #04aa6d;
        cursor: pointer;
        border: unset;
      }
      .rc-slider-track {
        background: #04aa6d;
      }
      .rc-slider-rail {
        background: #bfbfbf;
      }
    }
  }
}
// /* total width */
// #style-5::-webkit-scrollbar {
//   background-color: #fff;
//   width: 16px;
// }
// /* background of the scrollbar except button or resizer */
// #style-5::-webkit-scrollbar-track {
//   background-color: #fff;
// }
// #style-5::-webkit-scrollbar-track:hover {
//   background-color: #f4f4f4;
// }
// /* scrollbar itself */
// #style-5::-webkit-scrollbar-thumb {
//   background-color: #ceced5;
//   border-radius: 16px;
//   border: 5px solid #fff;
// }
// #style-5::-webkit-scrollbar-thumb:hover {
//   background-color: #b4b4b8;
//   border: 4px solid #f4f4f4;
// }
// /* set button(top and bottom of the scrollbar) */
// #style-5::-webkit-scrollbar-button {
//   display: none;
// }
#style-5 {
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 0px;
  }
}
#style-5:hover {
  &::-webkit-scrollbar {
    width: 8px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dddbdb;
    border-radius: 16px;
  }
  #style-5 {
    margin-right: 2px;
  }
}
@media (hover: none) {
  #style-5 {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }
}
.mobile-view {
  display: block;
  padding: 16px;
}
.listing-page-sidebar-theme2 {
  .child-department-list {
    padding-left: 18px;
    .ift-item-with-checkbox {
      @include flex-center;
      position: relative;
      padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
      label {
        @include flex-center;
      }
      span {
        @include text;
        color: var(--gray-400);
        display: block;
        padding-left: map-get($pixel, sm) + 2px;
      }
    }
  }
  .sidebar-title-alignment {
    @include flex-center;
    padding: 20px 0;
    justify-content: space-between;
    @include pointer;
    border-radius: 4px;
    border-bottom: none;
    font-size: 13px;
    border-bottom: 1px solid var(--border-color-5);
    span {
      @include heading-7-medium;
      display: block;
      font-family: var(--text-font-family);
      color: #000;
      line-height: 1;
      font-weight: 500;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
    i {
      color: var(--light-icon);
      transition: 0.3s ease-in-out;
      margin-right: 4px;
    }
  }
  .department-hidden {
    max-height: 0px;
    overflow: hidden;
    @include ease-in-out;
  }
  .department-show {
    max-height: 50vh;
    overflow: hidden;
    @include ease-in-out;
    z-index: 999;
    overflow-y: auto
  }
  .department-item {
    @include flex-center;
    @include pointer;
    border-radius: 4px;
    .add-icon {
      @include pointer;
      i {
        color: var(--gray-text-color);
      }
    }
    padding: 10px 15px;
    justify-content: space-between;
    // padding: map-get($pixel, sm) * 2 0 map-get($pixel, sm) * 2 0;
    &:hover {
      @include ease-in-out;
      background-color: var(--primary-light-color);
      color: var(--primary-color);
    }
    .title-alignment {
      @include flex-center;
      position: relative;
      @include pointer;
      &:hover {
        @include ease-in-out;
        color: var(--primary-color) !important;
      }
    }
    input[type="checkbox"] {
      width: 15px;
      height: 15px;
      margin: 0;
      padding: 0;
    }
    label {
      @include flex-center;
    }
    span {
      @include text;
      color: var(--gray-400);
      display: block;
      // padding-left: map-get($pixel, sm) * 2 + 2px;
      padding: 10px 0 10px map-get($pixel, sm) * 2 + 2px;
      &:hover {
        @include ease-in-out;
        background-color: var(--primary-light-color);
        color: var(--primary-color);
      }
    }
  }
  .active {
    background-color: var(--primary-light-color);
    color: var(--primary-color);
  }
  .other-department-list {
    a {
      display: block;
      padding: 10px 15px;
      @include ease-in-out;
      &:hover {
        color: var(--secondary-color);
      }
    }
  }
}