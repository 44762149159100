@import "styles/mixins/index";

.content-center-align {
    // justify-content: map-get($layout, center);
}

.account-wrapper-section-theme2 {
    padding: 0 0 40px 0;

    @include breakpoint("max-md") {
        width: 100%;
        padding: 40px 0;
    }

    @include breakpoint("max-sm") {
        width: 100%;
    }

    .grid {
        @include grid-layout;
        gap: map-get($pixel, md) * 5 + 2px;
        grid-template-columns: 300px minmax(0, 1fr);

        @media (max-width: 992px) {
            grid-template-columns: repeat(1, 1fr);
            display: block;
        }

        .grid-items {
            .account-tab {
                @include breakpoint("max-sm") {
                    display: none;
                }

                .active-button {
                    background-color: var(--primary-color);
                    color: var(--bg-white);

                    i {
                        color: var(--bg-white);
                    }
                }

                button {
                    padding: 15px 19px;
                    border: 1px solid var(--border-color-8);
                    border-radius: 5px;
                    @include flex-center;
                    margin: 0 0 15px 0;
                    justify-content: flex-start;
                    color: #6a6a6a;
                    height: 52px;
                    @include text;
                    width: 100%;
                    background-color: transparent;
                    @include ease-in-out;

                    i {
                        color: #b9b9b9;
                    }


                    &:hover {
                        background-color: var(--primary-color);
                        color: var(--bg-white);

                        i {
                            color: var(--bg-white);
                        }

                    }

                    div:last-child {
                        padding-left: 7px;
                    }

                    div {
                        @include flex-center;

                        svg {
                            @include ease-in-out;
                        }
                    }
                }
            }
        }
    }
}

.mobile-view-select-show {
    display: none;
    position: relative;
    cursor: pointer;
    @include breakpoint("max-sm") {
        display: block;
    }

    .select-dropdown-hidden {
        max-height: 0px;
        overflow: hidden;
        transition: .3s ease-in-out;
    }

    .select-dropdown-show {
        max-height: calc(100vh - 0px);
        overflow: hidden;
        transition: .3s ease-in-out;
    }

    .select-dropdown-border {
        border: 1px solid var(--primary-color);
        border-radius: 4px;
    }

    .select-dropdown-design {
        position: absolute;
        width: 100%;
        z-index: 99999;
        background-color: var(--bg-white);

        .icon-text-alignment {
            height: 45px;
            padding: 0 12px;
            border-bottom: 1px solid var(--border-color-8);
            @include flex-center;

            &:hover {
                background-color: var(--primary-color);

                div {
                    span {
                        color: var(--bg-white);
                    }

                    i {
                        color: var(--bg-white);
                    }
                }
            }

            div {
                span {
                    color: var(--gray-6);
                    @include text-sm;
                    display: block;
                    padding-left: 10px;
                }
            }

            div {
                i {
                    color: #b9b9b9;
                }
            }
        }
    }
}

.mobile-dropdown {
    position: relative;

    .down-icon-alignment {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }

    input {
        cursor: pointer;
        width: 100%;
        border: 1px solid var(--border-color-8);
        height: 45px;
        color: var(--gray-6);
        border-radius: 4px;
        @include text-sm;
        padding: 0 16px;
    }

    ::placeholder {
        @include text-sm;
        color: var(--gray-6);
    }
}