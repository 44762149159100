@import "styles/mixins/index";

.show-items {
  margin-right: map-get($pixel, md) * 2;
  position: relative;

  button {
    font-size: 15px;
    line-height: 19px;
    font-family: var(--heading-font-family), var(--default-font-family);
    font-weight: 500;
    padding: 12px 17px;
    border: 1px solid var(--border-color-2);
    color: var(--black-5-light);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    font-weight: 400;
    min-width: 153px;
    position: relative;
    span {
      padding: 0 map-get($pixel, sm) + 1px;
      // display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;
    }
  }
}
