@import "styles/mixins/index";
.pagination {
  @include flex-center;
  justify-content: map-get($layout,  flex-end);
  @include breakpoint("max-sm") {
    justify-content: map-get($layout, center);
  }
  padding: map-get($pixel, sm) * 3 0 map-get($pixel, sm) * 3 0;

  .left-arrow,
  .page-counter,
  .right-arrow {
    width: 42px;
    cursor: pointer;
    height: 42px;
    @include flex-center;
    justify-content: map-get($layout, center);
    img {
      @include breakpoint("max-sm") {
        height: 28px;
        width: 28px;
      }
    }
  }

  .active-page {
    background-color: var(--primary-color);
    color: var(--text-white) !important;
  }
  .pagination-wrapper {
    @include flex-center;
    gap: 10px;
  }
  .page-counter {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: none;
    @include text-xs-medium;
    transition: 0.3s ease-in-out;
    color: var(--black-text-color);
    @include breakpoint("max-sm") {
      height: 28px;
      width: 28px;
      @include text-sm;
    }

    &:hover {
      background-color: var(--border-color);
      color: var(--black-6) !important ;
    }
  }
  button {
    background-color: var(--border-color);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    @include text-xs-medium;
    border: none;
    @include breakpoint("max-sm") {
      height: 28px;
      width: 28px;
    }
    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-white);
      @include breakpoint("max-md") {
        background-color: var(--border-color);
        color: var(--black-6);
      }
    }
  }
}
