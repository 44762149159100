@import "styles/mixins/index";
.individual-listing-page {
  .breadcrumb-detailed-section {
    padding: 16px 50px;
    @include breakpoint("max-sm") {
      padding: 30px 0;
    }
  }
  .individual-listing-page-wrapper {
    padding: map-get($pixel, md) * 1-4 0;
    padding-bottom: 20px;
    @include grid-layout;
    grid-template-columns: max(321px) minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm) + 3px;
    @include breakpoint("max-sm") {
      padding: 0;
    }
    @include breakpoint("max-md") {
      display: block;
    }
    @include breakpoint("max-sm") {
      display: block;
    }
    .listing-page-wrapper-items {
      .deal-all-wrapper {
        .product-list-grid {
          @media (max-width: 480px) {
            gap: 10px;
          }
          .product-list-grid-items {
            width: 100%;
            .product-col-xl-4 {
              .deal-all-product-main-wrapper {
                @media (max-width: 480px) {
                  padding: 12px;
                }
                .deal-right-product-main-wrap {
                  .deal-right-product-img {
                    @media (max-width: 480px) {
                      margin: 35px 0 0 0;
                    }
                    img {
                      @media (max-width: 480px) {
                        min-height: 100px;
                        max-height: 100px;
                        max-width: 100px;
                      }
                    }
                  }
                  .deal-right-product-caption {
                    @media (max-width: 480px) {
                      .increment-decrement-qty {
                        width: 58px;
                        button {
                          height: 20px;
                          width: 20px;
                          min-width: 20px;
                        }
                      }
                    }
                    .bag-icon {
                      @media (max-width: 480px) {
                        width: 25px;
                        height: 25px;
                        line-height: 2.2;
                      }
                      svg {
                        @media (max-width: 480px) {
                          // width: 15px;
                          // height: 15px;
                          vertical-align: super;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}