@import "styles/mixins/index";



  .catering-checkbox {
    padding: 0 0 12px 0;

    @include breakpoint("max-sm") {
      padding: 0 0 8px 0;
    }

    p {
      @include heading-4;
      margin: 0 0 map-get($pixel, md) 0;
      color: var(--black-5-light);
      cursor: pointer;
      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
      span {
        color: var(--bg-danger);
      }
    }
    .check-box-wrapper.dropdown-show {
      // max-height: 100vh;
      // overflow: hidden;
      display: block;
      transition: 0.3s ease-in-out;
      z-index: unset;
    }
    
    .check-box-wrapper.dropdown-hide {
      // max-height: 0px;
      display: none;
      // overflow: hidden;
      transition: none;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
    }
    .arrow {
      margin-left: 10px;
      transition: transform 0.3s ease;
      display: inline-block;
      transform: rotate(0);  
    }
    
    .arrow-up {
      transform: rotate(180deg);  
    }
    .catering-checkbox-item-list {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(2, 1fr);
        @media screen and (max-width: 992px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 650px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 575px) {
          grid-template-columns: repeat(2, 1fr);
        }
        input[type="checkbox"] + label:before {
          font-size: 14px !important;
          // display: inline-block;
          vertical-align: middle;
          min-height: 18px;
          min-width: 18px;
          @media (max-width: 575px) {
          min-height: 15px;
          min-width: 15px;
          height: 15px;
          width: 15px;
          }
        }
        input[type=checkbox]:checked + label:after{
          content: unset;
        }
        .reorder-dropdown-subheading {
          display: block;
          color: var(--gray-400);
          margin: 10px 0;
          font-weight: 600;
        }
      
        .catering-item-with-checkbox:last-child {
          padding: 0;
        }
        .catering-item-with-checkbox {
          @include flex-center;
          position: relative;
          padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
          @media (max-width: 575px) { 
          padding: 0 0 map-get($pixel, sm) * 1 + 2px 0;
          }
          label {
            @include flex-center;
            span {
              padding-left: 7px;
                @media screen and (max-width: 768px) {
                  font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (768 - 280)));
                }
            }
          }
          div {
            @include flex-center;
            input[type="checkbox"] {
              width: 15px;
              height: 15px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }

  }