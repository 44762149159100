@import "styles/mixins/index";

.ads-space-section {
  // background-color: var(--bg-light-300);
  padding: map-get($pixel, md) * 1 0;
  @include flex-center;
  justify-content: map-get($layout, center);

  @include breakpoint("max-sm") {
    display: none;
  }

  > h1 {
    @include heading-2;
    color: var(--black-text-color);
    opacity: 0.22;

    @include breakpoint("max-md") {
      @include text-center;
    }
  }

  .add-grid {
    display: flex;
    width: 100%;
    // grid-template-columns: auto auto;
    // gap: 0 60px;
    align-items: center;
    .add-grid-description {
      width: 60%;
      .html-parser {
        text-align: left;
      }
    }
    .add-grid-image {
      width: 40%;
      text-align: right;
      img {
        max-height: 105px;
      }
    }
  }
}

.ads-space-section-no-ads {
  background-color: var(--bg-light-300);
  padding: map-get($pixel, md) * 4 0;
  @include flex-center;
  justify-content: map-get($layout, center);

  @include breakpoint("max-sm") {
    display: none;
  }

  > h1 {
    @include heading-2;
    color: var(--black-text-color);
    opacity: 0.22;

    @include breakpoint("max-md") {
      @include text-center;
    }
  }
}
