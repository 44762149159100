@import "styles/mixins/index";
.search-coupon-listing-page-wrapper {
  padding: map-get($pixel, md) * 5 0;
  @include grid-layout;
  grid-template-columns: max(321px) minmax(0, 1fr);
  gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm) + 3px;
  @include breakpoint("max-sm") {
    padding: 0;
  }
  @include breakpoint("max-md") {
    display: block;
  }
  @include breakpoint("max-sm") {
    display: block;
  }
  .listing-page-wrapper-items {
    .coupon-card-theme2 {
      min-width: auto;
    }
  }
  .found-items {
    padding: 15px;
    p {
      span {
        color: var(--secondary-color);
        margin-bottom: 12px;
      }
    }
  }
}