@import "styles/mixins/index";
.product-preview-slider-theme2 {
  .selected-product {
    // margin: 0 0 map-get($pixel, md) * 3 0;
    // height: 680px;
    width: 82%;
    text-align: center;
    @include breakpoint("max-sm") {
      height: 320px;
    }
    @media screen and (max-width: 450px){
      height: 270px;
    }
    @media screen and (max-width: 650px){
      height: max-content;
    }
    @media screen and (max-width: 390px){
     width: 70%;
    }
    img {
      width: 100%;
      max-height: 390px;
      height: 390px;
      object-position: top;
      object-fit: contain;
      margin-left: -75px;
      @include breakpoint("max-md") {
        height: 289px;
        margin-left: 0;
      }
      @include breakpoint("max-sm") {
        height: 300px;
      }
      @media (max-width: 575px) {
        height: 200px; 
        max-height: 200px;
      }
    }
  }
  .child-images {
    width: 18%;
    position: relative;
    margin: auto;
    @media screen and (max-width: 650px){
      height: max-content;
    }
    @media screen and (max-width: 390px){
      width: 30%;
     }
    .slick-initialized .slick-slide {
      padding: 0;
      margin: 0.7px 0;
      text-align: center;
      /* margin: auto; */
    }
    .slick-list {
      text-align: center;
      background-color: #fff;
      @include breakpoint("max-md") {
        margin: 0 -20px;
      }
      @include breakpoint("max-sm") {
        margin: 0 -10px;
      }
      // .slick-track{
      //   height: 100% !important;
      //   transform: none !important;
      // }
    }
    .custom-arrow-design {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      @include flex-center;
      justify-content: map-get($layout, center);
      background-color: var(--bg-light-400);
      @include ease-in-out;
      z-index: 20;
      @include pointer;
      color: var(--gray-text-color);
      &:hover {
        background-color: var(--primary-color);
        color: var(--bg-white);
      }
    }
    .right-arrow-alignment {
      bottom: -40px;
      top: auto;
      margin: auto;
      transform: rotate(90deg) translateY(10px);
      left: 0;
      right: 0;
      @extend .custom-arrow-design;
      @media screen and (max-width: 767px) {
        transform: rotate(90deg) translateY(0px);
      }
    }
    .left-arrow-alignment {
      top: -30px;
      left: 0;
      right: 0;
      margin: auto;
      bottom: auto;
      transform: rotate(90deg) translateY(10px);
      @extend .custom-arrow-design;
      @media screen and (max-width: 767px) {
        transform: rotate(90deg) translateY(0px);
      }
    }
    div{
      line-height: 0;
    }
      .procut-child-image {
        overflow: hidden;
        background-color: var(--bg-color-white-theme2);
        border-radius: 5px;
        padding: 10px;
        border: 1px solid var(--bg-color-theme2);
        @include ease-in-out;
        @include pointer;
        @include flex-center;
        width: 90px;
        height: 86px;
        justify-content: map-get($layout, center);
        opacity: .5;
        transition: opacity .6s ease;
        @media (max-width: 575px) {
          width: 70px;
          height: 70px;
        }
        &.active {
          opacity: 1;
        }
        img {
          max-width: 89px;
          height: 100%;
          width: auto;
          margin: auto;
          // max-height: 112px;
          // height: 112px;
          // object-fit: cover;
          @include breakpoint("max-sm") {
            height: 80px;
            object-position: center;
          }
          @media (max-width: 575px) {
            height: 50px;
          max-width: 50px;
          }
        }
        &:hover {
          border: 1px solid var(--primary-color);
        }
      }
    .active {
      border: 1px solid var(--primary-color);
    }
  }
}