.mobile-sticky-bar {
    box-shadow: 0px 0px 10px #2222221f;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 25;
    transform: none;
    background-color: #fff;
    @media (min-width: 769px) {
        display: none;
    }
    .mobile-sticky-bar-item {
        display: flex;
        flex: 1 0 auto;
        padding: 18px 20px;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #eee;
        position: relative;
        @media (max-width: 575px) {
            padding: 15px 20px;
        }
        a {
            display: inline-block;
            height: 24px;
            @media (max-width: 575px) {
                height: 20px;
            }
            svg {
                height: 24px;
                width: 24px;
                @media (max-width: 575px) {
                    height: 20px;
                    width: 20px;
                }
            }
            &:hover {
                &::after {
                    content: attr(data-tooltip);
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    right: 0;
                    width: max-content;
                    margin: auto;
                    background-color: #ffffff;
                    box-shadow: 0px 0px 10px #2222221f;
                    color: #000;
                    text-transform: capitalize;
                    padding: 4px 10px;
                    border-radius: 4px;
                    white-space: nowrap;
                    display: block;
                    font-size: 14px;
                    z-index: 10;
                }
                &::before {
                    position: absolute;
                    content: "";
                    z-index: 10;
                    border-color: transparent;
                    border-style: solid;
                    top: 0;
                    left: 0;
                    filter: drop-shadow(0px 0px 10px #2222221f);
                    border-width: 0.4rem 0.4rem 0;
                    border-top-color: #f4f4f4;
                    display: block;
                    right: 0;
                    margin: auto;
                    width: max-content;
                }
            }
        }
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
        &.active {
            a {
                svg {
                    path {
                        fill: var(--theme2-primary-color);
                    }
                }
            }
        }
        &.weekly-ad {
            a {
                svg {
                    height: 18px;
                    width: 18px;
                }
            }
        }
        &.list {
            a {
                svg {
                    height: 22px;
                    width: 22px;
                }
            }
        }
    }
}