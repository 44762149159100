html,
body {
  padding: 0;
  margin: 0;
  //--text-font-family-theme2: "Poppins", sans-serif;
  font-family: var(--text-font-family), var(--default-font-family);
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

button {
  cursor: pointer;
}

.markercluster-map {
  position: sticky !important;
}

.leaflet-container {
  height: 700px;
  width: 100%;
}
.theme-2-font{
  font-family: var(--text-font-family) !important;
}
@media (max-width: 768px) {
body .uwy.userway_p5 .userway_buttons_wrapper {
  bottom: 60px !important;
}
}
.breadcrumb{
  background-color: var(--invert-primary-color);
    padding: 16px 50px;
    margin-bottom: 30px;
  }
  .breadcrumb ul li,
    .breadcrumb ul li i{
    
    color: #fff !important;
}
.breadcrumb ul li:first-child i, 
  .breadcrumb ul li:first-child{
  color: var(--primary-color) !important;
}
.breadcrumb ul li:first-child i{ 
    padding-right: 5px !important;
    vertical-align: middle;
}
.height-100vh{
  min-height: 80vh;
}