@import "styles/mixins/index";



  .catering-checkbox-group {
    padding: 0 0 12px 0;

    @include breakpoint("max-sm") {
      padding: 0 0 10px 0;
    }

    p {
      @include heading-4;
      cursor: pointer;
    border-bottom: 1px #afafaf solid;
      // margin: 0 0 map-get($pixel, md) 0;
      color: var(--black-5-light);
      font-size: .8em;
      @media (max-width: 767px) {
        font-size: calc(11px + (16 - 11) * ((100vw - 280px) / (767 - 280)));
    }
      span {
        color: var(--bg-danger);
      }
    }
    .check-box-wrapper{
      &.dropdown-show {
        display: block;
        max-height: unset;
        transition: 0.3s ease-in-out;
        z-index: unset;
      }
      &.dropdown-hide {
         display: none;
         transition: none;
         -webkit-transition: none;
         -moz-transition: none;
         -ms-transition: none;
         -o-transition: none;
       }
      }
      .arrow {
        float: right;
        margin-left: 10px;
        transition: transform 0.3s ease;
        display: inline-block;
        transform: rotate(0);
        img{
          @media screen and (max-width: 575px) {
            height: 14px;
            width: 14px;
            vertical-align: middle;
          }
        }
      }
      .arrow-up {
        transform: rotate(180deg);
      }
    .catering-checkbox-group-item-list {
        display: grid;
        grid-template-columns: auto;
        
        @include breakpoint("max-sm") {
            grid-template-columns: auto;
          }
          label{
            &::before{
              min-width: 18px;
              min-height: 18px;
              font-size: 11px !important;
            }
            &::after{
              font-size: 12px !important;
            }
          }
        .reorder-dropdown-subheading {
          display: block;
          color: var(--gray-400);
          margin: 10px 0;
          font-weight: 600;
        }
      
        .catering-item-with-checkbox:last-child {
          padding: 0;
        }
        .catering-item-with-checkbox {
          @include flex-center;
          position: relative;
          padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
          label {
            @include flex-center;
            span {
              padding-left: 7px;
            }
          }
          div {
            @include flex-center;
            input[type="checkbox"] {
              width: 15px;
              height: 15px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }

  }