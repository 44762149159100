@import "styles/mixins/index";
.discover-section-theme2 {
  padding: 0 0 40px 0;
  @media (max-width: 992px) {
    padding: 0 0 20px 0;
  }
  .discover{
    align-items: flex-start;
    background: #edf2ee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 45px;
    @media screen and (max-width: 1200px) {
      padding: 15px 20px;
    }
    @media screen and (max-width: 800px) {
    display: block;
    }
    h2{
      width: 70%;
      font-family: var(--text-font-family);
      color: #000;
      font-size: 28px;
      font-weight: 600;
      @media screen and (max-width: 1200px) {
        font-size: calc(14px + (25 - 14) * ((100vw - 280px) / (1200 - 280)));
      }
      @media screen and (max-width: 800px) {
       width: 100%;
        }
  }
  a{
    background: var(--bg-color-theme2);
    border-radius: 40px;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    padding: 10px 30px;
    transition: all 0.5s ease-in-out;
    &:hover{
      background-color: #000;
      color: #fff;
    }
    span{
      vertical-align: middle;
    }
  }
 }
}