@import "styles/mixins/index";

.product-preview-slider {
  .selected-product {
    border: 1px solid var(--border-color-10);
    border-radius: map-get($pixel, sm) * 0;
    padding: 78px 80px;
    margin: 0 0 map-get($pixel, md) * 3 0;
    height: 480px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(129, 129, 129, 0.16);
    border: 2px solid #ddd;
    border-radius: 5px;
    @include breakpoint("max-md") {
      padding: 40px;
    }
    @include breakpoint("max-sm") {
      height: 280px;
    }
   
    @media (max-width: 575px) {
      height: 200px;
      margin: 0 0 map-get($pixel, md) * 2 0;
    }

    img {
      width: 100%;
      max-height: 300px;
      height: 480px;
      object-position: top;
       object-fit: contain;

      @include breakpoint("max-md") {
        height: 289px;
      }

      @include breakpoint("max-sm") {
        height: 289px;
      }
      @media (max-width: 575px) {
        height: 130px;
       }
    }
  }

  .child-images {
    .slick-initialized .slick-slide {
      padding: 0 30px;

      @include breakpoint("max-md") {
        padding: 0 20px;
      }

      @include breakpoint("max-sm") {
        padding: 0 10px;
      }
    }

    .slick-list {
      margin: 0 -30px;

      @include breakpoint("max-md") {
        margin: 0 -20px;
      }

      @include breakpoint("max-sm") {
        margin: 0 -10px;
      }
    }

    .custom-arrow-design {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      @include flex-center;
      justify-content: map-get($layout, center);
      background-color: var(--bg-light-400);
      @include ease-in-out;
      z-index: 999;
      @include pointer;
      color: var(--gray-text-color);

      &:hover {
        background-color: var(--primary-color);
        color: var(--bg-white);
      }
    }

    .right-arrow-alignment {
      right: -15px;
      @extend .custom-arrow-design;
    }

    .left-arrow-alignment {
      left: -15px;
      @extend .custom-arrow-design;
    }

    .procut-child-image {
      background-color: var(--bg-light-300);
      border-radius: map-get($pixel, md) * 2;
      padding: map-get($pixel, md) * 2 + 3px map-get($pixel, md) * 4;
      border: 1px solid transparent;
      @include ease-in-out;
      @include pointer;
      @include flex-center;
      justify-content: map-get($layout, center);

      @include breakpoint("max-md") {
        padding: map-get($pixel, md) * 2;
      }

      img {
        max-width: 89px;
        width: 100%;
        max-height: 112px;
        height: 112px;
        object-fit: cover;

        @include breakpoint("max-sm") {
          height: 80px;
          object-position: center;
        }
      }

      &:hover {
        border: 1px solid var(--primary-color);
      }
    }
    .active {
      border: 1px solid var(--primary-color);
    }
  }
}
