@import "styles/mixins/index";

.provide-section {
  padding: map-get($pixel, md) * 3 0 8px 0;

  @include breakpoint("max-sm") {
    padding: 60px 0 0 0;
  }

  .provide-section-title {
    padding: 0 0 map-get($pixel, md) * 6 0;

    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 4 0;
    }

    h1 {
      @include heading-9-medium;
      color: var(--invert-secondary-color);
      margin: 0;
      @include text-center;

      @include breakpoint("max-sm") {
        @include heading-2;
      }
    }
  }

  .faq {
    margin: 0 0 27px 0;
    //border: 1px solid var(--secondary-color);
    padding: 10px;
    p{
      word-wrap: break-word;
    }
    h1 {
      text-align: center;
      color: var(--secondary-color);
      margin: 15px 0 7px 0;

    }

    h3 {
      margin: 12px 0 12px 0;
      color: var(--invert-secondary-color);
      font-size: 20px;
    }

    span {
      text-align: justify;
      line-height: 26px;
      padding: 5px;

      p {}
    }
  }

}