@import "styles/mixins/index";

.top-header {
  padding: map-get($pixel, md) - 3px 0;
  border-bottom: 1px solid var(--gray-border-color);
  @include breakpoint("max-md") {
    display: none;
  }
  .grid {
    @include flex-between;
    .need-help,
    .headline {
      p {
        color: var(--gray-text-color);
      }
      p > a {
        color: var(--secondary-color);
        @include pointer;
        @include ease-in-out;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .links-wrapper {
      @include flex-center;
      .auth-links {
        padding-left: map-get($pixel, sm) * 3 + 1px;
        ul li + li {
          border-right: map-get($pixel, null);
          padding-right: map-get($pixel, null);
        }
        a:last-child li {
          border: none;
        }
        ul {
          @include flex-center;
          a:first-child > li {
            padding-right: map-get($pixel, md);
          }
          li {
            padding-left: map-get($pixel, md);
            border-right: 1px solid var(--light-border-color);
            @include heading-text-sm-normal;
            text-transform: uppercase;
            @include pointer;
            color: var(--black-text-color);
            @include ease-in-out;
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
      .follow-us {
        @include flex-center;
        div > span {
          @include heading-text-sm;
          color: var(--black-3);
          display: block;
          padding-right: map-get($pixel, sm) * 3 + 1px;
        }
        div:last-child {
          @include flex-center;
          a:last-child {
            margin-right: 0px;
          }
          a {
            display: block;
            margin-right: map-get($pixel, sm) * 3 + 1px;
          }
          div {
            width: 35px;
            height: 35px;
            @include flex-center;
            justify-content: map-get($layout, center);
            @include pointer;
            border-radius: 50%;
            background-color: var(--secondary-color);
            @include ease-in-out;
            &:hover {
              box-shadow: (0px 0px 10px rgba(0, 0, 0, 0.2));
            }
            i {
              color: var(--text-white);
            }
          }
        }
      }
    }
  }
}
