@import "styles/mixins/index";

.html-parser {
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-bottom: 15px;
    @include text-4xl;
  }
  p,
  span,
  li {
    @include text-sm-normal;
  }
}
