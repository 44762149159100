@import "styles/mixins/index";

.download-app-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  @media (max-width: 575px) {
    display: none;
  }
  .download-app {
    display: none;
    @include breakpoint("max-sm") {
      display: flex;
      margin: 0 0 10px 0;
    }

    .secondary-button {
      padding: map-get($pixel, sm) * 2 + 1px;
      background-color: var(--secondary-color);
      color: var(--text-white);
      @include heading-text-sm;
      height: 40px;
      border: none;
      border-radius: map-get($pixel, sm) - 1px;
    }
  }
}
