.reward-view-main-wrap {
    .reward-view-box {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            display: block;
        }
        .reward-view-img {
            margin-right: 20px;
            @media (max-width: 992px) {
                text-align: center;
            }
            img {
                min-height: 150px;
                max-height: 150px;
                max-width: 150px;
            }
        }
        .details{
            .reward-view-title {
                margin: 10px 0 8px;
                font-size: 20px;
                font-weight: 500;
                line-height: normal;
                @media (max-width: 1200px) {
                    font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                }
                @media (max-width: 992px) {
                    margin-bottom: 10px;
                }
            }
            .view-reward-point {
                font-size: 14px;
                color: var(--primary-color);
                font-weight: 600;
            }
            .redeem-btn{
                margin-top: 15px;
                cursor: pointer;
                text-transform: capitalize;
                background: var(--reward-view-btn-bg-color);
                border-radius: 40px;
                color: var(--reward-view-btn-font-color);
                padding: 10px 20px;
                width: max-content;
                text-align: center;
            }
        }
    }
}