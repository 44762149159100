@import "styles/mixins/index";


.proceed-checkout {
    border: 1px solid var(--border-color-8);
    border-radius: calc(10px - 3px);
    .box-title {
        padding: map-get($pixel, sm ) * 3 map-get($pixel, md ) * 2 + map-get($pixel, sm ) + 1px;
        background-color: var(--cart-page-header-title-bg-color);
        color: var(--cart-page-header-title-font-color);
        border-radius: 7px 7px 0 0;
        > p {
            @include text;
            @include font-semi-bold;
            color: var(--black-5);           
        }
    }
    .box-body {
        .proceed-to-checkout {
            padding: map-get($pixel, sm ) * 2 + 3px map-get($pixel, md ) * 2 + 6px map-get($pixel, md ) * 3 - 2px map-get($pixel, md ) * 2 + 6px;
            button {
                @include heading-text-md-medium;
                color: var(--bg-white);
                background-color: var(--primary-color);
                padding: map-get($pixel, md ) * 2 map-get($pixel, sm ) * 3;
                border-radius: 5px;
                border: none;
                height: 62px;
                @include flex-center;
                justify-content: map-get($layout, center );
                width: 100%;
                span {
                    padding-right: map-get($pixel, sm ) * 2;
                }
            }

            .ebt-message{
                color: var( --bg-danger);
                margin-bottom: 12px;
            }
        }
        .all-text-alignment {
            padding: map-get($pixel, sm ) * 4 + 0px map-get($pixel, md ) * 2 + 6px;
            border-bottom: 1px solid var(--border-color-8);
            @include flex-between;
            p {
                @include heading-4;
                margin: 0;
                color: var(--gray-7);
            }
            span {
                @include heading-4;
                color: var(--black-3);
                display: block;
            }
            h2 {
                @include text-lg ;
                color: var(--primary-color);
                margin: 0;
                @include font-medium;
            }
        }
    }
    .html-parser{
        text-align: center;
        margin-bottom: 12px;

        .success{
            color:var(--primary-color)
        }
        .delivery-text{
            color: var(--gray-7);
        }
        .danger{
            color: var( --bg-danger);
        }
    }
    .disabled{
        opacity: 0.6;
        pointer-events: none;
    }
}