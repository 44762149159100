@import "styles/mixins/index";

.markercluster-map {
  height: 400px;
  width: 100%;
}

.tabs {
  padding: 20px 0 25px 0;
  display: flex;

  @include breakpoint("max-sm") {
    justify-content: center;
  }

  justify-content: flex-start;
}

.location-section {
  @include breakpoint("max-sm") {
    padding-bottom: 20px;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: 404px minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 8 + 4px;
    border: 1px solid #ececec;
    padding-left: 115px;
    padding-right: 55px;
    // align-items: center;
    border-radius: 7px;
    padding-top: 47px;
    padding-bottom: 47px;

    @include breakpoint("max-md") {
      padding: 0;
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
      border: 1px solid #ececec;
      padding: map-get($pixel, md) * 4;
      border-radius: 5px;
    }

    @include breakpoint("max-sm") {
      padding: 20px;
    }

    .grid-items:nth-child(1) {
      padding-left: 0;

      @include breakpoint("max-md") {
        padding: 0;
      }
    }

    .grid-items {
      .store-loc-details {
        float: right;
        padding: 20px 2px;

        &__button {
          margin-right: 5px;
          padding: 20px;
          color: #fff;
          border-radius: 4px;
          border: none;
          background-color: var(--primary-color);
          font-size: 22px;
          font-weight: 500;
        }
      }

      h2 {
        @include heading-9-medium;
        color: var(--invert-secondary-color);
        margin: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-sm") {
          @include heading-3;
        }
      }

      .time-text {
        padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 2 0;
        border-bottom: 1px solid var(--light-border-color);

        h3 {
          @include text-md-semi-bold;
          margin: 0;
          color: var(--primary-color);

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }

      a {
        @include text-md-semi-bold;
        color: var(--invert-secondary-color);
        display: block;
        line-height: 28px;
        @include pointer;
      }

      p {
        @include text-md-regular;
        color: var(--black-light-300);
        max-width: 389px;
        margin: 0 0 5px 0;
        line-height: 18px;
      }

      .map-image {
        img {
          max-width: 100%;
          height: auto;
          // object-fit: cover;

          // @include breakpoint("max-sm") {
          //   height: 180px;
          // }
        }
      }

      .store-service-section {
        margin-top: 15px;

        .store-service-header {
          border: 1px solid var(--light-border-color);
          padding: 10px;

          p {
            padding-bottom: 15px;
            border-bottom: 1px solid var(--light-border-color);
            font-weight: 500;
          }

          p:not(:first-child) {
            padding-top: 20px;
          }

          .store-service-link {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
              @include text;
              // color: var(--invert-secondary-color);
              color: rgb(1, 89, 184);
              display: inline-block;
              line-height: 28px;
              text-decoration: underline;
              @include pointer;
            }

            span {
              @include text;

              display: inline-block;
              line-height: 26px;
            }
          }
        }

        .store-section-body {
          border: 1px solid var(--light-border-color);
          padding: 10px;

          .child-text {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .child-text-item {
              width: 50%;
              padding: 0 5px;

              p {
                @include text;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
}