@import "styles/mixins/index";

.copyright-section {
  background-color: var(--bg-light);
  padding: map-get($pixel, md) 0;
  .powered-by-all-align-content {
    @include flex-between;
    @include breakpoint("max-sm") {
      display: block;
      padding: map-get($pixel, md) * 2 + 2px 0 map-get($pixel, md) 0;
    }
  }
  p {
    @include text-sm-normal;
    color: var(--black-text-color);
    margin: 0;
    @include breakpoint("max-sm") {
      @include text-center;
      margin: 0 0 map-get($pixel, sm) * 3 0;
    }
  }
  .powered-by-align {
    @include flex-center;
    @include breakpoint("max-sm") {
      display: block;
    }
    img {
      @include breakpoint("max-sm") {
        margin: 0 auto;
        @include flex-center;
        justify-content: map-get($layout, center);
      }
      max-width: 130px;
    }
    span {
      @include text-xs-normal;
      padding-right: 15px;
      color: var(--black-text-color);
      @include breakpoint("max-sm") {
        display: block;
        @include text-center;
      }
    }
  }
}
