$layout: (
  grid: grid,
  flex: flex,
  center: center,
  pointer: pointer,
  block: block,
  relative: relative,
  absolute: absolute,
  none: none,
  left: left,
  right: right,
  space-between: space-between,
  space-around: space-around,
  flex-end:  flex-end,
  end: end,
);

$pixel: (
  null: 0px,
  sm: 5px,
  md: 10px,
  lg: 15px,
  xl: 20px,
  w-full: 100%,
  h-full: 100%,
  h-screen: 100vh,
);

$normal-shadow: 0 0 15px #0000001a;
