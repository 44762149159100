@import "styles/mixins/index";

.loader-wrapper {
  height: 80vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .loadingspinner {
    pointer-events: none;
    width: 2.5em;
    height: 2.5em;
    border: 0.4em solid transparent;
    border-color: #eee;
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: loadingspin 1s linear infinite;
    -webkit-animation: loadingspin 1s linear infinite;
  }

  @keyframes loadingspin {
    100% {
      transform: rotate(360deg);
    }
  }
}
