.b2b-mainSection {
  .mission-container {
    width: 100%;
    padding: 3% 11%;
    text-align: center;
    max-width: 1440px;
    margin: 0 auto;
  }
  .mission-container h2 {
    font-weight: 600;
    line-height: 100%;
    display: inline-block;
    line-height: 115%;
    letter-spacing: 3.25px;
    text-transform: uppercase;
    color: #000;
    font-size: calc(18px + (50 - 18) * ((100vw - 280px) / (1440 - 280)));
    @media screen and (min-width: 1450px) {
      font-size: 56px;
    }
  }
  .nf-error-msg,
  .ninja-forms-req-symbol {
    color: #e80000;
  }
  .nf-field-element h3 {
    margin: 5px 0;
    color: #ed1c24;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 1024px) {
      font-size: calc(16px + (25 - 16) * ((100vw - 280px) / (1024 - 280)));
    }
  }
  .nf-form-layout {
    display: block;
    max-width: 1400px;
    margin: 0 auto;
    padding: 15px 10% 40px;
    @media (max-width: 575px) {
      padding: 15px 10% 40px;
    }
  }
  .nf-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
      display: block;
    }
    .nf-cell {
      display: flex;
      flex-direction: column;
      margin: 0px 20px 0px 0px;
      @media only screen and (max-width: 600px) {
        width: 100% !important;
      }
      .nf-field-container {
        clear: both;
        position: relative;
        margin-bottom: 15px;
        @media (max-width: 1024px) {
          margin-bottom: calc(10px + (15 - 10) * ((100vw - 280px) / (1024 - 280)));
        }
        .nf-field {
          .field-wrap {
            .nf-field-element {
              input {
                width: 100%;
              }
            }
            .nf-element {
              font-size: 20px;
              line-height: 24px;
              height: 40px;
              width: 406px;
              background-color: #fff;
    border: 1px #000 solid;
              margin: 0px 25px 0px 0px;
            }
            .nf-field-label {
              font-weight: 700;
              line-height: 100%;
              margin: 10px 0 6px 0;
              @media (max-width: 991px) {
                margin: 10px 0;
              }
              label {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 25px;
                line-height: 1.1;
                @media (max-width: 1024px) {
                  margin-bottom: calc(16px + (20 - 16) * ((100vw - 280px) / (1024 - 280)));
                }
              }
            }
          }
        }
      }
    }
  }
  .nf-row-store {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .nf-cell {
      display: flex;
      flex-direction: column;
      margin: 0px 20px 0px 0px;
      .nf-field-container {
        clear: both;
        position: relative;
        margin-bottom: 25px;
        .nf-field {
          .field-wrap {
            .nf-field-element {
              input {
                width: 100%;
                margin-bottom: 5px;
              }
            }
            .nf-element {
              font-size: 16px;
              line-height: 24px;
              height: 40px;
              width: 100%;
              margin: 0px 25px 0px 0px;
              @media (max-width: 1024px) {
                font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1024 - 280)));
              }
            }
            .nf-field-label {
              font-weight: 700;
              line-height: 100%;
              margin-bottom: 6px;
              label {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.2;
                display: inline-block;
                @media (max-width: 1024px) {
                  font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (1024 - 280)));
                }
                @media (max-width: 991px) {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .nf-row-submit {
    text-align: center;
    .nf-element-button {
      font-size: 17px;
      padding: 10px 30px;
      background-color: var(--primary-color);
      color: var(--bg-white);
      border-radius: 5px;
      border: none;
      outline: none;
      cursor: pointer;
      @media (max-width: 991px) {
        padding: 10px 15px;
        font-size: calc(16px + (25 - 16) * ((100vw - 280px) / (991 - 280)));
      }
    }
  }
}