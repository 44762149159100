@import "styles/mixins/index";

.city-item {
  .show-items {
    position: relative;
    @include breakpoint("max-sm") {
      width: 100%;
    }

    @include breakpoint("min-md") {
      width: unset;
    }

    button {
      font-size: 15px;
      line-height: 19px;
      font-family: var(--heading-font-family), var(--default-font-family);
      font-weight: 500;
      padding: 12px 17px;
      border: 1px solid var(--light-border-color);
      color: var(--black-5-light);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      font-weight: 400;
      width: 300px;
      position: relative;
      transition: 0.2s ease-in-out;

      @include breakpoint("max-sm") {
        width: 100%;
      }

      &:focus {
        border: 1px solid var(--primary-color);
      }

      span {
        padding: 0 map-get($pixel, sm) + 1px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
      }
    }

    .submenu-list {
      .disable-lable {
        font-size: 14px;
        line-height: 16px;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 400;
        color: var(--black-3);
        padding: 11px 15px;
        opacity: 0.6;
      }

      .submenu-item {
        padding: 11px 30px;
      }
    }

    .text-danger {
      color: var(--bg-danger);
    }

    .dropdown-show {
      max-height: 300px;
      z-index: 999999;
      overflow-y: auto;
      border: 1px solid var(--light-border-color);

      .list-menu {
        border: none;
      }
    }

    .dropdown-hidden {
      z-index: 999999;
    }
  }
}
