@import "styles/mixins/index";

.checkout-info-section {
  padding: 0 0 map-get($pixel, md) * 10 + 2px 0;

  @include breakpoint("max-sm") {
    padding: 0 0 map-get($pixel, md) * 5 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 639px;
    gap: 0 map-get($pixel, md) * 4 + 6px;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 25px;
    }

    .grid-items {
      @include breakpoint("max-md") {
        grid-column: span 12;
      }

      .ebt-checkout-information{
        display: flex;
        flex-direction: column;

        span{
          margin-bottom: 6px;
        }
      }
    }
  }
}
