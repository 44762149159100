@import "styles/mixins/index";
.order-section-theme2 {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  @include breakpoint("max-sm") {
    margin-top: 20px;
  }
  .order-page-title {
    border-bottom: 1px solid #ddd;
    padding: 20px;
    @include flex-between;
    @include breakpoint("max-sm") {
      padding: 15px;
    }
    h1 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      color: var(--invert-secondary-color);
      @include breakpoint("max-sm") {
        font-size: 14px;
      }
    }
  }
  .order-section-main-wrapper {
    padding: 20px;
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.096);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
    .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      width: calc(100% - 20px);
      position: relative;
      text-align: center;
      max-height: calc(100vh - 400px);
      overflow: hidden;
      overflow-y: auto;
      @media (min-width: 992px) {
        width: 500px;
      }
      &::-webkit-scrollbar {
        width: 7px;
        background-color: var(--light-border-color);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
      }
      .close-btn {
        background: transparent;
        border: none;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        left: auto;
        right: 5px;
        font-weight: 600;
        top: 5px;
      }
      .modal-body {
        h3 {
          text-align: left;
          margin-bottom: 10px;
        }
        .submit-btn {
          width: 100%;
          padding: 15px 10px;
          background-color: var(--primary-color);
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: 500;
          font-size: 17px;
          margin-top: 10px;
          &:hover {
            background-color: var(--secondary-color);
          }
        }
        .input-group {
          margin-bottom: 15px;
          &.model-group {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .group-label{
            width: 100%;
          }
          label {
            display: block;
            text-align: left;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 5px;
            @media (max-width: 992px) {
              font-size: 13px;
            }
          }
          input {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 5px;
          }
        }
        .picup-items {
          overflow-x: scroll;
          cursor: grab;
          padding-bottom: 10px;
          width: 100%;
          background-color: transparent;
          &::-webkit-scrollbar {
            height: 0;
            background-color: #e4e4e4;
          }
          &::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
          }
          label {
            text-align: left;
            text-transform: capitalize;
            display: block;
            font-weight: 500;
            margin-bottom: 5px;
          }
          .pickup-item-wrap {
            //   display: flex;
            //   gap: 15px;
            text-align: left;
            margin: 0 -7.5px;
            position: relative;
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
            transition: all 0.2s;
            will-change: transform;
            user-select: none;
            cursor: pointer;
            padding-bottom: 5px;
            &::-webkit-scrollbar {
              height: 4px;
              background-color: #e4e4e4;
            }
            &::-webkit-scrollbar-thumb {
              background-color: var(--primary-color);
            }
            &.active {
              background: rgba(255, 255, 255, 0.3);
              cursor: grabbing;
              cursor: -webkit-grabbing;
            }
          }
          .pickup-item-with-checkbox {
            margin: 5px 7.5px 0;
            background-color: #fff;
            border-radius: 50px;
            font-size: 16px;
            cursor: pointer;
            color: #000;
            width: max-content;
            transition: all 0.5s ease-in-out;
            display: inline-block;
            input[type=checkbox]:checked + label:after,
            input[type=checkbox]+label:before,
            input[type=checkbox]:checked+label:before {
              display: none;
            }
            .custom-label {
              line-height: 1;
              display: block;
              text-align: center;
              background-color: #fefffa;
              border: 2px solid #ddd;
              border-radius: 5px;
              box-shadow: 0 0 15px hsla(0, 0%, 51%, .16);
              cursor: pointer;
              height: 100%;
              overflow: hidden;
              padding: 15px;
              position: relative;
              transition: .3s ease-in-out;
              @media (max-width: 992px) {
                padding: 10px;
              }
              span {
                @include text-bold;
                color: var(--black-text-color);
                @include text-center;
                font-weight: 600;
                display: block;
                line-height: 1.1;
                margin-top: 12px;
                @media (max-width: 1024px) {
                  margin-top: 7px;
                  font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1024 - 280)));
                }
              }
            }
            div {
              @include flex-center;
              input[type="checkbox"] {
                width: 15px;
                height: 15px;
                margin: 0;
                padding: 0;
              }
            }
            .custom-label {
              img {
                width: 100%;
                border-radius: 5px;
                max-width: 80px;
                max-height: 80px;
                height: 160px;
                // object-fit: cover;
                @media (max-width: 992px) {
                  max-width: 50px;
                  max-height: 50px;
                  height: 100px;
                }
              }
            }
            .custom-checkbox:checked+.custom-label {
              border-color: var(--primary-color);
            }
          }
        }
        .color-items {
          overflow-x: scroll;
          cursor: grab;
          padding-bottom: 10px;
          width: 100%;
          background-color: transparent;
          &::-webkit-scrollbar {
            height: 0;
            background-color: #e4e4e4;
          }
          &::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
          }
          label {
            text-align: left;
            text-transform: capitalize;
            display: block;
            font-weight: 500;
            margin-bottom: 5px;
          }
          .color-item-wrap {
            //   display: flex;
            //   gap: 15px;
            text-align: left;
            margin: 0 -7.5px;
            position: relative;
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
            transition: all 0.2s;
            will-change: transform;
            user-select: none;
            cursor: pointer;
            padding-bottom: 5px;
            &::-webkit-scrollbar {
              height: 4px;
              background-color: #e4e4e4;
            }
            &::-webkit-scrollbar-thumb {
              background-color: var(--primary-color);
            }
            &.active {
              background: rgba(255, 255, 255, 0.3);
              cursor: grabbing;
              cursor: -webkit-grabbing;
            }
            .color-item-with-checkbox {
              margin: 5px 7.5px 0;
              background-color: #fff;
              border-radius: 50px;
              font-size: 16px;
              cursor: pointer;
              color: #000;
              width: max-content;
              transition: all 0.5s ease-in-out;
              display: inline-block;
              input[type=checkbox]+label:before,
              input[type=checkbox]:checked+label:after,
              input[type=checkbox]:checked+label:before {
                display: none;
              }
              .custom-label {
                line-height: 1;
                display: block;
                cursor: pointer;
                height: 100%;
                overflow: hidden;
                // padding: ;
                position: relative;
                text-align: left;
                text-transform: capitalize;
                font-weight: 500;
                margin-bottom: 5px;
                transition: .3s ease-in-out;
                span {
                  @include text-bold;
                  color: var(--black-text-color);
                  @include text-center;
                  font-weight: 600;
                  display: block;
                  line-height: 1;
                  margin-top: 12px;
                  @media (max-width: 1024px) {
                    margin-top: 7px;
                    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1024 - 280)));
                  }
                }
              }
              div {
                @include flex-center;
                input[type="checkbox"] {
                  width: 15px;
                  height: 15px;
                  margin: 0;
                  padding: 0;
                }
              }
              .custom-label {
                span.color-box {
                  width: 100%;
                  border-radius: 50px;
                  min-width: 60px;
                  min-height: 60px;
                  margin: 0;
                  border: 2px solid #c7c7c7;
                  @media (max-width: 992px) {
                    min-width: 40px;
                    min-height: 40px;
                  }
                }
              }
              .custom-checkbox:checked+.custom-label span {
                border-color: var(--primary-color);
              }
            }
          }
        }
      }
    }
    .order-section-wrapper {
      .order-section-items {
        margin: 0 0 15px;
        &:last-child {
          margin: 0;
        }
        .order-item-main {
          border: 1px solid #e6e6e6;
          border-radius: 10px;
          padding: 15px;
          .order-items-main-wrap {
            @include flex-between;
            @media (max-width: 767px) {
              display: block;
            }
            .order-items {
              @media (min-width: 767px) {
                width: calc(100% - 300px);
              }
              .order-title {
                color: #000;
                font-size: 15px;
                font-weight: 600;
                margin: 0 0 10px;
              }
              .order-product-image {
                display: flex;
                margin: 0 0 10px;
                .order-image {
                  align-items: center;
                  background-color: #fff;
                  border: 1px solid #ddd;
                  border-radius: 50px;
                  display: inline-block;
                  display: inline-flex;
                  height: 40px;
                  justify-content: center;
                  margin: 0 -15px 0 0;
                  overflow: hidden;
                  width: 40px;
                  img {
                    max-width: 30px;
                  }
                }
              }
              .order-number-id {
                .order-num-title {
                  color: #999;
                  font-size: 15px;
                  font-weight: 500;
                  margin: 0 0 10px;
                  span {
                    color: #000;
                  }
                }
              }
              .order-product-month {
                color: #4d4d4d;
                font-size: 15px;
                font-weight: 500;
                span {
                  margin: 0 0 0 15px;
                  padding: 0 0 0 20px;
                  position: relative;
                  &::before {
                    background-color: #4d4d4d;
                    border-radius: 50px;
                    content: "";
                    height: 5px;
                    left: 0;
                    position: absolute;
                    top: 8px;
                    width: 5px;
                  }
                }
              }
            }
            .order-price-section {
              @include flex-between;
              @media (min-width: 767px) {
                width: 300px;
              }
              @media (max-width: 767px) {
                margin-top: 15px;
              }
              span {
                color: #007bff;
              }
              .price {
                color: #000;
                font-size: 15px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .pickup-btn-wrap {
      text-align: right;
    }
    .pickup-btn {
      margin: auto;
      background: var(--bg-color-theme2);
      border: none;
      text-transform: capitalize;
      border-radius: 50px;
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      padding: 14px 25px;
      margin-top: 10px;
      text-align: center;
      color: #fff;
      transition: all 0.5s ease-in-out;
      position: relative;
      @media (max-width: 767px) {
        padding: 9px 15px;
      }
      .pickup-ico {
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
}