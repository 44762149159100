            .spend-reward-main-wrap {
                .spend-reward-box {
                    border-radius: 10px;
                    padding: 20px;
                    background-color: #fff;
                    box-shadow: 0px 5px 10px 10px rgba(23, 83, 116, 0.051);
                    @media (max-width: 575px) {
                        padding: 15px;
                    }
                    @media (min-width: 575px) {
                        display: flex;
                        align-items: center;
                    }
                    .spend-reward-img {
                        min-height: 180px;
                        margin: 0 15px 0 0;
                        img {
                            max-height: 200px;
                        }
                        @media (max-width: 575px) {
                            width: 100%;
                            margin: 0 0 15px 0;
                        }
                    }
                    .spend-reward-content-wrapper {
                        @media (max-width: 575px) {
                            width: 100%;
                        }
                        color: #000;
                        .spend-reward-title-wrap {
                            margin: 15px 0;
                            .spend-reward-title {
                                font-size: 20px;
                                font-weight: 500;
                                line-height: normal;
                                @media (max-width: 1200px) {
                                    font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                                }
                                @media (max-width: 992px) {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                        .spend-reward-range-bar {
                            width: 60%;
                            progress{
                                &::-moz-progress-bar { background: red; }
                                @media (min-width: 575px) {
                                    width: 60%;
                                }
                            }
                            margin-bottom: 30px;
                            .progress-bar {
                                height: 30px;
                                overflow: hidden;
                                line-height: 0;
                                text-align: center;
                                font-size: .75rem;
                                background-color: #e9ecef;
                                border-radius: 50px;
                                position: relative;
                                .bar-text {
                                    line-height: 1.7;
                                    font-size: 18px;
                                }
                                .progress-bar-line {
                                    background-color: var(--theme2-secondary-color);
                                    border-radius: 50px;
                                    padding: 14px 12px;
                                    width: max-content;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: auto;
                                    span {
                                        color: #FFF;
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                    .spend-reward-btn {
                        cursor: pointer;
                        text-transform: capitalize;
                        background: var(--theme2-secondary-color);
                        border-radius: 40px;
                        color: #fff;
                        padding: 10px 20px;
                        width: max-content;
                        text-align: center;
                    }
                }
            }