@import "styles/mixins/index";
.download-checkout-invoice-wrapper {
  .oreder-details-title-list {
    @include flex-between;
    padding: 20px;
    border-bottom: 1px #e6e6e6 solid;
    @include breakpoint("max-sm") {
      padding: 15px;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      gap: 10px;
    }
    .order-details-page-title {
      @include flex-between;
      @media (max-width: 650px) {
        flex-wrap: wrap;
        gap: 5px;
      }
      h1 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: var(--invert-secondary-color);
        @include breakpoint("max-sm") {
          font-size: 14px;
        }
      }
      span {
        position: relative;
        color: #4d4d4d;
        font-size: 13px;
        font-weight: 500;
        margin: 0 0 0 15px;
        padding: 0 0 0 20px;
        position: relative;
        @media (max-width: 575px) {
          margin: 0;
          padding: 0;
        }
        &::before {
          background-color: #4d4d4d;
          border-radius: 50px;
          content: "";
          height: 5px;
          left: 0;
          position: absolute;
          top: 8px;
          width: 5px;
          @media (max-width: 575px) {
            display: none;
          }
        }
      }
    }
  }
}
.download-invoice-body-wrapper {
  .order-card {
    padding: 20px;
    .order-item-list-wrapper {
      margin: 0 0 30px;
      @media (min-width: 767px) {
        display: flex;
      }
      .order-item-list {
        @media (min-width: 767px) {
          margin: 0;
          width: 65%;
        }
        .order-item-list-items {
          border: 1px solid #e6e6e6;
          border-radius: 10px;
          // display: flex;
          // margin: 0 0 15px 0;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          // @media (max-width: 992px) {
          //   display: block;
          // }
        }
        .order-address-items {
          .order-address-book {
            .address-title {
              border-bottom: 1px solid #ddd;
              padding: 15px;
              color: #999;
              font-size: 13px;
              text-transform: uppercase;
              font-weight: 500;
              letter-spacing: .4px;
            }
            .address-description {
              padding: 15px;
              .info {
                color: #8b8b8b;
                font-size: 14px;
                line-height: 24px;
                margin: 0 0 20px;
              }
              .user-name {
                color: #000;
                display: block;
                font-weight: 500;
                margin: 0 0 20px;
                font-size: 14px;
                text-transform: capitalize;
              }
              .user-email-box {
                span {
                  color: #000;
                  display: block;
                  font-weight: 500;
                }
              }
              .user-phone-box {
                span {
                  color: #000;
                  display: block;
                  font-weight: 500;
                }
              }
              .user-time-box {
                span {
                  color: #000;
                  display: block;
                  font-weight: 500;
                }
              }
              .user-address-box {
                span {
                  color: #000;
                  display: block;
                  font-weight: 500;
                }
              }
              .user-picup-date {
                span {
                  color: #000;
                  display: block;
                  font-weight: 500;
                }
              }
              .user-status-box {
                span {
                  color: #000;
                  display: block;
                  font-weight: 500;
                }
              }
              .cancel-order-button {
                padding: 10px;
                margin-top: 10px;
                background: red;
                border: none;
                color: #fff;
                font-weight: 600;
                border-radius: 5px;
              }
            }
          }
        }
      }
      .order-steps {
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        margin: 15px 0 0 0;
        @media (min-width: 767px) {
          margin: 0 0 0 15px;
          width: 35%;
        }
        .payment-title {
          border-bottom: 1px solid #ddd;
          display: flex;
          padding: 15px;
          font-size: 13px;
          justify-content: space-between;
          .order-id {
            span {
              color: #1a1a1a;
              text-transform: capitalize;
            }
          }
          .method-title {
            margin: 0;
          }
        }
        .order-pyment-steps {
          padding: 15px;
          p {
            border-bottom: 1px solid #ddd;
            color: #565656;
            font-size: 14px;
            font-weight: 400;
            margin: 0 0 15px;
            padding: 0 0 15px;
            span {
              color: #000;
              float: right;
              font-weight: 500;
            }
          }
          h6 {
            color: #565656;
            font-size: 15px;
            font-weight: 500;
            margin: 0;
            span {
              color: #2c742f;
              float: right;
              font-weight: 600;
            }
          }
        }
      }
    }
    .product-table {
      table {
        width: 100%;
        border-spacing: 0;
        th {
          background-color: #f2f2f2;
          color: #606060;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: .5px;
          padding: 10px 15px;
          text-transform: uppercase;
        }
        td {
          border-bottom: 1px solid #ddd;
          color: #000;
          font-weight: 500;
          padding: 10px 20px;
          text-align: center;
          @media (max-width: 767px) {
            padding: 10px;
          }
          .product-image-wrap {
            display: flex;
            align-items: center;
            .product-image {
              width: 50px;
              display: block;
              img {
                max-width: 100%;
              }
            }
            .product-title {
              text-align: left;
              color: #000;
              font-size: 14px;
              font-weight: 500;
              margin: 0 0 0 15px;
            }
          }
        }
      }
    }
  }
  .order-detail {
    padding: 20px;
    .header-title {
      font-size: 18px;
      font-weight: 600;
      text-transform: capitalize;
      margin: 0;
      color: #000;
      margin-bottom: 15px;
      @include breakpoint("max-sm") {
        font-size: 16px;
      }
    }
    table {
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      margin-bottom: 30px;
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      @media (max-width: 992px) {
        display: block;
      }
      &::-webkit-scrollbar {
        height: 4px;
        background-color: var(--add-to-card-bg-color);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
      }
      tr {
        border-spacing: 0;
        th {
          &:last-child {
            border-right: none;
          }
          border-right: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
          text-align: left;
        }
      }
      tbody {
        tr {
          td {
            border-right: 1px solid #e6e6e6;
            word-break: break-all;
            position: relative;
            &:last-child {
              border-right: none;
            }
            @media (max-width: 575px) {
              font-size: 14px;
            }
            @media (max-width: 600px) {
              padding: 10px;
              padding-left: 50%;
              td {
                border-right: none;
              }
              &:nth-child(1)::before {
                content: "Method";
              }
              &:nth-child(2)::before {
                content: "Status";
              }
              &:nth-child(3)::before {
                content: "Transaction ID";
              }
              &:nth-child(4)::before {
                content: "Authorized amount";
              }
              &:nth-child(5)::before {
                content: "Final amount";
              }
              &:nth-child(6)::before {
                content: "Message";
              }
              &:last-child {
                border-right: none;
              }
              &::before {
                position: absolute;
                top: 6px;
                left: 6px;
                word-break: break-all;
                width: 45%;
                padding-right: 10px;
                white-space: normal;
                font-weight: 600;
                line-height: 1;
                color: #000000;
                @media (max-width: 575px) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.download-invoice-table-wrapper {
  margin-top: 20px;
  .product-table {
    padding: 20px;
    table {
      width: 100%;
      border-spacing: 0;
      th {
        background-color: #f2f2f2;
        color: #606060;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .5px;
        padding: 10px 15px;
        text-transform: uppercase;
      }
      td {
        border-bottom: 1px solid #ddd;
        color: #000;
        font-weight: 500;
        padding: 10px 20px;
        text-align: center;
        @media (max-width: 767px) {
          padding: 10px;
        }
        .product-image-wrap {
          display: flex;
          align-items: center;
          .product-image {
            width: 50px;
            display: block;
            img {
              max-width: 100%;
            }
          }
          .product-title {
            text-align: left;
            color: #000;
            font-size: 14px;
            font-weight: 500;
            margin: 0 0 0 15px;
          }
        }
      }
    }
  }
}
.download-important-note-wrapper {
  @include flex-between;
  padding: 0 25px;
  font-size: 14px;
  .follow-us-wrapper {
    justify-content: flex-end;
    @include flex-center;
    .social-media-wrapper {
      @include flex-center;
      div:first-child {
        margin-right: 10px;
      }
      div {
        width: 35px;
        height: 35px;
        color: var(--text-white);
        @include flex-center;
        @include pointer;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--secondary-color);
      }
    }
    span {
      // @include heading-text-sm;
      color: var(--black-3);
      padding-right: 15px;
      display: block;
    }
  }
  .download-thank-text {
    h6 {
      // @include heading-7-medium;
      font-size: 19px;
      line-height: 22px;
      color: var(--invert-secondary-color);
      margin: 0 0 20px 0;
    }
  }
  .notes-text {
    h5 {
      // @include text-lg;
      // @include font-medium;
      color: var(--invert-secondary-color);
      margin: 0 0 15px 0;
    }
    ul {
      display: block;
      margin: 0;
      padding: 0;
      padding-left: 1.25rem;
      li {
        // @include text;
        color: #565656;
        margin: 0 0 10px 0;
      }
    }
  }
}
.checkout-card {
  .item-details {
    display: flex;
    justify-content: space-between;
  }
  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 27px 18px 18px 18px;
  // min-height: 490px;
  min-height: auto;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;
  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }
  &:hover {
    border: 1px solid var(--primary-color);
    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
  @keyframes check {
    100% {
      stroke-dashoffset: 4.5px;
    }
  }
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  * {
    box-sizing: inherit;
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }
  body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.checkout-items-card {
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
  .invoice-wrapper {
    .checkout-invoice-information {
      border: 1px solid var(--border-color-8);
      border-radius: calc(10px - 2px);
      margin: 0 0 map-get($pixel, md) * 3 0;
    }
    .checkout-invoice-text-alignment {
      border-bottom: none;
      padding: 15px;
      border-bottom: 1px solid var(--border-color-8);
      display: flex;
      justify-content: space-between;
      div {
        p {
          @include heading-4;
          line-height: 22px;
          @include flex-center;
          color: #3d3d3d;
          @include breakpoint("max-sm") {
            @include heading-text-sm;
          }
        }
        h2 {
          font-size: 20px;
          color: var(--primary-color);
        }
      }
    }
  }
}