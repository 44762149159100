.top-sticky-header {
  background-color: #fff;
  z-index: 99;
  position: relative;
  &.sticky {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    border-radius: 0;
    animation: slideupbottom 0.40s ease-out;
    @media (max-width: 1350px) {
      .main-header {
        .grid {
          .grid-items:nth-child(2) {
            .stickey-logo {
              @media (max-width: 1350px) {
                display: none;
              }
            }
          }
        }
      }
      .main-header {
        .grid {
          .grid-items:nth-child(2) {
            div,
            >div {
              display: block;
            }
            @media (max-width: 768px) {
              .icon-wrapper {
                display: none;
              }
            }
          }
        }
      }
      .home-product-search{
        display: none;
      }
    }
  }
}
@keyframes slideupbottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}