.list-menu {
  border: 1px solid var(--light-border-color);
  background-color: var(--bg-white);
  border-radius: 4px;
  z-index: 99999;
  ul {
    margin: 0;
    padding: 0;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background: #999;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--invert-primary-color);
      width: 10px;
    }
    li.selected {
      background-color: var(--primary-light-color);
      color: var(--primary-color);
    }
    li {
      @include heading-text-sm-normal;
      color: var(--black-3);
      padding: map-get($pixel, sm) * 2 + 1px map-get($pixel, sm) * 3;
      @include pointer;
      @include ease-in-out;
      &:hover {
        background-color: var(--primary-light-color);
        color: var(--primary-color);
      }
    }
    .active {
      background-color: var(--primary-light-color);
      color: var(--primary-color);
    }
  }
}

.dropdown-hidden {
  max-height: 0px;
  overflow: hidden;
  @include ease-in-out;
}

.dropdown-show {
  max-height: 100vh;
  overflow: hidden;
  @include ease-in-out;
  z-index: 999;
}

.dropdown-align {
  position: absolute;
  width: 100%;
}
