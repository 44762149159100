@import "styles/mixins/index";

.catering-page {
  .breadcrumb-detailed-section {
    padding: 16px 50px;
  }
  .catering-page-wrapper {
    padding: map-get($pixel, md) * 1-4 0;
    @include grid-layout;
    grid-template-columns: max(321px) minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm) + 3px;

    @include breakpoint("max-sm") {
      padding: 0;
    }

    @include breakpoint("max-md") {
      display: block;
    }

    @include breakpoint("max-sm") {
      display: block;
    }
  }
}
