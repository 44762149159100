@import "styles/mixins/index";
.product-info-theme2 {
  padding: 0 0 30px 0;
  @include breakpoint("max-sm") {
    padding: 20px 0;
  }
  @media screen and (max-width: 480px) {
    padding: 20px 0;
  }
  .info-box {
    // margin: 0 70px;
    @include breakpoint("max-md") {
      margin: 0;
    }
    @include breakpoint("max-sm") {
      padding: 0;
      margin: 0;
      border: none;
    }
    .info-details {
      p {
        font-size: 14px;
        color: #808080;
        font-family: var(--text-font-family);
        @include breakpoint("max-sm") {
          @include text-sm-normal;
        }
      }
      .html-parser {
        text-align: left;
      }
      p:last-child {
        margin: 0;
      }
      .Nutrition-heading {
        margin: 0 0 map-get($pixel, md) * 2 0;
        padding-left: 15px;
      }
      .nutrition-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include breakpoint("max-md") {
          display: block;
        }
        .nutrition-image {
          width: 33%;
          overflow: hidden;
          @include breakpoint("max-sm") {
            width: 100%;
          }
          @include breakpoint("max-md") {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        .nutrition-information {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
    .info-tab {
      padding: 0 0 map-get($pixel, md) * 2 0;
      // @include breakpoint("max-sm") {
      //   @include flex-around;
      // }
      button:last-child {
        margin: 0;
      }
      button {
        margin-right: map-get($pixel, sm) * 4;
        border: none;
        background-color: transparent;
        color: #000;
        @include text-md;
        padding: 0;
        font-size: 28px;
        font-weight: 500;
        @include breakpoint("max-sm") {
          @include text-sm-semi-bold;
        }
      }
      .active {
        border-bottom: 2px solid #000;
        padding-bottom: 8px;
        // box-shadow: (0px 0px 10px rgba(126, 126, 126, 0.16));
        color: #000;
      }
    }
  }
  .description-main-wrap {
    h2 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 800;
      margin-bottom: 16px;
      color: #414142;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
    .mb-20 {
      margin-bottom: 20px;
    }
    .box-header {
      margin-bottom: 20px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 24px 0;
      border-bottom: 1px solid rgb(215, 215, 216);
      transition: all 0.5s ease-in-out;
      @media screen and (max-width: 768px) {
        padding: 0 0 calc(10px + (24 - 10) * ((100vw - 280px) / (768 - 280))) 0;
        margin-bottom: calc(10px + (24 - 10) * ((100vw - 280px) / (768 - 280)));
      }
      &:hover {
        h2 {
          color: #000;
        }
      }
    }
    .description-container {
      margin: 0 auto;
      .description-text {
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        &.collapsed {
          max-height: 125px;
        }
        &.expanded {
          max-height: none;
        }
      }
      .view-item-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        -webkit-box-pack: center;
        margin: 15px 0;
        .toggle-btn {
          background-color: #fff;
          color: #000;
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          text-align: center;
          font-weight: 600;
          border-radius: 40px;
          font-size: 16px;
          width: max-content;
          display: inline-block;
          svg {
            vertical-align: middle;
          }
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
    .button-container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
    .highlight-btn {
      min-width: fit-content;
      border: 1px solid #d7d7d8;
      background-color: #fff;
      padding: 10px 20px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 9px 9px -9px;
      cursor: pointer;
      border-radius: 30px;
      font-weight: 600;
      font-size: 16px;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: none;
        border-color: var(--primary-color);
      }
      &:focus {
        outline: none;
        border-color: var(--primary-color);
      }
      &:active {
        background-color: var(--primary-color);
        transform: scale(0.98);
      }
    }
    .nutrition-ingredients-section {
      h2 {
        margin-bottom: 0;
      }
    
      .grid-box {
        border-radius: 10px;
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(2, 1fr);
        @media screen and (max-width: 450px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      .grid-main-box {
        border-radius: 24px;
        background-color: #f5f5f5;
        padding: 16px 32px;
        @media (max-width: 992px) {
         padding: 15px;
        }
        &.notice-warning {
          margin-top: 15px;
        }
        .grid-title {
          margin-bottom: 8px;
        }
          img{
            max-width: 100%;
            height: auto;
          }
        .fact-table {
          width: 100%;
          border-collapse: collapse;
          p {
            font-size: 14px;
          }
          .main-title {
            text-align: left;
            font-size: 14px;
            font-weight: 700;
          }
          .calories-title,
          .size-title {
            display: flex;
            border-bottom: 10px solid #000;
            align-items: center;
            justify-content: space-between;
          }
          .calories-title {
            border-bottom: 5px solid #000;
          }
        }
        .bold {
          font-weight: bold;
        }
        .semi-text {
          font-size: 12px;
          font-weight: 800;
          padding: 1px 0;
          border-bottom: 1px #000 solid;
          display: block;
        }
        .semi-title {
          font-size: 16px;
          color: #414142;
          font-weight: 700;
          span {
            font-size: 12px;
            font-weight: 500;
            margin-left: 3px;
          }
        }
        .sub-semi-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px #000 solid;
          font-size: 16px;
          color: #414142;
          font-weight: 500;
          .main-title {
            font-weight: 400;
            font-size: 16px;
            padding-left: 15px;
            &.p-0 {
              padding: 0;
            }
            &.p-30 {
              padding-left: 30px
            }
          }
          span {
            font-size: 16px;
            font-weight: 400;
            margin-left: 3px;
          }
        }
        .flex-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px #000 solid;
        }
        .bb-10 {
          border-bottom: 10px #000 solid;
        }
        .bb-6 {
          border-bottom: 6px #000 solid;
        }
        .value {
          font-size: 16px;
          font-weight: 700;
        }
        .text-right {
          text-align: right;
        }
        .calories {
          font-size: 24px;
          @media (max-width: 992px) {
           font-size: 18px;
          }
        }
        .footnote {
          font-size: 12px;
          line-height: 1;
          text-align: left;
          display: block;
          margin-top: 10px;
        }
      }
    }
    .desc-information-section {
      h2 {
        margin-bottom: 0;
      }
      .info-desc {
        border-radius: 24px;
        background-color: #f5f5f5;
        padding: 32px 20px;
        @media (max-width: 992px) {
          padding: 15px;
        }
        .info-link {
          margin-top: 8px;
          display: block;
          text-decoration: underline;
          font-size: 18px;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }
      &.notice-warning {
        margin-top: 15px;
      }
    }
  }
}