.detail-reward-main-wrap {
  .detail-reward-box {
    background-color: #edf2ee;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    @media (max-width: 575px) {
      padding: 15px;
    }
    .detail-reward-img {
      background: #fff;
      border-radius: 10px;
      margin: 0;
      min-height: 180px;
      padding: 10px;
      text-align: center;
      width: 30%;
      margin: auto;
      img {
        max-height: 200px;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 15px 0;
      }
    }
    .detail-reward-content-wrapper {
      text-align: center;
      @media (max-width: 1200px) {
        margin-left: 0 calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
      }
      @media (max-width: 767px) {
        margin: 0;
      }
      @media (max-width: 992px) {
        width: 100%;
    }
      color: #000;
      .detail-reward-subtitle {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        line-height: 1.2;
      }
      .detail-reward-title-btn {
        .detail-reward-title {
          margin: 15px auto 0;
          font-size: 18px;
          font-weight: 500;
          width: 80%;
          line-height: normal;
          @media (max-width: 1200px) {
            font-size: calc(
              12px + (18 - 12) * ((100vw - 280px) / (1200 - 280))
            );
          }
          @media (max-width: 992px) {
            margin-top: 10px;
            width: 100%;
          }
        }
      }
      .success-msg {
        position: fixed;
        // top: 20px;
        // right: 20px;
        background-color: red; /* Green background */
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        // z-index: 1000;
        // opacity: 0;
        transition: opacity 0.5s ease-in-out;
        right: 15rem;
      }
      @keyframes slideIn {
        from {
          right: -100%;
        }
        to {
          right: 15rem;
        }
      }
      .success-message.show {
        opacity: 1;
        animation: slideIn 3s forwards;
      }
    }
    .detail-reward-range-bar {
      .detail-reward-point {
        font-size: 40px;
        color: var(--primary-color);
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .reward-btn {
      cursor: pointer;
      text-transform: capitalize;
      background: var(--reward-redeem-btn-bg-color);
      border-radius: 40px;
      color: var(--reward-redeem-btn-font-color);
      padding: 10px 20px;
      width: max-content;
      text-align: center;
      &.redeem-btn {
        margin-left: 10px;
      }
    }
  }
}
.reward-details-wrap {
  margin-bottom: 40px;
  @media (max-width: 575px) {
    margin-bottom: 20px;
  }
}
