@import "styles/mixins/index";

.weekly-section-wrapper-theme2 {
  .display-none {
    display: none;
  }

  padding: 0 0 40px 0;

  .grid {
    @include grid-layout;
    grid-template-columns: repeat(16, 1fr);
    gap: 0 30px;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(12, 1fr);
      gap: 0 20px;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
    }

    .grid-items {
      grid-column: span 4;

      @include breakpoint("max-md") {
        grid-column: span 6;
      }

      @include breakpoint("max-sm") {
        grid-column: span 12;
      }

      .weekly-box {
        border-radius: 6px;
        border: 1px solid var(--border-color-8);
        background: #f9f9f9;
        border: 1px solid #f9f9f9;
        border-radius: 12px;
        margin: 0 0 25px;
        padding: 10px 20px 20px;
        position: relative;

        @media screen and (max-width: 992px) {
          padding: 10px 12px;
        }

        .weekly-image {
          img {
            width: 100%;
            object-fit: cover;
          }
        }

        p {
          @include text-md;
          @include font-semi-bold;
          color: var(--primary-color);
          font-size: 12px;
          font-weight: 500;
          margin: 18px 0 0;
          text-align: left;

          @include breakpoint("max-md") {
            margin: 10px 0 0 0;
          }
        }

        .weekly-title-align {
          @include breakpoint("max-md") {
            display: block;
          }

          button {
            border-radius: 0 12px 0 12px;
            display: inline-flex;
            font-size: 16px;
            font-weight: 500;
            padding: 5px 15px;
            position: absolute;
            right: 0;
            top: 0;
            border: none;
            background-color: var(--secondary-color);
            color: var(--bg-white);
          }
        }
      }

      .box-title {
        h1 {
          color: #000;
          font-size: 18px;
          font-weight: 600;
          margin: 5px 0 10px;
          max-width: 70%;

          @media screen and (max-width: 768px) {
            font-size: 14px;
            max-width: 60%;
          }
        }
      }
    }
  }
}