@import "styles/mixins/index";

.ads-space-section-department-theme2 {
  padding: 0;
  border-radius: 10px;
  margin: 15px;
  @include flex-center;
  justify-content: map-get($layout, center);
background-color: var(--bg-light-300);
  
  @include breakpoint("max-sm") {
    // display: none;
    padding: 0;
  }
  img {
    max-width: 100%;
  }

  > h1 {
    @include heading-2;
    color: var(--black-text-color);
    opacity: 0.22;

    @include breakpoint("max-md") {
      @include text-center;
    }
  }

  .ads-space-section-no-ads {
    background-color: var(--bg-light-300);
    padding: map-get($pixel, md) * 4 0;
    @include flex-center;
    justify-content: map-get($layout, center);

    @include breakpoint("max-sm") {
      display: none;
    }

    > h1 {
      @include heading-2;
      color: var(--black-text-color);
      opacity: 0.22;

      @include breakpoint("max-md") {
        @include text-center;
      }
    }
  }
}
