@import "styles/mixins/index";
.col-text-detail {
  height: 30rem;
  //  font-family: var(--heading-font-family), var(--default-font-family);
}
.grid-flex-detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  flex-direction: row;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: space-around;
  padding: 20px 3px;
  @include breakpoint("max-md") {
    height: 760px !important;
    display: -webkit-flex !important;
    -webkit-flex-direction: column !important;
    flex-direction: column !important;
  }
  .location-img-responsive {
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }
}
.col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  @include breakpoint("max-md") {
    order: vertical !important;
  }
}
.col-left {
  -webkit-box-ordinal-group: -1;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
  @include breakpoint("max-md") {
    -webkit-box-ordinal-group: 0 !important;
    -webkit-order: 0 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
}
.col-text-detail {
  display: flex;
  justify-content: center;
}
.Align-item {
  width: 80%;
  @include breakpoint("max-md") {
    width: 100% !important;
  }
  h1 {
    padding-bottom: 16px;
    color: var(--gray-3);
    font-family: var(--heading-font-family);
    @include breakpoint("max-md") {
      padding: 16px;
    }
  }
  p {
    line-height: 36px;
    font-size: 18px;
    text-align: justify;
    color: var(--gray-3);
    @include breakpoint("max-md") {
      padding: 1em !important;
    }
  }
}
.col-image {
  height: 634px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
}
.store-video {
  height: 580px;
  width: 100%;
  &__container {
    padding: 15px 100px;
    height: 92%;
    width: 70%;
    margin: 0 auto;
    img {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      width: 100%;
    }
  }
}
@media (max-width: 640px) {
  .location-section .grid .grid-items .store-loc-details {
    padding: 22px 29px !important;
  }
  .grid-flex-detail {
    height: 760px !important;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
  }
  .location-img-responsive {
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }
  .col {
    order: vertical;
  }
  .col-left {
    -webkit-box-ordinal-group: 0;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .col-text-detail div p {
    padding: 1em;
  }
  .col-text-detail {
    display: block;
  }
  .Align-item {
    width: 90%;
    p {
      font-size: 14px !important;
      line-height: 28px !important;
      overflow: hidden;
    }
    h1 {
      padding: 60px 16px 6px !important;
    }
  }
  .location-section-theme2 .grid .grid-items .store-loc-details__button {
    font-size: 14px !important;
    padding: 12px 13px !important;
    // margin-bottom: 7px;
  }
}