.mainSection {
    .mission-container {
        width: 100%;
        padding: 3% 10%;
        text-align: center;
        max-width: 1440px;
        margin: 0 auto;
    }

    .mission-container h2 {
        font-weight: 600;
        line-height: 100%;
        font-size: 4.3vw;
        line-height: 115%;
        letter-spacing: 3.25px;
        text-transform: uppercase;
        color: #000;
        font-size: calc(18px + (50 - 18) * ((100vw - 280px) / (1440 - 280)));

        @media screen and (min-width: 1450px) {
            font-size: 56px;
        }
    }

    .mission-container p {
        font-size: 20px;
        line-height: 30px;
        margin: 0;
        padding: 10px;
        text-align: center;
        line-height: 1.1;

        @media (max-width: 1024px) {
            font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1024 - 280)));
        }

        @media (max-width: 991px) {
            padding: 10px 0;
        }
    }

    .mission-container-Error p {

        margin: 0;
        padding: 10px;
        text-align: center;
        line-height: 1.1;

        @media (max-width: 1024px) {
            font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1024 - 280)));
        }

        @media (max-width: 991px) {
            padding: 10px 0;
        }
    }

    .nf-form-fields-required {
        text-align: right;

        @media (max-width: 1024px) {
            font-size: calc(13px + (16 - 13) * ((100vw - 280px) / (1024 - 280)));
        }
    }

    .nf-error-msg,
    .ninja-forms-req-symbol {
        color: #e80000;
    }

    .form-div {
        display: block;
        max-width: 1400px;
        margin: 0 auto;
        padding: 30px 10% 40px;

        @media (max-width: 575px) {
            padding: 15px 10% 40px;
        }

        .nf-field-label {
            margin-bottom: 6px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;

            @media (max-width: 992px) {
                margin-bottom: 5px;
            }

            span {
                font-size: 20px;
                font-weight: 500;

                @media (max-width: 1024px) {
                    font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (1024 - 280)));
                }
            }
        }

        .nf-field {
            font-size: 24px;
            margin-top: 25px;

            @media (max-width: 1024px) {
                margin-top: calc(15px + (20 - 15) * ((100vw - 280px) / (1024 - 280)));
            }
        }

        .nf-field-element {
            h2 {
                padding: 0;
                margin: 10px 0 5px;
                font-size: 25px;

                @media (max-width: 1024px) {
                    font-size: calc(16px + (25 - 16) * ((100vw - 280px) / (1024 - 280)));
                }

                @media (max-width: 1024px) {
                    padding: 0;
                }
            }

            p {

                font-size: 16px;
                line-height: 1.3;
                font-weight: 400;

                @media (max-width: 1024px) {
                    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1024 - 280)));
                }
            }

            label {
                color: #000000;
                font-size: 16px;
                line-height: 1;

                @media (max-width: 1024px) {
                    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1024 - 280)));
                }
            }

            [type=radio] {

                &:checked+label:after,
                &:not(:checked)+label:after {
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            .nf-element {
                width: 100%;
                text-rendering: optimizeLegibility;
                font-weight: 400;
                line-height: 100%;
                padding: 7px;
                border: 1px solid #dfdfdf;
                background: #fff;
                resize: none;
                font-size: 16px;
            }

            .nf-element-textarea {
                width: 100%;
                height: 200px;
                text-rendering: optimizeLegibility;
                font-weight: 400;
                line-height: 100%;
                padding: 7px;
                border: 1px solid #dfdfdf;
                background: #fff;
                font-size: 16px;
            }
        }

        .nf-field-element {
            input {
                font-size: 17px;
                padding: 10px 30px;
                background-color: var(--primary-color);
                // color: var(--bg-white);
                border-radius: 5px;
                border: none;
                outline: none;
            }

            .err-msg {
                color: #e80000;
            }
        }

        .consent-checkbox {
            font-size: 13px;
            margin-bottom: 25px;

            @media (max-width: 1024px) {
                margin-bottom: calc(10px + (20 - 10) * ((100vw - 280px) / (1024 - 280)));
            }

            .err-msg {
                color: #e80000;
                font-size: 16px;
            }

            .notification-desc {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .checkboxalim {
                width: 5px;
                height: 5px;
            }

            input[type=checkbox]+label {
                display: flex;
                align-items: center;
                cursor: pointer;

                .desc {
                    line-height: 1;

                    @media (max-width: 991px) {
                        align-items: start;
                        padding-left: 10px;
                    }
                }

                span {
                    font-size: 18px;

                    @media (min-width: 991px) {
                        padding-left: 10px;
                    }
                }
            }

            @media (max-width: 575px) {
                input[type=checkbox] {
                    &+label {
                        &:before {
                            width: 30px;
                            height: 16px;
                        }
                    }
                }
            }

            a {
                text-decoration: none;
                color: #007bff;
                text-decoration: underline;
                margin-left: 5px;
            }
        }
    }
}