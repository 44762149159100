@import "styles/mixins/index";

.download-coupon-cart-items-table {
  .download-coupon-cart-section-align {
    margin-left: 30px;
    .download-coupon-cart-title {
      padding: map-get($pixel, md) * 2 0 0 0;
      h1 {
        @include heading-9-medium;
        color: var(--black-text-color);
        margin: 0 0 map-get($pixel, md) * 2 - 1px 0;
      }
    }
  
    .download-coupon-page-grid-align {
      @include flex-between;
      width: calc(100% - 547px);
      margin: 0 0 map-get($pixel, md) * 2 - 1px 0;
    }
  }
  
  .download-coupon-cart-table {
    width: 100%;
    border-collapse: collapse;
  }
  .download-coupon-cart-table {
    .title {
      width: 40%;
      text-align: left;
    }
  }
  
  .download-coupon-cart-table th {
    padding: 12px 15px;
    border-bottom: 1px solid var(--primary-light-color);
    border-top: 1px solid var(--primary-light-color);
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
  }
  
  .download-coupon-cart-table td {
    padding: 8px 15px;
    text-align: center;
    font-size: 16px;
  }
  
  .download-coupon-cart-table th {
    background-color: var(--primary-light-color);
    color: var(--black-3);
  }
  
  .download-coupon-cart-table tr {
    border-bottom: 1px solid var(--border-color-8);
    border-left: 1px solid var(--border-color-8);
    border-right: 1px solid var(--border-color-8);
  }
  
  .download-coupon-cart-table img {
    width: 100%;
    max-width: 130px;
    height: 64px;
    object-fit: contain;
  }
  
  .download-coupon-cart-table .remove-icon {
    i {
      color: var(--gray-text-color);
      @include pointer;
      margin: 0 29px 0 0;
    }
  }
}





