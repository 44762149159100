.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include flex-center;
  justify-content: map-get($layout, center);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.modal {
  width: 592px;
  border-radius: 8px;
  background-color: var(--bg-white);
  @include breakpoint("max-sm") {
    width: calc(100% - 20px);
  }
}
.modal-lg {
  width: 680px;
  border-radius: 8px;
  background-color: var(--bg-white);
  @include breakpoint("max-sm") {
    width: calc(100% - 20px);
  }
}
