@import "styles/mixins/index";

.recipe-product-preview-section {
  padding: map-get($pixel, md) * 7 + 7px 0 map-get($pixel, md) * 8 + 8px 0;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 4 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: max(520px) minmax(0, 1fr) 202px;
    gap: 40px;
    padding: 0 70px;

    @include breakpoint("max-md") {
      display: flex;
      gap: 40px;
      padding: 0;
      grid-template-columns: none;
      padding: 0;
    }

    @include breakpoint("max-sm") {
      display: block;
      grid-template-columns: none;
    }

    .grid-items:nth-child(2) {
      // padding: map-get($pixel, md) * 6 0 0 0;
    }

    .grid-items {
      @include breakpoint("max-md") {
        width: 50%;
      }

      @include breakpoint("max-sm") {
        width: 100%;
      }

      h1 {
        @include heading-9-medium;
        margin: 0 0 map-get($pixel, md) * 2 - 1px 0;
        color: var(--black-text-color);
        max-width: 655px;

        @include breakpoint("max-md") {
          @include heading-7;
          @include font-medium;
        }
      }
    

      .final-price {
        h2 {
          color: var(--black-5-light);
          font-weight: 500;
        }
      }

      .department-code {
        @include flex-center;
        padding: 12px 0 0 0;

        div {
          padding-right: map-get($pixel, md) * 4;
        }

        div {
          p {
            @include text-sm-normal;
            color: var(--black-5-light);

            span {
              color: var(--primary-color);
            }
          }
        }
      }
      .restricted {
        p {
          color: var(--bg-danger);
        }
      }
   
      .add-product-option {
        @include flex-center;
        padding: 12px 0 0 0;
        @include breakpoint("max-sm") {
          display: flex;
          flex-wrap: wrap;
        }
        .button-disable {
          pointer-events: none;
          opacity: 0.5;
        }

        .link-button {
          margin-left: map-get($pixel, md);

          @include breakpoint("max-md") {
            padding: map-get($pixel, md) * 2 0 0 0;
            margin-left: 0;
          }

          @include breakpoint("max-sm") {
            padding: map-get($pixel, md) * 2 - map-get($pixel, md) * 2 0 0 0;
            margin-left: 0;
          }

          button {
            width: 137px;
            height: 50px;
            @include flex-center;
            justify-content: map-get($layout, center);
            border-radius: 5px;
            background-color: transparent;
            border: 1px solid #ccc;

            i {
              color: #ccc;
            }
          }

          .fa-solid {
            color: red;
          }
        }

        .add-to-cart {
          @include breakpoint("max-sm") {
            padding-bottom: map-get($pixel, md);
          }

          button {
            background-color: var(--primary-color);
            height: 50px;
            padding: 10px 25px;
            border-radius: 5px;
            @include flex-center;
            justify-content: map-get($layout, center);
            border: none;
            @include text-sm-normal;
            @include font-semi-bold;
            color: var(--bg-white);
            min-width: 155px;

            &:disabled {
              cursor: default;
              opacity: 0.5;
            }

            span {
              padding-left: map-get($pixel, md);
            }
          }
        }
      }

    
      .other-price-details {
        padding-top: 12px;
        font-size: 14px;
        p {
          padding-bottom: 5px;
        }

        p:first-child & p:last-child {
          padding: 0 0 map-get($pixel, md) * 2 0;
        }
      }
      .final-price {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-md") {
          display: block;
        }

        .price {
          padding-right: map-get($pixel, md) * 3 + 4px;

          h2 {
            @include text-5xl;
            color: var(--primary-color);
            margin: 0;
          }
        }

        .discount-price {
          p {
            @include text-sm-semi-bold;
            color: #fcbb0a;
            margin: 0;
          }

          span {
            @include text-4xl;
            display: block;
            color: var(--gray-8);
          }
        }
      }

      .rating-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 + 3px 0;

        .star-alignment {
          @include flex-center;
          padding-right: map-get($pixel, sm) * 3 - 1px;

          i:last-child {
            color: #c2c2c2;
            margin: 0;
          }

          i {
            color: #f9c945;
            @include pointer;
            margin-right: map-get($pixel, sm);
          }
        }
      }

      .summary-text{
        .html-parser{
          text-align: left;
        }
      }
      .see-more{
        @include text-sm-semi-bold;
        color: #fcbb0a;
        margin: 0;
        cursor: pointer;
      }
     .cal{
      margin-top: 12px;
     }
     .diets-wrapper{
      padding: 25px;
      border: 1px solid var(--border-color-10);
      display: flex;
      flex-direction: column;
      .diet-item{
        display: flex;
        align-items: center;
        margin-bottom: 4px;;
        img{
          width: 30px;
          height: 30px;
          margin-right: 4px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
}
       
      }
     }
    }
  }
}