@import "styles/mixins/index";

.ads-space-section-weeklyAdd-right-side-wrapper {
  width: 20%;
  margin-top: 84px;
  @include breakpoint("max-md") {
    width: 100%;
    margin-top: 0px;
  }
  @include breakpoint("max-sm") {
    width: 100%;
    margin-top: 0px;
  }
  .weekly-ad-right-side-special-promotion {
    // padding-bottom: 10px;
    // border-bottom: 2px solid var(--border-color-2);
    margin-bottom: 13px;
    display: inline-block;
  }
}
.ads-space-section-weeklyAdd-right-side {
  width: 100%;
  // background-color: var(--bg-light-300);
  // padding: 0 map-get($pixel, md) * 5 + 4px;
  height: 978px;
  overflow-y: auto;
  position: relative;

  @include breakpoint("max-md") {
    height: 480px;
    width: 100%;
    display: grid;
    grid-template-columns: 46% 46%;
    gap: 20px;
  }
  @include breakpoint("max-sm") {
    width: 100%;
    height: 336px;
    margin: 12px auto;
    display: grid;
    grid-template-columns: 100%;
  }
  .weekly-ad-coupon-card {
    margin-bottom: 20px;

    @include breakpoint("max-sm") {
     display: flex;
     justify-content: center
    }
  }

  // @include flex-center;
  // justify-content: map-get($layout, center);

  // @include breakpoint("max-sm") {
  //   display: block;
  // }

  > h6 {
    @include heading-6;
    text-align: center;
    color: var(--black-text-color);
    opacity: 0.22;

    @include breakpoint("max-md") {
      @include text-center;
    }
  }
}
