@import "styles/mixins/index";

.blog-section {
  padding: map-get($pixel, md) * 10 0 8px 0;
  @include breakpoint("max-sm") {
    padding: 60px 0 0 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: map-get($pixel, md) * 4;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .grid-items {
      border: 1px solid var(--border-color-9);
      background-color: var(--bg-white);
      border-radius: 7px;
      padding: 50px 50px 30px 50px;
      &:hover {
        border: 1px solid var(--primary-color);
        @include breakpoint("max-sm") {
          // display: none;
          border-bottom: 1px solid var(--primary-color);
        }
      }
      @include breakpoint("max-md") {
        padding: 30px;
      }
      .department {
        width: 60px;
        padding: 5px 0px;
        text-align: center;
        background-color: var(--gray-2);
        margin-bottom: 10px;
      }
      .blog-detail {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      h2 {
        @include heading-3xl;
        color: var(--invert-secondary-color);
        margin: 0 0 map-get($pixel, md) + 5px 0;
        @include breakpoint("max-md") {
          @include heading-3;
        }
        color: var(--primary-color);
      }
      p {
        @include text;
        font-size: 17px;
        line-height: 25px;
        color: var(--dark-gray-300);
        @include breakpoint("max-md") {
          @include text-sm;
          line-height: 22px;
        }
      }
      .icon-center-align {
        @include flex-center;
        justify-content: map-get($layout, center);
        padding: 0 0 map-get($pixel, md) * 3 0;
        img {
          width: 150px;
          height: 200px;
          object-fit: cover;
        }
      }
      .read-more {
        margin-top: 35px;
        button {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          background-color: var(--primary-light-color);
          color: var(--primary-color);
          @include text-xs-semi-bold;
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;
          &:hover:not([disabled]) {
            background-color: var(--primary-color);
            color: var(--bg-white);
          }
          span {
            padding-left: 4px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
