@import "styles/mixins/index";
.focus-department {
  margin-bottom: 40px;
  @media (max-width: 992px) {
    margin-bottom: 20px;
  }
  .focus-department-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 350px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .focus-department-inner {
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      @media (max-width: 767px) {
        border-radius: 10px;
      }
      .disabled-anchor {
        cursor: no-drop;
        pointer-events: none;
      }
      .focus-dep-img {
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
          transition: all 0.5s ease-in-out;
        }
      }
      .focus-department-details {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        z-index: 1;
        bottom: 0px;
        text-align: center;
        padding: 16px;
        background: rgba(84, 84, 84, 0.34);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        @media (max-width: 1200px) {
          padding: calc(10px + (14 - 10) * ((100vw - 280px) / (1200 - 280)));
        }
        .item-title {
          color: #fff;
          font-weight: 600;
          font-size: 22px;
          text-transform: capitalize;
          cursor: pointer;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.1;
          @media (max-width: 1200px) {
            font-size: calc(
              12px + (18 - 12) * ((100vw - 280px) / (1200 - 280))
            );
          }
        }
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
