@import "styles/mixins/index";

.coupon-cart-items-table {
  .coupon-cart-footer {
    @include flex-between;
    padding: map-get($pixel, md) * 2 + 2px 0 0 0;

    @include breakpoint("max-sm") {
     flex-direction: column;
     }
    button {
      padding: 11px 12px;
      border: none;
      border-radius: 7px;
      border: none;
      @include flex-center;
      @include heading-text-sm;
    }

    .continue-shopping {
      button {
        background-color: var(--primary-color);
        color: var(--bg-white);

        span {
          padding-left: 5px;
        }
      }

      @include breakpoint("max-sm") {
       margin-bottom: 12px;
      }
    }

    .download-button {
      @include flex-center;
      justify-content: center;
    
      button {
        background-color: var(--secondary-color);
        padding: 12px;
        color: var(--text-white);
        border-radius: 4px;
        border: none;
        @include heading-text-sm;
        span {
          padding-left: 7px;
        }
      }
    }
  }
}

.coupon-cart-table {
  width: 100%;
  border-collapse: collapse;
}
.coupon-cart-table {
  .title {
    width: 40%;
    text-align: left;
  }
}

.coupon-cart-table th {
  padding: 12px 15px;
  border-bottom: 1px solid var(--primary-light-color);
  border-top: 1px solid var(--primary-light-color);
  text-align: center;
  font-size: 16px;
  margin: 0 auto;
}

.coupon-cart-table td {
  padding: 12px 15px;
  text-align: center;
  font-size: 16px;


}

.coupon-cart-table th {
  background-color: var(--primary-light-color);
  color: var(--black-3);
}

.coupon-cart-table tr {
  border-bottom: 1px solid var(--border-color-8);
  border-left: 1px solid var(--border-color-8);
  border-right: 1px solid var(--border-color-8);
}

.coupon-cart-table img {
  width: 100%;
  max-width: 130px;
  height: 130px;
  object-fit: contain;
}

.coupon-cart-table .remove-icon {
  i {
    color: var(--gray-text-color);
    @include pointer;
    margin: 0 29px 0 0;
  }
}

/*responsive*/

@media (max-width: 600px) {
  .coupon-cart-table thead {
    display: none;
  }

  .coupon-cart-table,
  .coupon-cart-table tbody,
  .coupon-cart-table tr,
  .coupon-cart-table td {
    display: block;
    width: 100% !important;
  }

  .coupon-cart-table tr {
    margin-bottom: 15px;
    border: 1px solid var(--primary-light-color);
  }

  .coupon-cart-table td {
    padding-left: 50%;
    text-align: left;
    position: relative;
    border: 1px solid var(--border-color-8);
  }

  .coupon-cart-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }

  .coupon-cart-table img {
    width: 100%;
    max-width: 160px;
    object-fit: contain;
  }
}
