@import "styles/mixins/index";

.coupon-list-wrapper {
  padding: map-get($pixel, md) * 2 0 0 0;
  margin-bottom: 25px;
  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 2 + 4px 0 0 0;
  }
  .slick-slide {
    padding: 0 18px;
    @include breakpoint("max-sm") {
      padding: 0 30px;
    }
  }
  .slick-list {
    margin: 0 -18px;
    @include breakpoint("max-sm") {
      margin: 0;
    }
  }
  .left-arrow-alignment {
    position: absolute;
    right: 42px;
    top: -63px;
    @include breakpoint("max-sm") {
      top: 50%;
      transform: translateY(-50%);
      left: 13px;
      z-index: 9999;
    }
  }
  .right-arrow-alignment {
    position: absolute;
    right: 0;
    top: -63px;
    @include breakpoint("max-sm") {
      top: 50%;
      transform: translateY(-50%);
      right: 13px;
      z-index: 9999;
    }
    svg {
      transform: rotate(178deg);
    }
  }
  .custom-arrow-design {
    width: 32px;
    border-radius: 50%;
    background-color: var(--bg-light-400);
    @include pointer;
    height: 32px;
    @include flex-center;
    justify-content: map-get($layout, center);
    @include ease-in-out;
    color: var(--gray-text-color);
    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-white);
    }
  }
}
