@import "styles/mixins/index";

.center-align-image {
  text-align: center;
  width: 100%;
  padding: 1% 0;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

