@use "sass:map";

$break-point: (
  base: 0px,
  sm: 767px,
  md: 1350px,
  xl: 1850px,
  lg: 1280px,
);

@mixin breakpoint($point) {
  @if str-index($point, "max-") == 1 {
    @media screen and (max-width: map.get($break-point, str-slice($point, 5))) {
      @content;
    }
  } @else {
    @media screen and (min-width: map.get($break-point, $point)) {
      @content;
    }
  }
}
