@import "styles/mixins/index";

.weekly-section-wrapper {
  padding: map-get($pixel, md) * 4 0;

  @include breakpoint("max-sm") {
    padding: 40px 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: repeat(12, 1fr);
    gap: 47px 40px;

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .grid-items {
      grid-column: span 4;

      @include breakpoint("max-md") {
        grid-column: span 6;
      }

      @include breakpoint("max-sm") {
        grid-column: span 12;
      }

      .weekly-box {
        border-radius: 6px;
        border: 1px solid var(--border-color-8);

        .weekly-image {
          padding: 0 13px 22px 13px;

          img {
            width: 100%;
            // height: 361px;
            object-fit: cover;
          }
        }

        .weekly-title-align {
          @include flex-between;
          padding: 0 0 11px 0;

          @include breakpoint("max-md") {
            display: block;
          }

          p {
            @include text-md;
            @include font-semi-bold;
            color: var(--primary-color);
            padding-right: 20px;
            text-align: end;

            @include breakpoint("max-sm") {
              margin: 10px 0 0 0;
              text-align: right;
            }
          }

          button {
            padding: 8px 28px;
            border-radius: 6px 0 6px 0;
            background-color: var(--secondary-color);
            @include text-semi-bold;
            color: var(--bg-white);
            border: none;
          }
        }
      }

      .box-title {
        h1 {
          @include heading-2;
          color: var(--invert-secondary-color);
          margin: 0 0 map-get($pixel, md) - 1px 0;

          @include breakpoint("max-sm") {
            @include heading-3;
          }
        }
      }
    }
  }
}
