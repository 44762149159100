@import "styles/mixins/index";

.custom-list-card {
  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  padding: 27px 18px 18px 18px;
  // min-height: 490px;
  min-height: auto;
  max-height: 264px;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;
  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
    margin-bottom: 18px;
  }
  @media only screen and (max-width: 450px) {
    padding: 15px 10px;
  }
  &:hover {
    border: 1px solid var(--primary-color);
    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
  .remove-icon {
    align-self: end;
    position: absolute;
    i {
      color: var(--gray-text-color);
      @include pointer;
    }
  }
  .coupon-used-time-ribbon {
    position: absolute;
    right: 0;
    top: 0;
    @include breakpoint("max-sm") {
      top: 4px;
    }
    .coupon-used-time {
      background-color: var(--secondary-color);
      width: fit-content;
      padding: 17px 6px 16px 7px;
      margin: 2px 2px 15px -18px;
      position: relative;
      @include breakpoint("max-sm") {
        margin: 0 0 10px -2px;
      }
      p {
        color: var(--bg-white);
        @include heading-text-sm-normal;
        @include breakpoint("max-md") {
          font-size: 12px;
          line-height: 16px;
        }
        span {
          @include font-medium;
        }
      }
      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        bottom: -8px;
        right: 8px;
        border-width: 14px 12px 14px 0;
        transform: rotate(90deg);
        position: absolute;
        content: "";

        border-color: transparent var(--bg-white) transparent transparent;
      }
    }
  }

  .list-card-image {
    @include flex-center;
    justify-content: map-get($layout, center);
    @include breakpoint("max-sm") {
      padding: 16px;
    }
    img {
      width: 137px;
      height: 137px;
      object-fit: contain;
      @include breakpoint("max-sm") {
        // min-width: 92px;
        // max-width: 92px;
        // max-height: 116px;
        // min-height: 116px;
        width: 100%;
      }
    }
  }
  .list-card-details {
    padding: map-get($pixel, md) * 2 + 4px 0 0 0;
    @include breakpoint("max-sm") {
      padding: 0;
    }
    .active-text{
      p {
        color: var(--primary-color)
        }
    }
    .checkbox-label {
      display: inline-flex;
      align-items: center;
      p {
        position: relative;
        padding: left 100px;
      }
    }
    .checkbox {
      --border-default: #bbbbc1;
      --border-hover: #9898a3;
      --active: #6E7BF2;
      --active-tick: #ffffff;
      display: block;
      width: 18px;
      height: 18px;
      cursor: pointer;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      svg {
          display: block;
          position: absolute;
      }
      input {
          display: block;
          outline: none;
          border: none;
          padding: 0;
          margin: 0;
          -webkit-appearance: none;
          width: 18px;
          height: 18px;
          border-radius: 36% / 36%;
          box-shadow: inset 0 0 0 1.5px var(--border, var(--border-default));
          background: var(--background, transparent);
          transition: background .25s linear, box-shadow .25s linear;
          & + svg {
              width: 21px;
              height: 18px;
              left: 0;
              top: 0;
              color: var(--active);
              .tick {
                  stroke-dasharray: 20;
                  stroke-dashoffset: var(--stroke-dashoffset, 20);
                  transition: stroke-dashoffset .2s;
                  &.mask { 
                      stroke: var(--active-tick);
                  }
              }
              & + svg {
                  width: 11px;
                  height: 11px;
                  fill: none;
                  stroke: var(--active);
                  stroke-width: 1.25;
                  stroke-linecap: round;
                  top: -6px;
                  right: -10px;
                  stroke-dasharray: 4.5px;
                  stroke-dashoffset: 13.5px;
                  pointer-events: none;
                  animation: var(--animation, none) .2s ease .175s;
              }
          }
          &:checked {
              --background: var(--active);
              --border: var(--active);
              & + svg {
                  --stroke-dashoffset: 0;
                  & + svg {
                      --animation: check;
                  }
              }
          }
      }
      &:hover {
          input {
              &:not(:checked) {
                  --border: var(--border-hover);
              }
          }
      }
  }
  
  @keyframes check {
      100% {
          stroke-dashoffset: 4.5px;
      }
  }
  
  html {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
  }
  
  * {
      box-sizing: inherit;
      &:before,
      &:after {
          box-sizing: inherit;
      }
  }
  
  body {
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }
    
    a {
      @include heading-4;
      color: var(--black-text-color);
      display: block;
      line-height: 22px;
      @include ease-in-out;
      margin: 0 0 map-get($pixel, sm) + 3px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        color: var(--secondary-color);
      }
    }
    h3 {
      @include text-sm;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      display: block;
      color: var(--color);
    }
  }
}
