.filter {
    margin: 15px 0;
    text-align: right;
    span {
        width: max-content;
        display: inline-block;
        background-color: #fff;
        border-radius: 50px;
        color: #000;
        border: 1px #e4e4e4 solid;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        padding: 10px 20px;
        text-transform: capitalize;
        cursor: pointer;
        i {
            margin-right: 10px;
        }
    }
}
.weekly-ad-items {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 991px) {
        margin: 0 -12px;
    }
    .product-items {
        @media (min-width: 991px) {
            flex: 0 0 auto;
            width: 75%;
            padding: 0 12px;
        }
        img {
            max-width: 100%;
            height: auto;
            cursor: pointer;
            @media screen and (min-width: 991px) {
                width: 100%;
            }
        }
        .bg {
            background-repeat: repeat-y;
            border: 20px #eee solid;
            padding: 15px;
            @media (max-width: 575px) {
                border-width: 8px;
                padding: 7px;
            }
        }
        .weeky-ad-section-wrapper {
            padding-top: 32%;
            gap: 5px;
            display: flex;
            // height: 100vh; 
            align-items: start;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 10px;
            @media (max-width: 992px) {
                grid-template-columns: repeat(auto-fit, 3, 1fr);
            }
            @media (max-width: 680px) {
                grid-template-columns: repeat(1, 1fr);
            }
            @media (max-width: 300px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .weekly-items-main {
                display: grid;
                // height: 100vh; 
                align-items: start;
                gap: 5px;
                grid-template-columns: repeat(3, 1fr);
                width: 70%;
            }
            .side-pro-ad-container {
                width: 30%;
                .weekly-right-ad {
                    line-height: 0;
                }
            }
            button {
                background: none;
                border: none;
                cursor: pointer;
                padding: 0;
                line-height: 0;
                &:visited {
                    outline: #fff solid 6px;
                    box-shadow: rgb(119, 119, 119) 0 0 10px 6px inset, rgb(119, 119, 119) 0 0 10px 10px;
                }
                .items {
                    .ad-image {
                        img {
                            // width: 217px;
                            width: 100%;
                            // height: 170px;
                            height: auto;
                            // object-fit: cover;
                        }
                    }
                    .desc {
                        margin-top: 10px;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 5px;
                        .title {
                            width: 70%;
                            margin-bottom: 0;
                            color: #000;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 1;
                            text-transform: capitalize;
                        }
                        .price {
                            display: inline-block;
                            vertical-align: middle;
                            color: #cc0505;
                            font-size: 18px;
                            text-align: right;
                            font-weight: 700;
                            line-height: normal;
                            letter-spacing: .02em;
                        }
                    }
                }
            }
        }
        .sale-pro-ad-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @media (max-width: 575px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 0;
            }
            .weekly-bottom-ad {
                line-height: 0;
            }
        }
    }
}
.detail-items {
    border: 20px #eee solid;
    padding: 15px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    @media (min-width: 768px) {
        flex: 0 0 auto;
        width: 25%;
    }
    @media (max-width: 575px) {
        border-width: 8px;
    }
    &.col-sm-3 {
        @media screen and (min-width: 575px) and (max-width: 768px) {
            width: 50%;
        }
    }
    .close-menu {
        @media (min-width: 991px) {
            display: none;
        }
    }
    .selected-product {
        .ad-image {
            img {
                width: 100%;
                // height: 256px;
                height: auto;
                object-fit: cover;
            }
        }
        .desc {
            margin-top: 10px;
            .title {
                color: #000;
                line-height: 1;
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 600;
                text-transform: capitalize;
            }
            .price {
                vertical-align: middle;
                color: #cc0505;
                font-size: 20px;
                font-weight: 700;
                line-height: normal;
                letter-spacing: .02em;
            }
        }
        .add-list-btn-wrap {
            text-align: center;
            border-top: 1px solid #e9e9e9;
            padding-top: 20px;
            margin-top: 20px;
            .add-list {
                background: #EC1D25;
                border-radius: 40px;
                color: #fff;
                display: inline-block;
                font-size: 15px;
                font-weight: 400;
                padding: 10px 30px;
                outline: none;
                border: none;
                transition: all 0.5s ease-in-out;
                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .detail-items {
        width: 350px;
        height: 100%;
        background-color: #ffffff;
        position: fixed;
        top: 0;
        right: -500px;
        z-index: 99;
        overflow-y: scroll;
        transition-duration: 0.3s;
        &.active {
            right: 0 !important;
        }
        .close-menu {
            cursor: pointer;
            text-align: right;
            display: block;
            margin-bottom: 7px;
        }
        @media (max-width: 390px) {
            width: 300px;
        }
    }
}
.filter {
    @media (min-width: 991px) {
        display: none;
    }
}
.alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
.alert-header {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    /* Adjust as needed */
    position: relative;
    @media (max-width: 575px) {
        width: calc(100% - 20px);
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        background: no-repeat;
        border: navajowhite;
        font-size: 34px;
    }
    .alert-title {
        display: flex;
        margin-bottom: 15px;
        svg {
            margin-right: 10px;
            path {
                fill: #2ECD0D;
            }
        }
    }
    .alert-body {
        text-align: center;
    }
}
.detail-menu-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition-duration: 0.3s;
    visibility: hidden;
    opacity: 0;
    &.active {
        visibility: visible;
        opacity: 1;
    }
}