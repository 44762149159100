@import "styles/mixins/index";

.hot-offers-section {
  padding: map-get($pixel, md) * 5 0 map-get($pixel, md) * 7 + 7px 0;
  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 5 0;
  }
  .page-title {
    padding: 0 0 map-get($pixel, md) * 3 0;
    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 2 0;
    }
    > h1 {
      @include heading-2;
      color: var(--black-text-color);
      margin: 0;
      @include breakpoint("max-sm") {
        @include heading-3;
      }
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 map-get($pixel, md) * 5;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .grid-items {
      img {
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        height: 227px;
        @include breakpoint("max-sm") {
          height: 185px;
        }
      }
    }
    a .grid-items {
      img {
        width: 100%;
        height: 227px;
        border-radius: 8px;
        object-fit: cover;
        @include breakpoint("max-sm") {
          height: 185px;
        }
      }
    }
  }
}
