input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "✔";
  border: 1px solid var(--border-color-7);
  border-radius: 2px;
  display: flex;
  width: 18px;
  height: 18px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  font-size: 6px !important;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"]:checked + label:before {
  background-color: var(--primary-color);
  color: var(--bg-white);
}

// input[type="checkbox"]:checked + label:after {
//   content: "\2714";
//   display: block;
//   position: absolute;
//   top: 11px;
//   left: 16px;
//   width: 4px;
//   height: 10px;
//   border: 1px solid #fff;
//   border-width: 0 2px 2px 0;
//   transform: rotate(45deg);
// }
