@import "styles/mixins/index";

.campaign-product {
    .campaign-product-main-wrapper {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 12px 0 #20b52652;
        padding: 10px 10px 15px;
        height: 100%;
        border: 1px solid #ddd;
        border-radius: 10px;
        outline: 3px solid transparent;
        outline-offset: -3px;
        transition: all 0.6s ease-in-out;
        overflow: hidden;

        @media (max-width: 767px) {
            height: auto;
        }

        &:hover {
            border-color: var(--border-color-theme2);
            outline-color: var(--border-color-theme2);
        }

        .campaign-product-wrap {
            .campaign-product-main-wrap {
                position: relative;

                .offer-tag-quickview {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    right: auto;
                    bottom: auto;
                    z-index: 11;
                    width: 100%;

                    .offer-product-tag {
                        li {
                            min-height: 36px;
                            border-radius: 50px;
                            color: #000;
                            font-size: 14px;
                            border: 2px #000 solid;
                            font-weight: 600;
                            text-transform: capitalize;
                            background: var(--theme2-campaign-primary-color);
                            padding: 5px 15px;
                            display: inline-block;
                        }
                    }
                }

                .campaign-product-img {
                    display: grid;
                    border-radius: 10px;
                    overflow: hidden;

                    @media (max-width: 767px) {
                        height: auto;
                    }

                    a {
                        display: inline-block;

                        img {
                            max-width: 100%;
                            height: auto;
                            margin: auto;
                            width: 100%;
                            object-fit: contain;
                            max-height: 740px;
                            transition: all 0.6s ease-in-out;
                            // @media (min-width: 900px) {
                            //     min-width: 300px;
                            //     min-height: 300px;
                            //     max-height: 300px;
                            // }
                        }
                    }
                }
            }

            .campaign-product-info-wrapper {
                text-align: center;
                margin-top: 20px;

                .add-to-cart-btn {
                    max-width: 237px;
                    margin: auto;
                    background: var(--theme2-campaign-primary-color);
                    border: none;
                    text-transform: capitalize;
                    border-radius: 50px;
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 600;
                    padding: 14px 25px;
                    text-align: center;
                    width: 60%;
                    color: #000;
                    border: 2px #000 solid;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                }
            }
        }

        .d-none {
            display: none !important;
        }
    }

    &:hover {
        .campaign-product-img {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}