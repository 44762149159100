@import "styles/mixins/index";

.ingradient-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  z-index: 999;
  .modal {
    width: 450px;
    min-height: 60vh;
    max-height: 60vh;
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    @include breakpoint("max-sm") {
      width: 362px;
    }

    .modal-header {
      display: flex;
      justify-content: center;
    }
    .close-btn {
      height: 30px;
      width: 30px;
      background-color: var(--primary-light-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 15px;
      right: 15px;
      &:hover {
        cursor: pointer;
      }
    }
    .search-products {
      width: map-get($pixel, w-full);
      margin-top: 10px;
      .relative-div {
        position: relative;
        .icon-wrapper {
          position: absolute;
          top: 50%;
          right: 10px + 7px;
          transform: translateY(-50%);
        }
        > input {
          width: 100%;
          height: 46px;
          padding: 0 10px;
          background-color: transparent;
          transition: 0.2s ease-in-out;
          border: 1px solid;
          @include heading-text-sm-normal;
          border-radius: 4px;
          color: var(--text-current);
          &:focus {
            border: 1px solid var(--primary-color);
          }
        }
        ::placeholder {
          @include heading-text-sm-normal;
          color: var(--text-current);
          @include font-normal;
        }
      }
    }
    .product-list{
max-height: 500px;
overflow-y: auto;
margin-top: 12px;

.product-list-grid {
  @include grid-layout;
  grid-template-columns: repeat(1, 1fr);
    gap: 0;
    .product-list-grid-items:first-child {
      .product-card {
        padding-top: 0;
      }
  }

  .product-list-grid-items {
    .product-card {
      padding: 28px 0;
      border: none;
      border-bottom: 1px solid var(--border-color-4);
      @include grid-layout;
      grid-template-columns: 128px minmax(0, 1fr);
      gap: 0 10px;
      align-items: flex-start;
      border-bottom: 1px solid var(--border-color-4);
      .product-card-image {
        padding: 16px;
        border-radius: calc(5px - 1px);

        @include breakpoint("max-sm") {
          border: 1px solid var(--border-color-4);
        }
      }
    }
  }
}

      .no-product{
        font-size: 14px;
      }
      .loading-msg{
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
}
