@import "styles/mixins/index";

.coupon-card {
  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 270px;
  min-width: 270px;
  padding: 27px 18px 18px 18px;
  min-height: 360px;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;
  position: relative;
  @include breakpoint("max-sm") {
    margin-bottom: 20px;
  }
  .coupon-used-time-ribbon {
    position: absolute;
    right: 0;
    top: 0;
    @include breakpoint("max-sm") {
      top: 4px;
    }
    .coupon-used-time {
      background-color: var(--secondary-color);
      width: fit-content;
      padding: 17px 6px 16px 7px;
      margin: 2px 2px 15px -18px;
      position: relative;
      @include breakpoint("max-sm") {
        margin: 0 0 10px -2px;
      }
      p {
        color: var(--bg-white);
        @include heading-text-sm-normal;
        @include breakpoint("max-md") {
          font-size: 12px;
          line-height: 16px;
        }
        span {
          @include font-medium;
        }
      }
      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        bottom: -8px;
        right: 8px;
        border-width: 14px 12px 14px 0;
        transform: rotate(90deg);
        position: absolute;
        content: "";

        border-color: transparent var(--bg-white) transparent transparent;
      }
    }
  }
  .coupon-design-ribbon {
    position: absolute;
    left: 0;
    top: 0;
    .coupon-design {
      background-color: var(--secondary-color);
      width: fit-content;
      padding: 6px 34px 6px 18px;
      margin: 2px 0 0px 2px;
      position: relative;

      @include breakpoint("max-sm") {
        margin: 0 0 10px -2px;
      }

      p {
        color: var(--bg-white);
        @include heading-text-sm-normal;

        @include breakpoint("max-md") {
          font-size: 12px;
          line-height: 16px;
        }

        span {
          @include font-medium;
        }
      }

      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        top: 0;
        border-width: 14px 12px 14px 0;
        position: absolute;
        content: "";
        right: 0;
        border-color: transparent var(--bg-white) transparent transparent;
      }
    }
  }

  .triangle-up-right {
    width: 0;
    height: 0;
    border-top: 50px solid var(--secondary-color);
    border-left: 50px solid transparent;
    position: absolute;
    right: 0;
    top: 0;

    .coupon-limit-align {
      position: relative;
      p {
        position: absolute;
        top: -43px;
        right: 6px;
        font-size: 14px;
        line-height: 16px;
        z-index: 10;
      }
    }
  }
  .inside-triangle {
    border-top: 46px solid #fff;
    border-left: 46px solid transparent;
    right: 1px;
    top: 1px;
  }

  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }

  &:hover {
    border: 1px solid var(--primary-color);

    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  .product-card-image {
    @include flex-center;
    justify-content: map-get($layout, center);

    @include breakpoint("max-sm") {
      padding: 16px;
    }

    img {
      max-width: 170px;
      max-height: 170px;
      object-fit: cover;

      @include breakpoint("max-sm") {
        min-width: 92px;
        max-width: 92px;
        max-height: 116px;
        min-height: 116px;
      }
    }
  }

  .product-card-details {
    padding: map-get($pixel, md) * 1 + 4px 0 0 0;

    @include breakpoint("max-sm") {
      padding: 0;
    }
    .coupon-details {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .regular-price {
      padding: 0 0 map-get($pixel, md) - 2px 0;

      p {
        @include text-sm;
        @include font-medium;
        color: var(--black-text-color);

        span {
          color: var(--gray-7);
          @include font-semi-bold;
        }
      }
    }

    a {
      @include heading-4;
      color: var(--black-text-color);
      display: block;
      line-height: 22px;
      @include ease-in-out;
      margin: 0 0 map-get($pixel, sm) + 3px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &:hover {
        color: var(--secondary-color);
      }
    }

    .button-group-alignment {
      @include flex-between;
      justify-content: flex-end;
      padding-top: 10px;
    }

    .final-price {
      padding: 0 0 map-get($pixel, md) - 2px 0;

      p {
        @include text-sm-normal;
        color: var(--black-text-color);
        font-size: 16px;
        @include font-medium;

        span {
          color: var(--primary-color);
          @include font-semi-bold;
        }
      }
    }

    .add-to-cart {
      button {
        padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
        background-color: var(--primary-light-color);
        color: var(--primary-color);
        @include text-xs-semi-bold;
        border: none;
        @include normal-radius;
        @include flex-center;
        @include pointer;
        @include ease-in-out;

        &:hover:not([disabled]) {
          background-color: var(--primary-color);
          color: var(--bg-white);

          svg {
            stroke: var(--bg-white);

            g {
              path {
                fill: var(--bg-white);
              }
            }
          }
        }

        span {
          padding-left: 4px;
        }

        svg {
          stroke: var(--primary-color);
          @include ease-in-out;

          g {
            path {
              fill: var(--primary-color);
            }
          }
        }

        &:disabled {
          cursor: default;
        }
      }
    }

    .button-disable {
      pointer-events: none;
      opacity: 0.5;
    }

    .button-group {
      border: 1px solid var(--border-color-5);
      @include normal-radius;
      @include flex-center;
      @include pointer;
      width: fit-content;

      .action-button,
      .counter {
        width: 24px;
        height: 23px;
        @include flex-center;
        justify-content: map-get($layout, center);
      }

      .action-button {
        color: var(--gray-text-color);
        @include ease-in-out;

        &:hover {
          background-color: var(--primary-color);
          color: var(--bg-white);
        }

        i {
          font-size: 12px;
        }
      }

      .action-button-plus {
        width: 29px;
        height: 23px;
        @include flex-center;
        color: var(--gray-text-color);
        justify-content: map-get($layout, center);
        @include ease-in-out;

        &:hover {
          background-color: var(--primary-color);
          color: var(--bg-white);
        }

        i {
          font-size: 12px;
        }
      }

      .counter {
        background-color: var(--border-color);
        @include heading-text-sm;
        color: var(--black-text-color);
      }
    }

    .price {
      @include flex-center;
      padding: 0 0 map-get($pixel, md) * 2 + 8px 0;

      h3 {
        @include text-bold;
        color: var(--primary-color);
        padding-right: 21px;
      }

      del {
        @include text-sm-semi-bold;
        font-size: 13px;
        color: var(--gray-8);
      }
    }

    .star-text-alignment {
      @include flex-center;
      padding: 0 0 map-get($pixel, sm) + 3px 0;

      .rating-text {
        span {
          @include text-xs-normal;
          color: var(--gray-7);
        }
      }

      .star-alignment {
        @include flex-center;
        padding-right: 10px;

        i:last-child {
          margin-right: 0px;
        }

        i {
          color: var(--secondary-color);
          margin-right: 3px;
          @include pointer;
          font-size: 12px;
        }
      }
    }

    h3 {
      @include text-sm;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      display: block;
      color: var(--color);
    }
  }
}
