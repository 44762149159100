@import "styles/mixins/index";



  .catering-radio-group {
    padding: 0 0 12px 0;

    @include breakpoint("max-sm") {
      padding: 0 0 10px 0;
    }
    .check-box-wrapper{
      &.dropdown-show {
        display: block;
        max-height: unset;
        transition: 0.3s ease-in-out;
        z-index: unset;
      }
      &.dropdown-hide {
         display: none;
         transition: none;
         -webkit-transition: none;
         -moz-transition: none;
         -ms-transition: none;
         -o-transition: none;
       }
      }
      .arrow {
        float: right;
        margin-left: 10px;
        transition: transform 0.3s ease;
        display: inline-block;
        transform: rotate(0);
        img{
          @media screen and (max-width: 575px) {
            height: 14px;
            width: 14px;
            vertical-align: middle;
          }
        }
      }
      .arrow-up {
        transform: rotate(180deg);
      }
    p {
      @include heading-4;
      margin: 0 0 map-get($pixel, md) 0;
      color: var(--black-5-light);
      border-bottom: 1px #afafaf solid;
      span {
        color: var(--bg-danger);
      }
    }
    .catering-radio-group-item-list {
        display: grid;
        grid-template-columns: auto;
        @include breakpoint("max-sm") {
          grid-template-columns: auto;
        }
      
        .catering-item-with-radio:last-child {
          padding: 0;
        }
        .catering-item-with-radio {
          @include flex-center;
          position: relative;
          padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
          label {
            @include flex-center;
            span {
              padding-left: 7px;
            }
          }
          div {
            @include flex-center;
            input[type="radio"] {
              width: 15px;
              height: 15px;
              margin: 0;
              padding: 0;
            }
          }

          [type="radio"]:checked + label,
          [type="radio"]:not(:checked) + label
          {
              color: black;
          }
          [type=radio] {
            &:checked + label {
              &:after {
               top: 0;
               bottom: 0;
               margin: auto;
                   transform: scale(1) translateY(0%);
              }
            }
          
            &:not(:checked) + label {
              &:after {
                top: 0;
               bottom: 0;
               margin: auto;
                   transform: scale(1) translateY(0%);
              }
            }
          }
          
        }
      }

  }