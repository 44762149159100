@import "styles/mixins/index";

.promotion-section {
  padding: 0 0 50px;
  @include breakpoint("max-sm") {
    padding: 0px 0 20px 0;
  }
  h1{
    @include heading-2;
      margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
      color: var(--black-text-color);
      @include breakpoint("max-sm") {
        @include heading-7;
      }
  }
  // padding: map-get($pixel, md) * 10 0 8px 0;
  @include breakpoint("max-sm") {
    // padding: 60px 0 0 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: map-get($pixel, md) * 4;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    @media only screen and (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
  }
    .grid-items {
      border: 1px solid var(--border-color-9);
      background-color: var(--bg-white);
      border-radius: 7px;
      padding: 20px 20px;
      &:hover {
        border: 1px solid var(--primary-color);
        @include breakpoint("max-sm") {
          // display: none;
          border-bottom: 1px solid var(--primary-color);
        }
      }
      @include breakpoint("max-md") {
        padding: 15px;
      }
      @include breakpoint("max-sm") {
        padding: 12px;
      }
      .department {
        width: 60px;
        padding: 5px 0px;
        text-align: center;
        background-color: var(--gray-2);
        margin-bottom: 10px;
        display: none;
      }
      .promotion-detail {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      h2 {
        
        color: var(--invert-secondary-color);
        margin: 0 0 map-get($pixel, md) + 5px 0;
        @include breakpoint("max-md") {
          font-size: calc(18px + (23 - 18) * ((100vw - 280px) / (1350 - 280)));
          line-height: 1;
        }
        margin-bottom: 0;
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 1.38px;
        color: #3d3e39;
        margin-bottom: 0;
      }
      p {
        @include text;
        font-size: 17px;
        line-height: 25px;
        color: var(--dark-gray-300);
        @include breakpoint("max-md") {
          @include text-sm;
          line-height: 22px;
        }
      }
      .icon-center-align {
        @include flex-center;
        justify-content: map-get($layout, center);
        padding: 0 0 map-get($pixel, md) * 3 0;
        @include breakpoint("max-md") {
          padding: 0 0 15px 0;
        }
        img {
          width: 100%;
          min-height: 411px;
          object-fit: cover;
          border-radius: 7px;
          @include breakpoint("max-md") {
             min-height: 100%;
          }
        }
      }
      .read-more {
        margin-top: 35px;
        button {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          background-color: var(--primary-light-color);
          color: var(--primary-color);
          @include text-xs-semi-bold;
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;
          &:hover:not([disabled]) {
            background-color: var(--primary-color);
            color: var(--bg-white);
          }
          span {
            padding-left: 4px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
