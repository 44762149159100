@import "styles/mixins/index";

.order-confirmation-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;

  @include breakpoint("max-sm") {
    padding: 25px;
  }

  .order-confirmation {
    max-width: 650px;

    .confirmation-header {
      h2 {
        @include heading-2;
        margin-bottom: 15px;
      }
    }

    .confirmation-body {
      .order-number {
        @include text-bold;
        .logo-width-100 {
          width: 100%
        }
      }

      span {
        @include text-sm;
      }

      .email-us {
        font-size: 13px;
        margin-left: 18px;
      }

      .order-page-link {
        a {
          text-decoration: underline;
          color: #003ed0;
          font-weight: 500;
        }
      }

      .back-to-home-btn {
        display: block;
        @include text-lg;
        text-decoration: underline;
        padding-top: 15px;
      }

      .pickup-grid {
        margin-top: 10px;

        .pickup-data {
          display: flex;
          align-items: baseline;
          justify-content: space-between;

          @include breakpoint("max-sm") {
            display: block;
          }

          .pickup-loaction {
            padding: 5px;

            a {
              color: #003ed0;
              font-weight: 500;
            }
          }

          .pickup-date {
            padding: 5px;

            p {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}