@import "styles/mixins/index";
.location-dropdown-main-section {
  margin: 0 0 40px;
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .store-location-image {
    img {
      height: 304px;
      width: 100%;
      @media (max-width: 1200px) {
        margin-top: 15px;
      }
    }
  }
  .store-service-section {
    .store-service-header {
      p {
        border-bottom: 1px solid #ddd;
        font-size: 20px;
        padding-bottom: 10px;
        font-weight: 500;
      }
      .store-link-wrap {
        margin: 10px 0;
        columns: 2;
        column-width: auto;
        @media (max-width: 575px) {
          columns: 2;
        }
        &:last-child {
          columns: 4;
          column-width: auto;
          @media (max-width: 575px) {
            columns: 2;
          }
        }
        // columns: 4;
        // column-width: auto;
        // @media (max-width: 992px) {
        //   columns: 3;
        // }
        // @media (max-width: 575px) {
        //   columns: 2;
        // }
        .store-service-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          a {
            color: var(--theme2-primary-color);
            display: inline-block;
            font-size: 14px;
            text-decoration: underline;
            @include pointer;
          }
          span {
            color: #3a3a3a;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            line-height: 2.14;
          }
        }
      }
    }
    .store-section-body {
      border: 1px solid var(--light-border-color);
      padding: 10px;
      .child-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .child-text-item {
          width: 50%;
          padding: 0 5px;
          p {
            @include text;
            line-height: 28px;
          }
        }
      }
    }
  }
  .location-dropdown-section {
    .location-dropdown-theme2 {
      .grid {
        align-items: center;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 20px;
        @include breakpoint("max-md") {
          padding: 0;
          grid-template-columns: repeat(1, 1fr);
          gap: 40px 0;
          border: 1px solid #ececec;
          padding: map-get($pixel, md) * 4;
          border-radius: 5px;
        }
        @include breakpoint("max-sm") {
          padding: 20px;
        }
        .grid-items:nth-child(1) {
          padding-left: 0;
          @include breakpoint("max-md") {
            padding: 0;
          }
        }
        .grid-items {
          h2 {
            color: #000;
            font-size: 20px;
            font-weight: 700;
            margin: 0 0 7px;
            @include breakpoint("max-sm") {
              @include heading-3;
            }
          }
          .time-text {
            h3 {
              font-size: 14px;
              font-weight: 500;
              line-height: 1.928;
            }
          }
          p,
          a {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.928;
            @include pointer;
          }
          .map-image {
            img {
              max-width: 100%;
              height: auto;
              // object-fit: cover;
              // @include breakpoint("max-sm") {
              //   height: 180px;
              // }
            }
          }
          .store-loc-details {
            display: flex;
          }
        }
        &.store-location-wrapper {
          @media (min-width: 1200px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
          }
          img {
            height: 280px;
            width: 100%;
            @media (max-width: 1200px) {
              margin-top: 15px;
            }
          }
        }
      }
      .search-dropdown-list {
        padding: 0 0 20px 0;
        .search-title {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          margin: 0 0 8px;
          display: inline-block;
        }
        .tabs {
          display: flex;
          padding: 0;
          @include breakpoint("max-sm") {
            justify-content: flex-start !important;
          }
          justify-content: flex-start;
        }
      }
    }
  }
}
.location-section-theme2 {
  @include breakpoint("max-sm") {
    padding-bottom: 20px;
  }
  .store-service-location-section {
    display: grid;
    align-items: start;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .map-image {
      .store-loc-details {
        margin-top: 15px;
        .store-loc-details__button {
          background-color: var(--theme2-primary-color);
          border: none;
          border-radius: 50px;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          margin-right: 5px;
          padding: 10px 20px;
        }
      }
    }
    .store-location-image {
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
        @media (min-width: 992px) {
          object-fit: fill;
          height: 600px;
        }
      }
    }
  }
}