@import "styles/mixins/index";
.catering-checkbox-image {
  padding: 0 0 12px 0;
  @include breakpoint("max-sm") {
    padding: 0 0 8px 0;
  }
  p {
    @include heading-3;
    margin: 0 0 map-get($pixel, md) 0;
    color: var(--black-5-light);
    cursor: pointer;
    border-bottom: 1px #afafaf solid;
    border-top: 1px #afafaf solid;
    padding: 5px;
    background: #fff;
    margin: 0;
    line-height: 1.2;
    margin-bottom: 10px;
    // border-radius: 5px;
    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
    
    span {
      color: var(--bg-danger);
    }
    .select-text{
      font-size: 17px;
      @media screen and (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
  .check-box-wrapper.dropdown-show {
    // max-height: 100vh;
    // overflow: hidden;
    display: block;
    max-height: unset;
    transition: 0.3s ease-in-out;
    z-index: unset;
  }
  .check-box-wrapper.dropdown-hide {
    // max-height: 0px;
    display: none;
    // overflow: hidden;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
  }
  .arrow {
    float: right;
    margin-left: 10px;
    transition: transform 0.3s ease;
    display: inline-block;
    transform: rotate(0);
    img{
      @media screen and (max-width: 575px) {
        height: 14px;
        width: 14px;
        vertical-align: middle;
      }
    }
  }
  .arrow-up {
    transform: rotate(180deg);
  }
  .catering-checkbox-item-list {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 1440px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
    input[type="checkbox"]+label:before {
      font-size: 14px !important;
      // display: inline-block;
      vertical-align: middle;
      min-height: 18px;
      min-width: 18px;
      @media (max-width: 575px) {
        min-height: 15px;
        min-width: 15px;
        height: 15px;
        width: 15px;
      }
    }
    input[type=checkbox]:checked+label:after {
      content: unset;
    }
    .reorder-dropdown-subheading {
      display: block;
      color: var(--gray-400);
      margin: 10px 0;
      font-weight: 600;
    }
    .catering-item-with-checkbox:last-child {
      padding: 0;
    }
    .catering-item-with-checkbox {
      label {
        line-height: 1;
        display: block;
        text-align: center;
        span {
          @include text-bold;
          color: var(--black-text-color);
          @include text-center;
          font-weight: 600;
          display: block;
          line-height: 1.1;
          margin-top: 12px;
          @media (max-width: 1024px) {
            margin-top: 7px;
            font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1024 - 280)));
          }
        }
      }
      div {
        @include flex-center;
        input[type="checkbox"] {
          width: 15px;
          height: 15px;
          margin: 0;
          padding: 0;
        }
      }
    }
    .custom-label {
      height: 100%;
      position: relative;
      overflow: hidden;
      background-color: #FEFFFA;
      padding: 15px;
      box-shadow: 0px 0px 15px rgba(129, 129, 129, 0.16);
      border: 2px solid #ddd;
      border-radius: 5px;
      @include pointer;
      @include ease-in-out;
      // min-height: 211px;
      @media (max-width: 1024px) {
        padding: 10px;
      }
      &::before {
        display: none !important;
      }
    }
    .checkbox-container {
      display: flex;
      gap: 15px;
    }
    .custom-checkbox {
      display: none;
    }
    .custom-label {
      img{
        width: 100%;
        border-radius: 5px;
        max-width: 160px;
        max-height: 160px;
        height: 160px;
        // object-fit: cover;
      }
    }
    .custom-checkbox:checked+.custom-label {
      border-color: var(--primary-color);
    }
  }
}