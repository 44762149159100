.free-reward-main-wrap {
  .free-reward-box {
    background-color: #edf2ee;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 50px;

    @media (max-width: 575px) {
      padding: 15px;
    }

    @media (min-width: 767px) {
      display: flex;
      align-items: center;
    }

    .free-reward-img {
      background: #fff;
      border-radius: 10px;
      margin: 0;
      min-height: 180px;
      padding: 10px;
      text-align: center;
      width: 33.33%;
      margin: 0 15px 0 0;

      img {
        max-height: 200px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 15px 0;
      }
    }

    .free-reward-content-wrapper {
      width: 66.66%;

      @media (max-width: 767px) {
        width: 100%;
      }

      color: #000;

      .free-reward-subtitle {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
      }

      .free-reward-title-btn {
        margin-bottom: 10px;

        .free-reward-title {
          margin: 10px 0;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;

          @media (max-width: 1200px) {
            font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
          }

          @media (max-width: 992px) {
            margin-bottom: 10px;
          }
        }
      }
    }

    .free-reward-range-bar {
      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @media (max-width: 650px) {
        flex-wrap: wrap;
        gap: 10px;
      }

      .free-reward-point {
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        input[type="checkbox"]:checked+label:before {
          font-size: 12px !important;
          border-radius: 50px;
        }

        input[type="checkbox"]+label:before {
          border: none;
                    height: 20px;
          width: 20px;
        }

        .checkbox-wrap {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .relative-checkbox {
            margin: 2px 5px;
            background-color: var(--bg-color-theme2);
            border-radius: 50px;


            &.blure {
              opacity: 1;
            }
          }
        }

        .free-reward-count {
          display: inline-block;
          margin: 0 0 0 20px;

          @media (max-width: 992px) {
            margin: 0px 0 0 10px;
          }
        }

        .cirlce_points {
          border-radius: 50%;
          border: 1px solid #eee;
          padding: 2px;
          display: inline-block;
          width: 30px;
          height: 30px;
          background-color: #fff;
          text-align: center;
          align-items: center;
          justify-content: center;
          display: flex;
          float: left;
          margin-right: 5px;
          color: white;
        }

        .cirlce_points .fa.fa-circle {
          color: #eee;
          font-size: 29px;
        }
      }

      .reward-btn-wrapper {
        display: flex;
        align-items: center;
      }

      .reward-btn {
        cursor: pointer;
        text-transform: capitalize;
        background: var(--theme2-secondary-color);
        border-radius: 40px;
        color: #fff;
        padding: 10px 20px;
        width: max-content;
        text-align: center;

        @media (max-width: 992px) {
          margin-top: 10px;
        }

        &.redeem-btn {
          margin-left: 10px;
        }
      }
    }
  }
}