@import "styles/mixins/index";
.details-reward-main-wrap {
    margin: 20px 0 40px;
    @media (max-width: 992px) {
        margin: 20px 0;
    }
    .details-reward-items{
        .reward-items-wrap{
            .items{
                margin: 0 -7.5px;
            }
            .heading{
                margin-bottom: 20px;
                h2 {
                    color: #1a1a1a;
                    text-transform: capitalize;
                    font-size: 30px;
                    font-weight: 500;
                    @media (max-width: 1200px) {
                        font-size: calc(20px + (30 - 20) * ((100vw - 280px) / (1200 - 280)));
                    }
                }
            }
            .left-arrow-alignment {
                position: absolute;
                right: 42px;
                top: -56px;
                @include breakpoint("max-sm") {
                    top: -45px;
                }
            }
            .right-arrow-alignment {
                position: absolute;
                right: 0;
                top: -56px;
                @include breakpoint("max-sm") {
                    top: -45px;
                }
            }
            .custom-arrow-design {
                width: 32px;
                border-radius: 50%;
                background-color: var(--bg-light-400);
                @include pointer;
                height: 32px;
                @include flex-center;
                justify-content: map-get($layout, center);
                @include ease-in-out;
                color: var(--gray-text-color);
                &:hover {
                    background-color: var(--primary-color);
                    color: var(--bg-white);
                }
            }
            .details-reward-box {
                border: 1px solid #ddd;
                border-radius: 10px;
                font-family: var(--text-font-family);
                height: 100%;
                outline: 3px solid transparent;
                outline-offset: -3px;
                padding: 10px 10px 15px;
                position: relative;
                margin: 0 7.5px;
                transition: all .6s ease-in-out;
                &:hover {
                    border-color: var(--border-color-theme2);
                    outline-color: var(--border-color-theme2);
                  }
                .details-reward-img {
                    line-height: 1.2;
                    min-height: 70px;
                    text-align: center;
                    margin: 0 15px 0 0;
                    img {
                              min-height: 210px;
                              max-height: 210px;
                              max-width: 210px;
                              margin: auto;
                              object-fit: contain;
                              -webkit-transition: all 0.6s ease-in-out;
                              -o-transition: all 0.6s ease-in-out;
                              transition: all 0.6s ease-in-out;
                              @media screen and (max-width: 1200px) {
                                min-height: 150px;
                                max-height: 150px;
                                max-width: 150px;
                              }
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        text-align: left;
                        margin: 0 0 15px 0;
                    }
                }
                .details-reward-content-wrapper {
                    @media (max-width: 575px) {
                        width: 100%;
                    }
                    color: #000;
                    .details-reward-title-btn {
                        margin-bottom: 5px;
                        .details-reward-title {
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            color: #4d4d4d;
                            display: -webkit-box;
                            font-family: var(--text-font-family);
                            font-size: 13px;
                            margin-bottom: 10px;
                            font-weight: 400;
                            overflow: hidden;
                            @media (max-width: 1200px) {
                                font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                            }
                            @media (max-width: 992px) {
                                margin-bottom: 10px;
                            }
                        }
                        .details-reward-point-btn {
                            cursor: pointer;
                            text-transform: capitalize;
                            color: #000;
                            span{
                                font-size: 13px;
                                color: #1c1c1c;
                            }
                        }
                    }
                }
                .details-reward-range-bar {
                    .details-reward-point {
                        font-size: 13px;
                        color: #000;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}