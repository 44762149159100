@import "styles/mixins/index";
.twobanner-banner-theme2 {
  padding-bottom: 20px;
  @media (max-width: 992px) {
    padding-bottom: 20px;
  }
  .twobanner-img-wrap {
    display: flex;
    flex-direction: row;
    gap: 30px;
    // justify-content: space-between;
    @include breakpoint("max-md") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .twobanner-banner-item {
      @media screen and (min-width: 1300px) {

      width: 50%;
      }
      .twobanner-img {
        overflow: hidden;
        cursor: pointer;
        img {
          border-radius: 4px;
          border: 1px solid var(--border-color-4);
          max-width: 100%;
          width: 100%;
         height: auto;
        }
      }
    }
  }
}