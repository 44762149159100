@import "styles/mixins/index";
.clear-cart-modal-body {
  padding: 30px;
  text-align: center;
  .button-primary {
    padding: map-get($pixel, sm) * 2 map-get($pixel, md) * 3;
    background-color: var(--primary-color);
    color: var(--bg-white);
    border-radius: calc(5px - 0px);
    border: none;
    @include heading-4;
    margin-right: 20px;
    span {
      font-size: 15px;
    }
  }
  h3 {
    margin-bottom: 30px;
  }

  .button-outline {
    background-color: #fff;
    border: 1px solid var(--primary-color);
    margin-right: 20px;
    color: var(--primary-color);
    font-size: 15px;
    span {
      font-size: 15px;
    }
  }
}
