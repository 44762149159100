.offer-banner-theme2{
  padding-bottom: 40px;
  @media (max-width: 992px) {
    padding-bottom: 20px;
  }
  .offer-img-wrap{
    .offer-img{
      img{
        max-width: 100%;
        width: 100%;
        border-radius: 4px;
        height: 452px;
        @media (max-width: 992px) {
        height: auto;
        }
      }
    }
  }
}